import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FrameEditorComponent } from '../editor/frame-editor.component';
import { FrameClass } from '../frame-store.service';
import { ZBuilder, zComponent } from '../../../../scripts/zimext/zbuilder';

@Component({
  selector: 'app-frame-coder',
  templateUrl: './frame-coder.component.html'
})
export class FrameCoderComponent implements OnInit, AfterViewInit {
  @Input() frameEditor: FrameEditorComponent;
  frame: FrameClass;
  selectedComp: zComponent;
  funcName: string;
  addFuncOptions: any;
  addEventOptions: any;

  aceOptions = { maxLines: Infinity, minLines: 2, showPrintMargin: false, showGutter: false };

  addFuncButton: any;
  refreshFrameButton: any;
  selectEventOptions: any;
  newEventName = '';
  newFuncName = '';
  eventNames = ['click', 'dblclick',
    'mousedown', 'mouseout', 'mouseover',
    'pressmove', 'pressup',
    'rollout', 'rollover',
    'added', 'removed', 'tick'];
  // sprite:    'animationend' , 'change'
  // spriteSheet: 'complete' ,'error', 'getframe'
  // stage:  'drawend', 'drawstart', 'mouseenter', 'mouseleave' ,
  //         'stagemousedown' ,'stagemousemove', 'stagemouseup', 'tickend', 'tickstart

  compCodes = [
    {
      label: 'Construct',
      func: '.',
      text: '.center(stage).transform()'
    },
    {
      label: 'Component',
      func: '#',
      text: 'color="red";'
    },
  ];
  compSelect = [];


  funcCodes = [
    {
      label: 'Beep',
      func: 'Beep()',
      text: 'console.log("Beep #");'
    },
  ];
  funcSelect = [];

  eventCodes = [
    {
      label: 'mousedown',
      func: 'on.mousedown',
      text: 'Beepdown'
    },
    {
      label: 'mouseup',
      func: 'on.mousedown',
      text: 'Beepup'
    },
  ];
  eventSelect = [];

  constructor(public cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {

    this.addFuncButton = {
      icon: 'add',
      type: 'normal',
      onClick: () => {
        this.addFunction(this.newFuncName, '()');
        this.newFuncName = '';
      }
    };

  }

  ngAfterViewInit(): void {
  }


  onCodeChange(evt: any, codes: any, index: number) {
    this.selectedComp.setFunc(codes[index].func, codes[index].text);
  }

  onKeyDown(evt: any, codes: any, index: number) {
    if ((evt.code === 'KeyX') && (evt.ctrlKey)) {
      if (codes[index].text.length === 0) {
        this.selectedComp.delFunc(codes[index].func);
        codes.splice(index, 1);
      }
    }
  }


  refreshFrameClicked() {
    this.frameEditor.zimBuilder.Refresh();
  }

  addEventClicked(data: any) {
    let fname = data.text ? data.text : data.value;
    fname = 'on.' + fname;
    this.addFunction(fname);
    this.newEventName = '';
  }


  openEditor(func: string) {

    for (const code of this.funcCodes) {
      if (code.func === func) {
        if (!this.funcSelect.includes(code)) {
          this.funcSelect.push(code);
        }
      }
    }

    for (const code of this.eventCodes) {
      if (code.func === func) {
        if (!this.eventSelect.includes(code)) {
          this.eventSelect.push(code);
        }
      }
    }
  }



  addFunction(fname: string, postfix?: string) {
    if (fname) {
      if ((postfix) && (!fname.includes('('))) {
        fname = fname + postfix;
      }
      const fbody = this.selectedComp.getFunc(fname);
      if (fbody) {
        this.openEditor(fname);
        return;
      }
      this.selectedComp.setFunc(fname, '// ' + fname);
      this.updateView(this.selectedComp);
      this.openEditor(fname);
    }
  }



  private updateView(zcomp: zComponent) {

    for (const code of this.compCodes) {
      code.text = zcomp.getFunc(code.func);
    }

    this.funcCodes.length = 0;
    const funcs = zcomp.getFuncs();
    for (const fun of funcs) {
      this.funcCodes.push({ label: fun.func, func: fun.func, text: fun.code });
    }

    this.eventCodes.length = 0;
    const events = zcomp.getEvents();
    for (const evt of events) {
      this.eventCodes.push({ label: evt.event, func: 'on.' + evt.event, text: evt.code });
    }
    this.compSelect = [];
    this.funcSelect = [];
    this.eventSelect = [];
    this.cdr.detectChanges();
  }


  public Select(zcomp?: zComponent) {
    if (zcomp) {
      this.selectedComp = zcomp;
    } else {
      this.selectedComp = this.frameEditor.zimFrame.component;
    }
    this.updateView(this.selectedComp);
  }


}

// saveCodesClicked() {
//   for (const code of this.compCodes) {
//     this.selectedComp.setFunc(code.func, code.text);
//   }

//   const funcs = [];
//   for (const code of this.funcCodes) {
//     funcs.push({ func: code.func, text: code.text });
//   }
//   const cfuncs = this.selectedComp.getFuncs();
//   for (const code of cfuncs) {
//     if (funcs.findIndex(fun => fun.func === code.func) < 0) {
//       // remove deleted funcs
//       this.selectedComp.delFunc(code.func);
//     } else {
//       // update remaining funcs
//       this.selectedComp.setFunc(code.func, code.text);
//     }
//   }

//   const events = [];
//   for (const code of this.eventCodes) {
//     events.push({ func: code.func, text: code.text });
//   }
//   const cevents = this.selectedComp.getEvents();
//   for (const code of cevents) {
//     if (funcs.findIndex(fun => fun.func === code.func) < 0) {
//       // remove deleted events
//       this.selectedComp.delFunc(code.func);
//     } else {
//       this.selectedComp.setFunc(code.func, code.text);
//       // update remaining events
//     }
//   }

// }
