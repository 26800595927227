<style>
  .col-fixed {
    margin: 0 auto;
  }

  #configPanel{
    width: 720px;
    height: auto;

  }
  /* @media (min-width: 768px) {
    .col-fixed {
      width: 720px;
    }
  } */

  fieldset {
    margin: 0px 10px 0px 10px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  legend {
    padding: 4px;
    border: 0px;
    margin: 4px;
    margin-bottom: 0px;
    font-size: 12pt;
  }
</style>

<div class="container" id="configPanel">

  <app-bg-banner></app-bg-banner>

  <fieldset style="margin-top:100px;">
    <legend>Configuration</legend>

    <fieldset>
      <legend>Application</legend>
      <form class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 control-label"
                 for="name-input-config">Your Display Name </label>
          <div class="col-sm-9">
            <input class="form-control"
                   [(ngModel)]="userDisplayName"
                   onClick="this.setSelectionRange(0, this.value.length)"
                   type="text"
                   id="userNameConfig"
                   name="name-input-config"
                   placeholder="Enter name here...">
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset>
      <legend>Chat Media</legend>
      <form class="form-horizontal">


        <div class="form-group row">
          <label class="col-sm-2 control-label"
                 for="loc-media-audio-0">Audio</label>
          <div class="col-sm-10">

            <select class="form-control"
                    name="loc-media-audio-0"
                    [(ngModel)]="avConfig.lmAudio[0]">
              <option [ngValue]=null>..none..</option>
              <option *ngFor="let src of audioSources" [ngValue]="src.id">
                {{ src.label }}
              </option>
            </select>

          </div>
        </div>


        <div class="form-group row">
          <label class="col-sm-2 control-label"
                 for="loc-media-video-0">Video</label>
          <div class="col-sm-10">
            <select class="form-control"
                    name="loc-media-video-0"
                    [(ngModel)]="avConfig.lmVideo[0]">
              <option [ngValue]=null>..none..</option>
              <option *ngFor="let src of videoSources"
                      [ngValue]="src.id">
                {{ src.label }}
              </option>
            </select>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-sm-2 control-label"
                 for="loc-media-width-0">Width</label>
          <div class="col-sm-2">
            <input id="lmWidth0"
                   [(ngModel)]="this.avConfig.lmWidth[0]"
                   name="loc-media-width-0"
                   class="form-control"
                   type="number"
                   maxlength="2">
          </div>

          <label class="col-sm-2 control-label"
                 for="loc-media-height-0">Height</label>
          <div class="col-sm-2">
            <input id="lmHeight0"
                   [(ngModel)]="this.avConfig.lmHeight[0]"
                   name="loc-media-height-0"
                   class="form-control"
                   type="number"
                   maxlength="2">
          </div>

          <label class="col-sm-2 control-label"
                 for="loc-media-fps-0">FPS</label>
          <div class="col-sm-2">
            <input id="lmFPS0"
                   [(ngModel)]="this.avConfig.lmFPS[0]"
                   name="loc-media-fps-0"
                   class="form-control"
                   type="number"
                   maxlength="2">
          </div>
        </div>

      </form>
    </fieldset>

    <fieldset>
      <legend>Observe Media</legend>
      <form class="form-horizontal">

        <div class="form-group row">
          <label class="col-sm-2 control-label"
                 for="loc-media-audio-1">Audio</label>
          <div class="col-sm-10">
            <select class="form-control"
                    name="loc-media-audio-1"
                    [(ngModel)]="avConfig.lmAudio[1]">
              <option [ngValue]=null>..none..</option>
              <option *ngFor="let src of audioSources"
                      [ngValue]="src.id">
                {{ src.label }}
              </option>
            </select>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-sm-2 control-label"
                 for="loc-media-video-1">Video</label>
          <div class="col-sm-10">
            <select class="form-control"
                    name="loc-media-video-1"
                    [(ngModel)]="avConfig.lmVideo[1]">
              <option [ngValue]=null>..none..</option>
              <option *ngFor="let src of videoSources"
                      [ngValue]="src.id">
                {{ src.label }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 control-label"
                 for="loc-media-width-1">Width</label>
          <div class="col-sm-2">
            <input id="lmWidth1"
                   name="loc-media-width-1"
                   class="form-control"
                   type="number"
                   maxlength="2"
                   [(ngModel)]="this.avConfig.lmWidth[1]">
          </div>

          <label class="col-sm-2 control-label"
                 for="loc-media-height-1">Height</label>
          <div class="col-sm-2">
            <input id="lmHeight1"
                   name="loc-media-height-1"
                   class="form-control"
                   type="number"
                   maxlength="2"
                   [(ngModel)]="this.avConfig.lmHeight[1]">
          </div>

          <label class="col-sm-2 control-label"
                 for="loc-media-fps-1">FPS</label>
          <div class="col-sm-2">
            <input id="lmFPS1"
                   name="loc-media-fps-1"
                   class="form-control"
                   type="number"
                   maxlength="2"
                   [(ngModel)]="this.avConfig.lmFPS[1]">
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset>
      <legend>Computer</legend>
      <form class="form-horizontal">

        <div class="form-group row">
          <label class="col-sm-2 control-label"
                 for="loc-scrshare-audio">Audio-Playback</label>
          <div class="col-sm-7">

            <select class="form-control"
                    name="loc-scrshare-audio"
                    [(ngModel)]="avConfig.ssAudio">
              <option [ngValue]=null>..none..</option>
              <option *ngFor="let src of audioSinks"
                      [ngValue]="src.id">
                {{ src.label }}
              </option>
            </select>

          </div>
          <label class="col-sm-1 control-label"
                 for="loc-scrshare-fps">FPS</label>
          <div class="col-sm-2">
            <input id="ssFPS"
                   name="loc-scrshare-fps"
                   class="form-control"
                   type="number"
                   maxlength="2"
                   [(ngModel)]="this.avConfig.ssFPS">
          </div>
        </div>

        <div class="form-group row">

          <label class="col-sm-2 control-label"
                 for="loc-rcol-width">Right-Split</label>
          <div class="col-sm-2">
            <input id="rcolWidth"
                   name="loc-rcol-width"
                   class="form-control"
                   type="number"
                   maxlength="2"
                   [(ngModel)]="this.avConfig.rcolWidth">
          </div>

          <label class="col-sm-2 control-label"
                 for="loc-canvas-width">Cnv-Width</label>
          <div class="col-sm-2">
            <input id="canvWidth"
                   name="loc-canvas-width"
                   class="form-control"
                   type="number"
                   maxlength="3"
                   [(ngModel)]="avConfig.canvWidth">
          </div>

          <label class="col-sm-2 control-label"
                 for="loc-canvas-height">Cnv-Height</label>
          <div class="col-sm-2">
            <input id="canvHeight"
                   name="loc-canvas-height"
                   class="form-control"
                   type="number"
                   maxlength="3"
                   [(ngModel)]="this.avConfig.canvHeight">
          </div>

        </div>
      </form>
    </fieldset>

    <div style="margin:0px; padding:5px;" class="form-group row ">

      <div class="formGroup">
        <input type="button"
               class="btn btn-warning"
               value="Save"
               (click)="saveConfig()">
      </div>

      <div class="formGroup">
        <input type="button"
               class="btn btn-danger"
               value="Default"
               (click)="defaultConfig()">
      </div>

      <div class="formGroup">
        <input type="button"
               class="btn btn-info"
               value="Cancel"
               (click)="enterLogin()">
      </div>

    </div>
  </fieldset>
</div>
