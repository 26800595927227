import { Injectable, NgZone } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SysEventsService, TAGS } from '../../shared/services/sysevents.service';

export interface RtcSignal { id: string; uid: string; senderUid: string; message: string; }
export interface User {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName?: string;
  photoURL?: string;
  isMaster?: boolean;
}


@Injectable({
  providedIn: 'root'
})



export class AuthService {
  private userDoc: AngularFirestoreDocument<User>;
  private userObs: Observable<User>;
  public userData: User = null; // Save logged in user data


  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public ses: SysEventsService
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.ses.Online = false;
    this.afAuth.authState.subscribe(user => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        const uid = user.uid;
        this.userDoc = this.afs.doc<User>(TAGS.USER_FOLDER + '/' + uid);
        this.userObs = this.userDoc.valueChanges();
        this.userObs.subscribe(x => {
          if (x !== undefined) {
            this.userData = x;
            this.ses.Online = true;
            if (this.userData.isMaster === undefined) {
              this.userData.isMaster = false;
            }
            this.ses.Master = this.userData.isMaster;
            if (this.ses.Master) {
              console.log('Auth user....');
              this.ses.ClassOpen = false;
            }
          }
        });
      }
      else {
        this.ses.Master = false;
        this.ses.Online = false;
        localStorage.setItem('user', null);
        this.userData = null;
      }
    });



  }


  public get userDisplayName(): string{
   return this.userData.displayName;
  }

  public set userDisplayName(value: string){
   this.userData.displayName = value;
   this.SaveUserData(this.userData);
  }

  // Sign in with email/password
  async SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          if (result.user.emailVerified === false) {
            this.SendVerificationMail();
            alert('Please verify your email before loggin in: ' + result.user.email);
          } else {
//            console.log(result.user);
            this.ses.LoggedIn = true;
            this.router.navigate(['dashboard']);
          }
        });
//        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  async SignUp(email, password, displayName) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.SendVerificationMail();
        this.CreateUserData(result.user, displayName);
      }).catch((error) => {
        window.alert(error.message);
      });
  }

  async GotoForgotPassword(){
      this.router.navigate(['forgot-password']);
  }


  async GotoSignUp(){
    this.router.navigate(['register-user']);
  }


  async GotoSignIn(){
    this.router.navigate(['sign-in']);
  }


  // Send email verfificaiton when new user sign up
  async SendVerificationMail() {
    return (await this.afAuth.currentUser).sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email']);
      });
  }


  // Reset Forggot password
  async ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }


  // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  // Auth logic to run auth providers
  // AuthLogin(provider) {
  //   return this.afAuth.signInWithPopup(provider)
  //     .then((result) => {
  //       this.ngZone.run(() => {
  //         this.router.navigate(['dashboard']);
  //       });
  //       this.CreateUserData(result.user);
  //     }).catch((error) => {
  //       window.alert(error);
  //     });
  // }

//  this.classOpenDoc = this.afs.doc(TAGS.APPSTATE_FOLDER + '/' + TAGS.CLASS_OPEN_DOC),


  SaveUserData(user: User) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(TAGS.USER_FOLDER + '/' + user.uid);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      photoURL  : user.photoURL,
      displayName: user.displayName
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  CreateUserData(newuser, dispName: string) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(TAGS.USER_FOLDER + '/' + newuser.uid);
    const userData  = {
      uid: newuser.uid,
      email: newuser.email,
      emailVerified: newuser.emailVerified,
      displayName: dispName
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.userData = null;
      this.ses.LoggedIn = false;
      localStorage.removeItem('user');
      this.ses.Online = false;
      this.router.navigate(['sign-in']);
    });
  }

}


