<div>
  <dx-tree-view id="treeview"
                [items]="products"
                dataStructure="plain"
                [width]="300"
                [height]="450"
                rootValue="0"
                (onItemContextMenu)="treeViewItemContextMenu($event)">
  </dx-tree-view>


  <dx-context-menu [dataSource]="menuItems"
                   target="#treeview .dx-treeview-item"
                   (onItemClick)="contextMenuItemClick($event)">
  </dx-context-menu>

</div>
