<style>
    .coder-block {
        margin-top: 2px;
        display: flex;
        flex-direction: column;
    }

    .coder-toolbar {
        margin: 0px;
        padding-left: 5px;
        display: flex;
        justify-content: left;
        flex-direction: row;
    }

    .coder-menu-label {
        padding: 3px;
        margin: 0px;
        margin-top: 5px;
        background-color: black;
        color: white;
    }

    .coder-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0px;
        padding: 2px;
        background-color: white;
    }

    .coder-fixed-items,
    .coder-event-items,
    .coder-func-items {
        margin-left: 10px;
    }

    .accord-panel {
        height: 33px;
        padding: 3px;
        margin: 0px;
        background-color: #EFEFEF
    }
</style>


<div class="coder-container">


    <div class="coder-block">

        <div class="coder-toolbar">
            <dx-button icon="refresh"
                       text="Reload"
                       (click)="refreshFrameClicked()">
            </dx-button>
        </div>

        <div class="coder-menu-label">Base</div>

        <div class="coder-fixed-items"
             *ngIf="compCodes.length">
            <dx-accordion [dataSource]="compCodes"
                          [collapsible]="true"
                          [multiple]="true"
                          [(selectedItems)]="compSelect"
                          [animationDuration]="300">
                <div class="accord-panel"
                     *dxTemplate="let code of 'title'">
                    <p style="font-size: 12px; padding: 2px; margin:0px">{{code.label}}</p>
                </div>
                <div *dxTemplate="let code of 'item'; let indx = index"
                     style="margin:0px; padding:2px;">
                    <ace-editor theme="monokai"
                                [autoUpdateContent]="true"
                                mode="javascript"
                                [options]="aceOptions"
                                (keydown)="onKeyDown($event, compCodes, indx)"
                                [durationBeforeCallback]="300" 
                                (textChanged)="onCodeChange($event, compCodes, indx)"
                                style="min-height: 24px; overflow: auto;"
                                [(text)]="code.text">
                    </ace-editor>
                </div>
            </dx-accordion>
        </div>
    </div>

    <div class="coder-block">

        <div class="coder-menu-label">Functions</div>

        <dx-text-box placeholder="..function-name.."
                     stylingMode="filled"
                     height=30px
                     stylingMode="outlined"
                     [(value)]="newFuncName"
                     mode="text">
            <dxi-button name="addFuncBtn"
                        location="after"
                        width=20px
                        height=20px
                        [options]="addFuncButton">
            </dxi-button>
        </dx-text-box>

        <div class="coder-func-items"
             *ngIf="funcCodes.length">
            <dx-accordion [dataSource]="funcCodes"
                          [collapsible]="true"
                          [multiple]="true"
                          [(selectedItems)]="funcSelect"
                          [animationDuration]="300">
                <div class="accord-panel"
                     *dxTemplate="let code of 'title'">
                    <p style="font-size: 12px; padding: 2px; margin:0px">{{code.label}}</p>
                </div>
                <div *dxTemplate="let code of 'item'; let indx = index"
                     style="margin:0px; padding:2px;">
                    <ace-editor theme="monokai"
                                [autoUpdateContent]="true"
                                mode="javascript"
                                [options]="aceOptions"
                                (keydown)="onKeyDown($event, funcCodes, indx)"
                                [durationBeforeCallback]="300" 
                                (textChanged)="onCodeChange($event, funcCodes, indx)"
                                style=" min-height: 24px; overflow: auto;"
                                [(text)]="code.text">
                    </ace-editor>
                </div>
            </dx-accordion>
        </div>
    </div>

    <div class="coder-block">
        <div class="coder-menu-label">Events</div>

        <dx-select-box #eventSelectBox
                       height=30px
                       [dataSource]="eventNames"
                       placeholder="..custom-event.."
                       [acceptCustomValue]="true"
                       stylingMode="outlined"
                       [(value)]="newEventName"
                       (onCustomItemCreating)="addEventClicked($event)"
                       (onValueChanged)="addEventClicked($event)">
        </dx-select-box>


        <div class="coder-event-items"
             *ngIf="eventCodes.length">
            <dx-accordion [dataSource]="eventCodes"
                          [collapsible]="true"
                          [multiple]="true"
                          [(selectedItems)]="eventSelect"
                          [animationDuration]="300">
                <div class="accord-panel"
                     *dxTemplate="let code of 'title'">
                    <p style="font-size: 12px; padding: 2px; margin:0px">{{code.label}}</p>
                </div>
                <div *dxTemplate="let code of 'item'; let indx = index"
                     style="margin:0px; padding:2px;">
                    <ace-editor theme="monokai"
                                [autoUpdateContent]="true"
                                mode="javascript"
                                [options]="aceOptions"
                                (keydown)="onKeyDown($event, eventCodes, indx)"
                                [durationBeforeCallback]="300" 
                                (textChanged)="onCodeChange($event, eventCodes, indx)"
                                style="min-height: 24px; overflow: auto;"
                                [(text)]="code.text">
                    </ace-editor>
                </div>
            </dx-accordion>
        </div>
    </div>
</div>