<style>
  .toolbar-panel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;
    width: auto;
    margin: 0px;
    margin-bottom: 5px;
    padding: 0px;
  }

  .toolbar-panel-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;
    width: calc(100% - 20px);
    margin: 0px;
    padding: 0px;
  }

  .toolbar-label-collapsed {
    position: relative;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-style:italic;
    border-radius: 5px;
    height: 48px;
    width: 24px;
    margin: 0px;
    padding-top: 2px;
    font-size: 13px;
    text-align: left;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #F0F0F0;
    background-color:#5050AA ;
  }


  .toolbar-label-active {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    font-style:normal;
    border-radius: 5px;
    height: auto;
    width: 20px;
    margin: 0px;
    padding-top: 4px;
    font-size: 13px;
    text-align: left;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #404040;
    background-color:#9090FF ;
  }
</style>


<div class="toolbar-panel">
  <div [className]="collapsed ? 'toolbar-label-collapsed' : 'toolbar-label-active'" 
       (click)="togglePanel($event)"><span [innerHTML]="labelStr"></span>
  </div>
  <div *ngIf="!collapsed" class="toolbar-panel-container">
    <div *ngFor="let item of panel.items">
      <app-toolbar-item [item]="item"
                        [color]="panel.color"></app-toolbar-item>
    </div>
  </div>
</div>
