import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { zBuilder } from 'src/scripts/zimext/zbuilder';
import { FrameEditorComponent } from '../editor/frame-editor.component';
import { FrameClass } from '../frame-store.service';


@Component({
  selector: 'app-frame-script',
  templateUrl: './frame-script.component.html'
})
export class FrameScriptComponent implements OnInit {
  @Input() frameEditor: FrameEditorComponent;
  @ViewChild('scriptEditor') editor;
  aceOptions = { wrap: 'printmargin', maxLines: Infinity, minLines: 2, showPrintMargin: true, showGutter: true };

  scriptEditor: any;
  zimBuilder: zBuilder;
  panelHeight: '500px';


  scriptText  = 'this is script';

  constructor() { 
  }

  ngOnInit(): void {
  }

  BuildScript(){
    if (this.zimBuilder) {
      this.scriptText  = this.zimBuilder.Compile();
      this.scriptText = this.scriptText + '\n//// PARAMS ////' +
                                          '\n INPUT = ' + this.zimBuilder.PARAMS_IN +
                                          '\n EXIT  = ' + this.zimBuilder.PARAMS_EX + '\n\n';
      this.editor.text = this.scriptText;
    }
  }

  UpdateBuilder(zBuild: zBuilder){
    this.zimBuilder = zBuild;
  }

}
