<style>
  html,
  body {
    margin: 0px;
    padding: 0px;
  }

  .toolbar_frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    overflow: none;
  }

  .canvas_frame {
    background-color: black;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }


  .frame_editor {
    width: 100%;
    height: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
</style>



<div class="frame_editor" (resized)="onResized($event)" id="frame_editor_div">
  <div class="toolbar_frame">
    <app-frame-editor-toolbar [frameEditor]="frameEditorComp"> </app-frame-editor-toolbar>
  </div>
  <div class="canvas_frame">
    <div class="canvas" id="zimCanvas"></div>
  </div>
</div>