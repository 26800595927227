import { Component, OnInit, AfterViewInit, OnDestroy, NgZone, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { MASTER_CTRL, WebRtcService } from 'src/app/shared/services/webrtc.service';
import { SysEventsService } from 'src/app/shared/services/sysevents.service';

import {
  faEye,
  faWindowClose
} from '@fortawesome/free-regular-svg-icons';



import {
  faMicrophoneAlt,
  faCamera,
  faDesktop,
  faChalkboardTeacher
} from '@fortawesome/free-solid-svg-icons';
import { ConfigService } from 'src/app/shared/services/config.service';



@Component({
  selector: 'app-session-control',
  templateUrl: './session-control.component.html'
})

export class SessionControlComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() set viewPortSwitch(value: boolean) {
    if (value !== this.viewPortAltMode) {
      console.log('ViewPortAltMode = ' + value);
      this.switchVideoSrc(this.webrtc.MediaChannels[3].remoteVideoSink, this.webrtc.MediaChannels[1].remoteVideoSink);
      const elmx = this.webrtc.MediaChannels[3].remoteVideoSink;
      this.webrtc.MediaChannels[3].remoteVideoSink = this.webrtc.MediaChannels[1].remoteVideoSink;
      this.webrtc.MediaChannels[1].remoteVideoSink = elmx;
      this.viewPortAltMode = value;
      if (this.ses.Master) {
        //        this.webrtc.SendMasterCtrlAction(MASTER_CTRL.FRMSEL, value ? 1 : 0);
      }
    }
  }

  get viewPortSwitch(): boolean {
    return this.viewPortAltMode;
  }

  @Input() set RemOnline(value: boolean) {
    this.bnSCRenabled = value;
    this.bnOBSenabled = value;
  }






  private viewPortAltMode = false;

  private obsEnable = false;
  private scrShareEnable = false;

  public bnSCRenabled = true;
  public bnOBSenabled = true;

  private micEnable = true;
  private camEnable = true;


  public bnExitIcon = faWindowClose;
  public bnMicIcon = faMicrophoneAlt;
  public bnCamIcon = faCamera;
  public bnWBIcon = faChalkboardTeacher;
  public bnSSIcon = faDesktop;
  public bnOBIcon = faEye;

  public ExitState = true;

  private myMCTRLSubs: Subscription;
  private micAudioState: Subscription;
  private camVideoState: Subscription;
  private auxViewState: Subscription;


  constructor(
    public auth: AuthService,
    public confsrv: ConfigService,
    public webrtc: WebRtcService,
    public ses: SysEventsService) {
  }

  async ngOnInit(): Promise<void> {

    if (this.ses.Master === false) {
      await this.webrtc.InitLocalMedia();
    }
    this.myMCTRLSubs = this.webrtc.RxMasterCtrlCmd.subscribe((cmd) => { this.exeMasterCommand(cmd); });

    this.ses.WBoardVisible = false;
    this.micAudioState = this.webrtc.MediaChannels[0].onMuteChange.subscribe((muted) => { this.micEnable = !muted; });
    this.camVideoState = this.webrtc.MediaChannels[1].onMuteChange.subscribe((muted) => { this.camEnable = !muted; });
    this.auxViewState = this.webrtc.MediaChannels[3].onMuteChange.subscribe((muted) => {
      if (muted) {
        this.bnSCRenabled = true;
        this.bnOBSenabled = true;
        this.obsEnable = false;
        this.scrShareEnable = false;
      } else {
        this.obsEnable = this.bnOBSenabled;
        this.scrShareEnable = this.bnSCRenabled;
      }
    });
  }

  ngAfterViewInit() {
    this.webrtc.MediaChannels[0].remoteAudioSink = document.getElementById('audio_remote') as HTMLAudioElement;

    this.webrtc.MediaChannels[1].remoteVideoSink = document.getElementById('video_remote') as HTMLVideoElement;
    this.webrtc.MediaChannels[1].localVideoSink = document.getElementById('video_local') as HTMLVideoElement;

    this.webrtc.MediaChannels[2].remoteAudioSink = document.getElementById('audio_remote') as HTMLAudioElement;

    this.webrtc.MediaChannels[3].remoteVideoSink = document.getElementById('video_main') as HTMLVideoElement;
    this.webrtc.MediaChannels[3].localVideoSink = document.getElementById('video_main') as HTMLVideoElement;

  }


  switchVideoSrc(elm1: HTMLVideoElement, elm2: HTMLVideoElement) {
    const src1 = elm1 ? elm1.srcObject : null;
    const src2 = elm2 ? elm2.srcObject : null;

    if (elm1) {
      elm1.pause();
      elm1.removeAttribute('src');
      elm1.srcObject = src2;
      elm1.load();
    }
    if (elm2) {
      elm2.pause();
      elm2.removeAttribute('src');
      elm2.srcObject = src1;
      elm2.load();
    }
    // const elmx = elm2;
    // elm2 = elm1;
    // elm1 = elmx;
  }

  private exeMasterCommand(action: string) {
    if (action.startsWith('[')) {
      const endcmd = action.indexOf(']');
      const head = action.slice(1, endcmd);
      const sections = head.split(':', 3);
      const cmd = sections[0];
      const indx0 = parseInt(sections[1], 10);
      const indx1 = parseInt(sections[2], 10);
      const msg = action.substring(endcmd + 1);

      switch (cmd) {


        case MASTER_CTRL.OBSERVE:
          if (indx0 > 1) {
            this.webrtc.MediaChannels[3].localVideoSink = null;
          } else {
            this.webrtc.MediaChannels[3].localVideoSink = document.getElementById('video_main') as HTMLVideoElement;
          }
          this.webrtc.SetObsVideo(indx0 > 0);
          break;

        case MASTER_CTRL.VIDRES:
          this.webrtc.MediaChannels[indx0].ScaleVideoSender(indx1);
          break;
          
        case MASTER_CTRL.LOCVIS:
          this.ses.emitLocViewVisible(indx0 > 0);
          break;

        case MASTER_CTRL.VIDSCALE:
          this.ses.emitVideoScaleIndex(indx0);
          break;

        case MASTER_CTRL.CAMSEL:

          //            this.avIndx = (indx0 > -1) ? indx0 : indx1;
          //            this.setMediaViewports();
          break;
        case MASTER_CTRL.FRMSEL:
          const state = (indx0 > 0) ? true : false;
          this.viewPortSwitch = state;
          break;
        case MASTER_CTRL.EXIT:
          this.ses.emitMasterExit();
          break;
      }
    }
  }

  get MicState(): boolean {
    return this.micEnable;
  }


  set MicState(value: boolean) {
    this.webrtc.SetMicAudio(value);
  }

  get CamState(): boolean {
    return this.camEnable;
  }

  set CamState(value: boolean) {
    this.webrtc.SetCamVideo(value);
  }

  get WBState(): boolean {
    return this.ses.WBoardVisible;
  }

  set WBState(value: boolean) {
    this.ses.WBoardVisible = value;
  }

  get OBState(): boolean {
    return this.obsEnable;
  }

  set OBState(value: boolean) {

    this.viewPortSwitch = false;
    this.bnSCRenabled = value ? false : true;
    this.bnOBSenabled = true;
    this.webrtc.SetObsVideo(value);
    const count = this.confsrv.validVideoDevice(1) ? 2 : 1;
    const p = value ? count : 0;
    this.webrtc.SendMasterCtrlAction(MASTER_CTRL.OBSERVE, p);
  }

  get SCRState(): boolean {
    return this.scrShareEnable;
  }

  set SCRState(value: boolean) {

    this.viewPortSwitch = false;
    this.bnOBSenabled = value ? false : true;
    this.bnSCRenabled = true;
    //    this.webrtc.MediaChannels[3].localVideoSink = value ? (document.getElementById('video_main') as HTMLVideoElement) : null;
    this.webrtc.SetScrShare(value);
  }

  ngOnDestroy(): void {
    this.auxViewState.unsubscribe();
    this.micAudioState.unsubscribe();
    this.camVideoState.unsubscribe();
    this.myMCTRLSubs.unsubscribe();
    this.ses.ClassOpen = false;
    console.log('Destroying Session View');
  }

  bnExitClassClick() {
    this.ses.emitMasterExit();
  }



}
