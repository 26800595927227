import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { SysEventsService } from 'src/app/shared/services/sysevents.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['..//styles/entry-dialogs.css']

})

export class DashboardComponent implements OnInit, OnDestroy {

  public canEnterClassAttr = 'disabled';
  public bnEnterClassText = 'Class Closed';
  private onClassOpenChange: Subscription;
  private onOnlineChange: Subscription;


  constructor(public auth: AuthService, public router: Router, public ngZone: NgZone, public ses: SysEventsService) {
  }

  enterClass() {
    this.router.navigate(['master-console']);
    this.canEnterClassAttr = 'disabled';
    //  this.router.navigate(['slave-console']);
  }



  classOpenCheck() {
    const open = this.ses.ClassOpen;
    const online = this.ses.Online;
    if (this.ses.Master === true) {
      if (online) {
        if (this.canEnterClassAttr) {
          this.ses.doDing();
        }
        this.canEnterClassAttr = null;
        this.bnEnterClassText = 'Enter Class';
      } else {
        this.canEnterClassAttr = 'disabled';
      }
    } else {
      if (online && open) {
        if (this.canEnterClassAttr) {
          this.ses.doDing();
          this.enterClass();
        }
        this.bnEnterClassText = 'Enter Class';
        this.canEnterClassAttr = null;
      } else {
        this.canEnterClassAttr = 'disabled';
      }
    }
  }

  zimJSView() {
    this.router.navigate(['zim-view']);
  }

  navFrameBuilder() {
    this.router.navigate(['frame-builder']);
  }
  navSignOut() {
    this.auth.SignOut();
  }

  navConfig() {
    this.router.navigate(['config']);
  }

  ngOnInit() {
    this.classOpenCheck();
    // if (this.ses.Master === true) {
    //   this.canProceed = 'active';
    // } else {
    //   if (this.ses.ClassOpen) {
    //     this.canProceed = 'active';
    //   } else {
    //     this.canProceed = 'disabled';
    //   }
    // }
    this.onClassOpenChange = this.ses.onClassOpenChange.subscribe((state: boolean) => { this.classOpenCheck(); });
    this.onOnlineChange = this.ses.onOnlineChange.subscribe((state: boolean) => { this.classOpenCheck(); });
  }

  ngOnDestroy(): void {
    this.onClassOpenChange.unsubscribe();
    this.onOnlineChange.unsubscribe();
  }

}
