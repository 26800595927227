import { Injectable } from '@angular/core';
import { createRxDatabase, RxDatabase, RxCollection, RxJsonSchema, RxDocument, addRxPlugin, RxChangeEvent, RxQuery } from 'rxdb/plugins/core';
import * as PouchdbAdapterIdb from 'pouchdb-adapter-idb';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';
import ArrayStore from 'devextreme/data/array_store';

import { fabric } from 'fabric';
import { SysEventsService } from 'src/app/shared/services/sysevents.service';


// FrameClass      - 1 x Zim-template , 1 x Var-template (N-Deep) => Instantiate 1..N Frames
//                  -- Frame[RowIndex:number] => 1 Frame
//                  -- Frames["ColumnName", "Expression"] => (0..N-1) Frames
// FrameSet - array of FrameClass[X]
// FrameCatalog - array of FrameSet[Y]
// FrameCatalogLib - array of FrameCatalog[Z]


const FrameThumbHeight = 128;   // default thumbnail width
const defCanvasWidth = 800;
const defCanvasHeight = 600;
const defCanvasColor = '#101010';


/// ###############################################################################################################################
/// ###############################################################################################################################



// type FSNodeDoc = RxDocument<FSNodeDocType>;
// type FSNodeCollection = RxCollection<FSNodeDocType>;
// type dbCollections = {
//   FrameCatalogsLib: FSNodeCollection,
//   FrameCatalogs: FSNodeCollection,
//   FrameSets: FSNodeCollection,
//   Frames: FSNodeCollection,
// }

// type dbFrameStore = RxDatabase<dbCollections>;
// type dbFrameStore = RxDatabase<any>;


class FSNodeDocType {
  static tables: RxCollection[] = [];
  static templates: object[] = [];
  id: string;
  name: string;
  description: string;
  childrenIDs: string[] = [];

  constructor() {
  }
}

export class FSNode extends FSNodeDocType {

  table: RxCollection;
  doc: RxDocument;
  parent: FSNode;
  thumbNail: string;

  constructor(public level: number, parent: FSNode, ID?: string) {
    super();
    if (ID !== undefined) {
      this.id = ID;
    }
    this.parent = parent;
  }

  private s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
  public GuidV4() {
    return (this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4());
  }


  public async InitRoot(): Promise<FSNode> {
    this.table = FSNodeDocType.tables[this.level];
    const root = await this.table.find().exec();
    if (root.length > 0) {
      this.id = root[0]._id;
    }
    return await this.InitLevel();
  }

  public async InitLevel(): Promise<FSNode> {
    this.table = FSNodeDocType.tables[this.level];
    if (this.id === undefined) {
      const newnode = await this.table.insert(FSNodeDocType.templates[this.level]);
      this.doc = newnode;
      this.id = newnode._id;
      this.name = newnode.name;
      this.description = newnode.description;
      this.childrenIDs = [];
      if (this.parent) {
        await this.parent.LinkChild(this);
      }
    } else {
      const query = await this.getQuery();
      const exnode = await query.exec();
      if (exnode) {
        this.doc = exnode;
        this.name = exnode.name;
        this.thumbNail = await this.GetATTStr('thumbnail');
        this.description = exnode.description;
        if (exnode.childrenIDs !== undefined) {
          this.childrenIDs = exnode.childrenIDs;
        }
      }
    }
    return this;
  }

  public async MoveChild(index: number, pos: number): Promise<number> {
    const to = index + pos;
    if ((to < this.childrenIDs.length - 1) && (to > -1)) {
      this.childrenIDs.splice(to, 0, this.childrenIDs.splice(index, 1)[0]);
      await this.Save();
      return to;
    }
    return index;
  }

  public async ChildByIndex(index: number): Promise<FSNode> {
    const child = new FSNode(this.level + 1, this, this.childrenIDs[index]);
    await child.InitLevel();
    return child;
  }

  public async ChildByID(id: string): Promise<FSNode> {
    const index = this.childrenIDs.indexOf(id);
    if (index > -1) {
      return await this.ChildByIndex(index);
    }
    return null;
  }

  public async Save() {
    await this.doc.update({
      $set: {
        name: this.name,
        id: this.id,
        description: this.description,
        childrenIDs: this.childrenIDs
      }
    });
  }

  public async getQuery(): Promise<RxQuery> {
    return this.table.findOne().where('_id').eq(this.id);
  }

  public async Delete() {
    for (let i = 0; i < this.childrenIDs.length - 1; i++) {
      const child = await this.ChildByIndex(i);
      await child.Delete();
    }
    if (this.parent) {
      await this.parent.UnlinkChild(this);
    }
    const query = await this.getQuery();
    if (query) {
      await query.remove();
    }
  }

  public async UnlinkChild(child: FSNode) {
    const index = this.childrenIDs.indexOf(child.id);
    if (index > -1) {
      this.childrenIDs.splice(index, 1);
      await this.Save();
    }
    return;
  }

  public async LinkChild(child: FSNode) {
    this.childrenIDs.push(child.id);
    await this.Save();
  }

  public async AddChild(pos?: number): Promise<FSNode> {

    const node = new FSNode(this.level + 1, this);
    const child = await node.InitLevel();
    if (pos === undefined) {
      this.childrenIDs.push(child.id);
    } else {
      this.childrenIDs.splice(pos, 0, child.id);
    }
    await this.Save();
    return child;
  }

  set ThumbNail(imgData: string) {
    this.thumbNail = imgData;
    this.SaveATTStr('thumbnail', imgData);
  }
  get ThumbNail(): string {
    return this.thumbNail;
  }

  public async GetATTBlob(id: string): Promise<Blob> {
    const att = this.doc.getAttachment(id);
    if (att) {
      const blob = await att.getData();
      return blob;
    }
    return null;
  }

  public async GetATTStr(id: string): Promise<string> {
    const att = this.doc.getAttachment(id);
    if (att) {
      const str = await att.getStringData();
      return str;
    }
    return null;
  }
  public async SaveATTBlob(id: string, data: Blob, type?: string) {
    await this.doc.putAttachment({ id, data, type });
  }

  public async SaveATTStr(id: string, data: string, type?: string) {
    await this.doc.putAttachment({ id, data, type });
  }

  public async LoadObj(id: string, storeObj: any): Promise<any> {
    const obj = JSON.parse(await this.GetATTStr(id));
    return obj ? obj : storeObj;
  }

  public async StoreObj(id: string, obj: any) {
    if ((obj !== undefined) && (obj !== null)) {
      const str = JSON.stringify(obj);
      await this.SaveATTStr(id, str, 'plain/text');
    }
  }

}


// const attachment = await myDocument.putAttachment({
//   id,     // string, name of the attachment like 'cat.jpg'
//   data,   // (string|Blob|Buffer) data of the attachment
//   type    // (string) type of the attachment-data like 'image/jpeg'
// });
// getAttachment()
// Returns an RxAttachment by its id. Returns null when the attachment does not exist.

// const attachment = myDocument.getAttachment('cat.jpg');


/// ###############################################################################################################################
/// ###############################################################################################################################


// public async LoadFrameSet(fsid: string): Promise<FrameBase[]> {
//   const frames: FrameBase[] = [];
//   const frameColl = this.afs.collection<FrameFields>(path);
//   const items = await frameColl.get().toPromise();
//   if (items) {
//     items.forEach(item => {
//       const data = item.data() as FrameFields;
//       const fr = new FrameBase();
//       fr.FID = item.id;
//       fr.Path = data.Path ? data.Path : '';
//       fr.Type = data.Type ? data.Type : '';
//       fr.Info = data.InfoOBJ ? JSON.parse(data.InfoOBJ) : null;
//       fr.ModuleID = data.ModuleID ? data.ModuleID : '';
//       fr.ModuleIndex = data.ModuleIndex ? data.ModuleIndex : 0;
//       fr.ThumbImage = data.ThumbImage ? data.ThumbImage : '';
//       fr.BackgroundColor = data.BackgroundColor ? data.BackgroundColor : '';
//       fr.Props = data.PropsOBJ ? JSON.parse(data.PropsOBJ) : null;
//       fr.Codes = data.Codes ? data.Codes : [];
//       fr.FabStore = data.FabStore ? data.FabStore : '';
//       frames.push(fr);
//     });
//   }
//   return Promise.resolve(frames);
// }

// public SaveFrame(frameSetID: string, frame: FrameBase) {

//   if (!frame.FID) {
//     frame.FID = this.afs.createId();
//   }
//   const frameRef: AngularFirestoreDocument<FrameFields> = this.afs.doc(path + '/' + frame.FID);
//   const info = JSON.stringify(frame.Info);
//   const props = JSON.stringify(frame.Props);
//   const frData: FrameFields = {
//     FID: frame.FID,
//     Path: frame.Path,
//     Type: frame.Type,
//     InfoOBJ:  info ? info : '' ,
//     ModuleID: frame.ModuleID,
//     ModuleIndex: frame.ModuleIndex,
//     ThumbImage: frame.ThumbImage,
//     BackgroundColor: frame.BackgroundColor,
//     PropsOBJ: props ? props : '',
//     Codes: frame.Codes,
//     FabStore: frame.FabStore
//   };
//   frameRef.set(frData, { merge: true, });
// }

// public SaveFrameSet(fsPath: string, frameSet: FrameBase[]) {
//   frameSet.forEach(fr => {
//     this.SaveFrame(fsPath, fr);
//   });
// }

// addItem(name: string) {
//   // Persist a document id
//   const id = this.afs.createId();
//   const item: Item = { id, name };
//   this.itemsCollection.doc(id).set(item);
// }



/// ###############################################################################################################################
/// ###############################################################################################################################



export enum VARTYPE {
  BOOL = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
  OBJECT = 'object'
}


//AddLink(name: string, onLoad: boolean, targetID: string, varID: string) {


export interface VarLink {
  ID: string;
  Name: string;
  VarID: string;  // UUID of the Var it is linked to
  ElmID: string;
  ElmProp: string;
  OnLoad: boolean;  // should it be applied on load or wait for a trigger event ?
  VarName?: string;
  ElmName?: string;
}

export class KVP {
  ID: string;
  Name: string;
}


export class FrameVarDef {
  ID: string;
  Name: string;
}

export interface FrameVar {
  ID: string;  // UUID of this Var
  Name: string;
  Rows: any[];
}

export interface ThemeVar {
  ID: string;  // UUID of the linked FrameVar
  Rows: any[];
}


export interface FrameVarArr {
  Name: string;
  Columns: FrameVar[];
}

export interface ThemeVarArr {
  Name: string;
  Column: ThemeVar[];
}

// Event Triggers
// AfterFrameShow, BeforeFrameExit
// Element-Mouse- on(L-Click, R-Click, Dbl-Click, MouseWheel)
// Element-Select- on(Select, Deselect)
// Element-Key- on(KeyPress, KeyDown, KeyUp)
// Element-Show- on(Show, Hide)
// Element-Move- on(StartDrag, StopDrag)
// Element-Drop- on(SourceDrop, TargetDrop)

// Elemment props
// Text, Opacity, Pos, Size, ForeColor, BackColor, Image, MEDIA_URL (Video/Image/Music),
// Font, Rotation, Z-Index, Visibility, Outline Width, Outline Color, Shadow ?


// Codes = Trigger => Tween_ElementProperty, From (This => VarX) / (Const => VarX) / (VarX => VarY)


export class FrameClass extends FSNode {

  private baseVars: FrameVarArr = { Name: 'Base', Columns: [] };
  private maskVars: FrameVarArr = { Name: 'Mask', Columns: [] };
  private themeVars: ThemeVarArr[] = [];

  Dirty = false;

  constructor(parent: FSNode, ID?: string) {
    super(3, parent, ID);
  }

  clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  public RenameFrame(index: number, name: string) {
    if ((index < this.themeVars.length) && (index !== 0)) {
      this.themeVars[index].Name = name;
    }
  }


  public GetVarValues(vid: string): string[] {
    if ((this.baseVars) && (this.baseVars.Columns.length) > 0) {
      const indx = this.baseVars.Columns.findIndex(c => (c.ID === vid));
      if (indx > -1) {
        return this.baseVars.Columns[indx].Rows;
      }
    }
    return [];
  }

  public GetVarName(vid: string): string {
    if ((this.baseVars) && (this.baseVars.Columns.length) > 0) {
      const indx = this.baseVars.Columns.findIndex(v => (v.ID === vid));
      if (indx > -1) {
        return this.baseVars.Columns[indx].Name;
      }
    }
    return 'NO!VAR!';
  }



  public GetVarDefs(): FrameVarDef[] {
    const names: FrameVarDef[] = [];
    if (this.baseVars) {
      for (const frvar of this.baseVars.Columns) {
        const vartype: FrameVarDef = { Name: frvar.Name, ID: frvar.ID };
        names.push(vartype);
      }
    }
    return names;
  }

  public GetVarLen(): number {
    let maxlen = 0;
    if (this.baseVars) {
      for (const frvar of this.baseVars.Columns) {
        maxlen = Math.max(maxlen, frvar.Rows.length);
      }
    }
    return maxlen;
  }

  public GetVarStore(maskOn: boolean, themeIndx: number): ArrayStore {
    // themeIndx of -1,  base only
    const frVars = this.clone(this.baseVars);

    if (themeIndx > -1) {
      if (themeIndx < this.themeVars.length - 1) {
        const theme = this.themeVars[themeIndx];
        theme.Column.forEach(ovr => {
          const id = ovr.ID;
          const values = ovr.Rows;
          // find and override the corrseponding array in frVars

        });

      }
    }

    if (maskOn) {

    }

    const dispStore: any[] = [];
    const maxlen = this.GetVarLen();
    for (let indx = 0; indx < maxlen; indx++) {
      const dispObj = {};
      for (const frvar of frVars.Columns) {

        if (frvar.Rows.length > indx) {
          const colName = frvar.Name;
          const rowId = indx;
          const rowVal = frvar.Rows[indx];
          Object.defineProperty(dispObj, 'indx', {
            value: rowId,
            writable: false
          });
          Object.defineProperty(dispObj, colName, {
            value: rowVal,
            writable: true
          });
        }
      }

      dispStore.push(dispObj);
    }

    const store = new ArrayStore({
      key: 'indx',
      data: dispStore,
      onUpdated: this.onUpdateROW.bind(this),
      onRemoved: this.onRemoveROW.bind(this)
    });
    return store;
  }


  public async MoveColumn(fromIndx: number, toIndx: number) {
    // repeat the same for all arrays maks and theme
    if ((toIndx > -1) && (toIndx < this.baseVars.Columns.length)) {
      this.baseVars.Columns.splice(toIndx, 0, this.baseVars.Columns.splice(fromIndx, 1)[0]);
      await this.StoreObj('vars', this.baseVars);
    }
  }

  public RenameColumn(indx: number, name: string) {
    this.baseVars.Columns[indx].Name = name;
    this.StoreObj('vars', this.baseVars);
  }

  public async DeleteColumn(indx: number) {
    this.baseVars.Columns.splice(indx, 1);
    await this.StoreObj('vars', this.baseVars);
  }

  public async AddColumn(indx: number, name: string, type: VARTYPE, value: any) {
    const uid = this.GuidV4();
    const col = {
      ID: uid,
      Name: name,
      Type: type,
      Rows: [value]
    };
    this.baseVars.Columns.splice(indx, 0, col);
    const len = this.GetVarLen();
    this.ExtendValues(len);
    await this.StoreObj('vars', this.baseVars);
  }

  public async AddRow() {
    const len = this.GetVarLen();
    this.ExtendValues(len + 1);
    await this.StoreObj('vars', this.baseVars);
  }

  public async MoveRow(fromIndx: number, toIndx: number) {
    this.ExtendValues(toIndx + 1);
    for (const fvar of this.baseVars.Columns) {
      fvar.Rows.splice(toIndx, 0, fvar.Rows.splice(fromIndx, 1)[0]);
    }
    await this.StoreObj('vars', this.baseVars);
  }


  public ExtendValues(len: number) {
    for (const fvar of this.baseVars.Columns) {
      while (fvar.Rows.length < len) {
        const v = this.clone(fvar.Rows[fvar.Rows.length - 1]);
        fvar.Rows.push(v);
      }
    }
  }

  public onRemoveROW(key: any) {
    for (const fvar of this.baseVars.Columns) {
      fvar.Rows.splice(key, 1);
    }
    this.StoreObj('vars', this.baseVars);
  }


  public async SetValue(indx: number, name: string, value: any) {
    // !!! here we should see if Mask or Theme is active and write back to those stores only
    const FVar = this.baseVars.Columns.find(element => element.Name === name);
    if (FVar) {
      this.ExtendValues(indx + 1);
      FVar.Rows[indx] = value;
      await this.StoreObj('vars', this.baseVars);
    }
  }

  public onUpdateROW(key: any, values: any) {
    Object.keys(values).forEach(name => this.SetValue(key, name, values[name]));
  }

  public async Init(): Promise<FrameClass> {
    await super.InitLevel();
    await this.LoadData();
    if (this.baseVars.Columns.length === 0){
      this.fakeVars();
    }
    return this;
  }

  private fakeVars() {
    this.baseVars = { Name: 'Base', Columns: [] };
    this.baseVars.Columns.push({
      ID: 'fake-1',
      Name: 'Greetings',
      Rows: ['Hello', 'GoodBye', 'GoodDay', 'GoodNight']
    });

    this.baseVars.Columns.push({
      ID: 'fake-2',
      Name: 'Color',
      Rows: ['red', 'blue', 'green', 'yellow']
    });

    this.baseVars.Columns.push({
      ID: 'fake-3',
      Name: 'Left',
      Rows: ['20', '100', '150', '80', '300']
    });

    this.baseVars.Columns.push({
      ID: 'fake-4',
      Name: 'Top',
      Rows: ['10', '100', '150', '10', '80', '0']
    });

  }

  public async LoadData() {
    this.baseVars = await this.LoadObj('vars', this.baseVars);
    this.maskVars = await this.LoadObj('mask', this.maskVars);
    this.themeVars = await this.LoadObj('themes', this.themeVars);
  }


  public async Save() {
    await super.Save();
    await this.StoreObj('vars', this.baseVars);
    await this.StoreObj('mask', this.maskVars);
    await this.StoreObj('themes', this.themeVars);
    this.Dirty = false;
  }


  public async Delete() {
    await this.Destroy();
    await super.Delete();
  }

  public async Clone(): Promise<FrameClass> {
    const n = await (new FrameClass(this.parent as FSNode)).Init();
    n.name = this.name;
    n.description = this.description;
    n.Dirty = true;
    n.ThumbNail = this.ThumbNail;
    if (this.baseVars !== undefined) { n.baseVars = this.clone(this.baseVars); }
    if (this.themeVars !== undefined) { n.themeVars = this.clone(this.themeVars); }
//    await n.SaveATTStr('canvas', this.FabCanvStore, 'plain/text');

    return n;
  }




  public async Destroy() {
  }

  public InitCode(args: string[]) {
  }

}




/// ###############################################################################################################################
/// ###############################################################################################################################


export class FrameSetMeta {
  Name = '';
  Author = '';
  Created = '';
  Version = '';
  Subject = '';
  Grade = '';
  Description: string[] = [''];
  Methodology = '';
  SearchTags: string[] = [''];
  isRow = false;
}

export class FrameSet extends FSNode {
  Meta: FrameSetMeta = new FrameSetMeta();
  FrameClasses: FrameClass[] = [];
  get IsRow(): boolean {
    return this.Meta.isRow;
  }

  set IsRow(state: boolean) {
    if (this.Meta.isRow !== state) {
      this.Meta.isRow = state;
      this.SaveData();
    }
  }

  constructor(parent: FSNode, ID?: string) {
    super(2, parent, ID);
    this.Meta = new FrameSetMeta();
  }

  private async LoadData() {
    const strData = await this.GetATTStr('metaData');
    this.Meta = JSON.parse(strData);
  }

  private async SaveData() {
    await this.SaveATTStr('metaData', JSON.stringify(this.Meta), 'obj');
  }

  private async LoadChildren() {
    this.FrameClasses.length = 0;
    for (const cid of this.childrenIDs) {
      const fr = await (new FrameClass(this as FSNode, cid)).Init();
      this.FrameClasses.push(fr);
    }
  }


  public async Save() {
    await super.Save();
    await this.SaveData();
  }

  public async Init(): Promise<FrameSet> {
    await super.InitLevel();
    await this.LoadChildren();
    await this.LoadData();
    return this;
  }

  public async MoveFrame(index: number, positions: number) {
    this.FrameClasses.splice(index + positions, 0, this.FrameClasses.splice(index, 1)[0]);
  }

  public async CopyFrame(index: number, toIndex: number) {
    const newFrame = await this.FrameClasses[index].Clone();
    this.FrameClasses.splice(toIndex, 0, newFrame);
  }

  public async DeleteFrame(index: number) {
    await this.FrameClasses[index].Delete();
  }

  public async AddFrame(): Promise<FrameClass> {
    const fr = await (new FrameClass(this as FSNode)).Init();
    this.FrameClasses.push(fr);
    return fr;
  }



}


/// ###############################################################################################################################
/// ###############################################################################################################################


export class FrameSetCatalog extends FSNode {
  FrameSets: FrameSet[] = [];

  constructor(parent: FSNode, ID?: string) {
    super(1, parent, ID);
  }

  public async Init(): Promise<FrameSetCatalog> {
    await super.InitLevel();
    await this.LoadChildren();
    return this;
  }

  private async LoadChildren() {
    for (const cid of this.childrenIDs) {
      const fsc = await (new FrameSet(this as FSNode, cid)).Init();
      this.FrameSets.push(fsc);
    }
  }

  public async DeleteFrameSet(indx: number) {
    if (indx < this.FrameSets.length - 1) {
      const fs = this.FrameSets[indx];
      await fs.Delete();
      this.FrameSets.splice(indx, 1);
    }
  }

  public async AddFrameSet(): Promise<FrameSet> {
    const fs = await (new FrameSet(this as FSNode)).Init();
    this.FrameSets.push(fs);
    return fs;
  }

}


@Injectable({
  providedIn: 'root'
})
export class FrameLibService {
  db: RxDatabase<any>;
  node: FSNode;
  FSCatalogs: FrameSetCatalog[] = [];

  constructor(public ses: SysEventsService) {
  }

  FSNodeSchema(Title: string): RxJsonSchema<FSNodeDocType> {
    const schema: RxJsonSchema<FSNodeDocType> = {
      keyCompression: false, // set this to true, to enable the keyCompression
      version: 0,
      title: Title,
      type: 'object',
      properties: {
        id: {
          type: 'string'
        },
        name: {
          type: 'string'
        },
        description: {
          type: 'string'
        },
        childrenIDs: {
          type: 'array',
          items: {
            type: 'string'
          }
        }
      },
      required: ['name'],
      indexes: ['id'],
      attachments: {
        encrypted: false // if true, the attachment-data will be encrypted with the db-password
      }
    };
    return schema;
  }

  // FRSetSchema(Title: string): RxJsonSchema<FRSetDocType> {
  //   const schema: RxJsonSchema<FRSetDocType> = {
  //     keyCompression: false, // set this to true, to enable the keyCompression
  //     version: 0,
  //     title: Title,
  //     type: 'object',
  //     properties: {
  //       id: {
  //         type: 'string'
  //       },
  //       name: {
  //         type: 'string'
  //       },
  //       description: {
  //         type: 'string'
  //       },
  //       childrenIDs: {
  //         type: 'array',
  //         items: {
  //           type: 'string'
  //         }
  //       }
  //     },
  //     required: ['name'],
  //     indexes: ['id'],
  //     attachments: {
  //       encrypted: false // if true, the attachment-data will be encrypted with the db-password
  //     }
  //   };
  //   return schema;
  // }


  public async Init() {

    const fsNodeSchema = this.FSNodeSchema('fsnode');
    addRxPlugin(PouchdbAdapterIdb);
    addRxPlugin(RxDBValidatePlugin);
    addRxPlugin(RxDBQueryBuilderPlugin);
    addRxPlugin(RxDBUpdatePlugin);
    addRxPlugin(RxDBAttachmentsPlugin);



    this.db = await createRxDatabase({
      name: 'store',           // <- name
      adapter: 'idb',               // <- storage-adapter
      multiInstance: false,         // <- multiInstance (optional, default: true)
      eventReduce: false // <- eventReduce (optional, default: true)
    });



    const table0 = await this.db.collection({
      name: 'table0/',
      schema: fsNodeSchema
    });
    FSNodeDocType.tables.push(table0);
    FSNodeDocType.templates.push({
      name: 'Library..',
      description: 'Library of Catalogs...'
    });

    const table1 = await this.db.collection({
      name: 'table1/',
      schema: fsNodeSchema
    });
    FSNodeDocType.tables.push(table1);
    FSNodeDocType.templates.push({
      name: 'Catalog..',
      description: 'Catalog of FrameSets...'
    });

    const table2 = await this.db.collection({
      name: 'table2/',
      schema: fsNodeSchema
    });
    FSNodeDocType.tables.push(table2);
    FSNodeDocType.templates.push({
      name: 'FrameSet..',
      description: 'Set of Frames...'
    });

    const table3 = await this.db.collection({
      name: 'table3/',
      schema: fsNodeSchema
    });
    FSNodeDocType.tables.push(table3);
    FSNodeDocType.templates.push({
      name: 'Frame..',
      description: 'A single Frame...'
    });

    this.node = new FSNode(0, null);
    await this.node.InitRoot();
    await this.LoadChildren();
  }

  private async LoadChildren() {
    for (const cid of this.node.childrenIDs) {
      const fsc = await (new FrameSetCatalog(this.node, cid)).Init();
      this.FSCatalogs.push(fsc);
    }
  }

  public async DeleteCatalog(indx: number) {
    if (indx < this.FSCatalogs.length - 1) {
      const cat = this.FSCatalogs[indx];
      await cat.Delete();
      this.FSCatalogs.splice(indx, 1);
    }
  }

  public async AddFrameSet(): Promise<FrameSet> {
    const frameSet = await this.FSCatalogs[0].AddFrameSet();
    return frameSet;
  }

  public async AddCatalog(): Promise<FrameSetCatalog> {
    const fsc = await (new FrameSetCatalog(this.node)).Init();
    this.FSCatalogs.push(fsc);
    return fsc;
  }

}

