import { Component, Input, OnInit } from '@angular/core';
import { ToolbarPanel } from './toolbar-panel.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html'
})
export class ToolbarComponent implements OnInit {

  @Input() panels: ToolbarPanel[];

  constructor() { }

  ngOnInit(): void {
  }



}
