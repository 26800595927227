import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Subscription, Subject } from 'rxjs';
import { AuthService, RtcSignal } from 'src/app/shared/services/auth.service';
import { map } from 'rxjs/operators';
import { SysEventsService, TAGS } from './sysevents.service';
import { MediaChannel, MediaT } from 'src/app/shared/services/mediachan';
import { ConfigService, AVconfig } from './config.service';



export enum WBREM_CTRL {
  REMON = 'RON',   // remote draw is online
  VISIBLE = 'VIS',
  DRAW = 'DRW',
  MOVE = 'MOV',
  TOOLS = 'TLS'
}

export enum MASTER_CTRL {
  EXIT = 'EXT',          // exit class
  FRMSEL = 'FRM',      // Master Frame | Slave Frame  swap
  CAMSEL = 'CAM',       // select CAM0 or CAM1 as source
  SHARESCR = 'SCR',      // enabled screen share
  OBSERVE  = 'OBS',       // set OBS mode
  VIDSCALE = 'VSC',       // scale video sender
  VIDRES = 'VRS',         // set video resolution
  LOCVIS = 'LOC'
}

const enum DATA_CHAN {
  MBOARD_TXT = 'MBTXT',
  WBOARD_CTRL = 'WBCTL',
  WBOARD_DRAW = 'WBDRW',
  MASTER_CTRL = 'MSCTL'
}




const SERVERS: RTCConfiguration = {
  iceServers: [
    { urls: 'stun:stun.services.mozilla.com' },
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'turn:numb.viagenie.ca', credential: 'Stefan12', username: 'stefan@dark-glow-arc.com' }
  ]
};

@Injectable({
  providedIn: 'root'
})

export class WebRtcService {
  public RxDataRaw = new Subject<string>();
  public RxWBoardCtrlCmd = new Subject<string>();
  public RxWBoardDrawCmd = new Subject<string>();
  public RxSlideViewCmd  = new Subject<string>();
  public RxMasterCtrlCmd = new Subject<string>();

  public RxMsgBoardText = new Subject<string>();


  public Loaded = false;
  // public ObsVideoState = new Subject<boolean>();
  // public ScrShareState = new Subject<boolean>();

  public MediaChannels: MediaChannel[] = new Array();


  private signalsCollection: AngularFirestoreCollection<RtcSignal>;
  private signalAddObs: Observable<RtcSignal[]>;
  private signalAddSub: Subscription;

  private avconfig: AVconfig;
  private pc: RTCPeerConnection;
  private senderId: string;

  private sigLayerOnData = false;
  private connectCounts = 0;
  private userId: string;
  private isMaster: boolean;

  private pcReset = false;

  private txSignalChan: RTCDataChannel;
  private rxSignalChan: RTCDataChannel;

  private txDataChan: RTCDataChannel;
  private txDataBuffer: string[] = new Array();
  private rxDataChan: RTCDataChannel;

  private pingCount: number;
  private pingMax: number;
  private pingSum: number;



  constructor(public afs: AngularFirestore,
    public auth: AuthService,
    public ses: SysEventsService,
    public confsrv: ConfigService) {
    // this.itemsCollection = afs.collection<Item>('students');
    // this.items = this.itemsCollection.valueChanges({ idField: 'id' });
    this.userId = auth.userData.uid;
    this.isMaster = auth.userData.isMaster;
    this.signalsCollection = afs.collection<RtcSignal>(TAGS.SIGNAL_FOLDER);
    this.senderId = this.ses.GuidV4();
    this.avconfig = confsrv.AVConfig;

    this.signalAddObs = this.signalsCollection.stateChanges(['added']).pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as RtcSignal;
        data.id = a.payload.doc.id;
        return { ...data };
      }))
    );

    //    this.setupWebRtc();
  }

  destroy(): void {
    this.signalAddSub.unsubscribe();
    this.ses.ClassOpen = false;
  }




  private audioStreamSpec(indx: number): MediaStreamConstraints {
    const devID = this.avconfig.lmAudio[indx];
    let audID: MediaTrackConstraints = null;
    if (devID) {
      audID = { deviceId: devID, echoCancellation: this.avconfig.echoCancel[indx] };
    } else {
      audID = { echoCancellation: this.avconfig.echoCancel[indx] };
    }
    const spec: MediaStreamConstraints = { audio: audID, video: false };
    return (spec);
  }

  private videoStreamSpec(indx: number): MediaStreamConstraints {
    const devID = this.avconfig.lmVideo[indx];
    const H = this.avconfig.lmHeight[indx];
    const W = this.avconfig.lmWidth[indx];
    const FPS = this.avconfig.lmFPS[indx];
    let vidID: MediaTrackConstraints = null;
    if (devID) {
      vidID = { deviceId: devID, width: W, height: H, frameRate: FPS };
    } else {
      vidID = { width: W, height: H, frameRate: FPS };
    }
    const spec: MediaStreamConstraints = { audio: false, video: vidID };
    return (spec);
  }

  private scrcapStreamSpec(): MediaStreamConstraints {
    const devID = 'screen';
    const H = 1200;
    const W = 1600;
    const FPS = 15;
    const vidID: MediaTrackConstraints = { deviceId: devID, width: W, height: H, frameRate: FPS };
    const audID: MediaTrackConstraints = { deviceId: 'screen' };
    const spec: MediaStreamConstraints = { audio: audID, video: vidID };
    return (spec);
  }




  public async SetObsVideo(state: boolean) {
    // switch MediaChannel[3] mode to stream Observation camera feed
    const mc = this.MediaChannels[3];
    if (state) {
      await this.SetScrShare(false);
      if (this.confsrv.validVideoDevice(1)) {
        mc.mediaConstraints = this.videoStreamSpec(1);
        await mc.Mute(false);
      }
    } else {
      await mc.UnMount();
    }
  }

  public async SetScrShare(state: boolean) {
    // switch MediaChannel[3] mode to stream Screen Share feed
    const mc = this.MediaChannels[3];
    if (state) {
      await this.SetObsVideo(false);
      mc.mediaConstraints = this.scrcapStreamSpec();
      mc.twin = this.MediaChannels[2];
      await mc.Mute(false);
    } else {
      await mc.UnMount();
    }
  }

  public async SetMicAudio(state: boolean) {
    await this.MediaChannels[0].Mute(!state);
  }

  public async SetCamVideo(state: boolean) {
    await this.MediaChannels[1].Mute(!state);
  }





  public async InitLocalMedia() {
    for (const chan of this.MediaChannels) {
      if (chan.autostart) {
        await chan.startMedia(false);
      }
    }
    await this.MakeOffer();
  }



  public async MakeAnswer() {
    const answer = await this.pc.createAnswer();
    await this.pc.setLocalDescription(answer);
    console.log('Sending Answer');
    this.sendSignal(this.senderId, JSON.stringify({ sdp: this.pc.localDescription }));
  }

  public async MakeOffer() {
    if (this.pc.connectionState !== 'connecting') {
      if (this.connectCounts > 0) {
        this.connectCounts--;
      }
      const offer = await this.pc.createOffer();
      await this.pc.setLocalDescription(offer);
      console.log('Sending an Offer');
      this.sendSignal(this.senderId, JSON.stringify({ sdp: this.pc.localDescription }));
    } else {
      this.connectCounts++;
    }
  }

  public async MakeOfferProxy() {
    await this.MakeOffer();
  }


  private async closeAllMedia() {
    for (const chan of this.MediaChannels) {
      await chan.closeMedia();
    }
  }


  private async killPC() {
    console.log('Shutting WebRTC.PC down now...');
    this.Loaded = false;
    if (this.signalAddSub) {
      this.signalAddSub.unsubscribe();
    }
    await this.closeAllMedia();
    this.txDataBuffer.length = 0;

    this.txSignalChan.close();
    this.txSignalChan = null;
    this.txDataChan.close();
    this.txDataChan = null;
    this.rxSignalChan.close();
    this.rxSignalChan = null;
    this.rxDataChan.close();
    this.rxDataChan = null;

    this.pc.onsignalingstatechange = null;
    this.pc.onconnectionstatechange = null;
    this.pc.ondatachannel = null;
    this.pc.close();
    this.pc = null;
    this.MediaChannels.length = 0;
  }

  public async CloseWebRTC(imm: boolean) {
    if (this.pc) {
      //      this.pcReset = true;
      if (!imm && this.isMaster) {
        this.ses.ClassOpen = false;
        console.log('Sending EXIT cmd to remote');
        this.SendMasterCtrlAction(MASTER_CTRL.EXIT);
        await this.CleanupSignals();
        setTimeout(() => {
          this.CloseWebRTC(true);
        }, 1000);
      } else {
        await this.killPC();
      }
    }
  }


  private resetLocalVideo(reload: boolean) {
    // if (this.videoLocal !== null) {
    //   this.videoLocal.pause();
    //   this.videoLocal.removeAttribute('src'); // empty source
    //   if (reload) {
    //     this.videoLocal.load();
    //   } else {
    //     this.videoLocal.srcObject = null;
    //   }
    // }
  }



  public onRemoteTrackAdded(param: MediaStreamTrackEvent) {
    const track = param.track;
    console.log('Stream.remtrack Added label=' + track.label);
    if (track.kind === 'video') {

    }
  }

  public onRemoteTrackRemoved(param: MediaStreamTrackEvent) {
    console.log('Stream.remtrack Removed label=' + param.track.label);
  }

  public onRemoteTrackMuted(param: any) {
    console.log('Rem track Muted');
  }

  public onRemoteTrackUnMuted(param: any) {
    console.log('Rem track UnMuted');
  }

  public onRemoteTrackEnded(param: any) {
    console.log('Rem track Ended');
  }

  async CleanupSignals() {

    if (this.signalAddSub) {
      this.signalAddSub.unsubscribe();
      this.signalAddSub = null;
    }

    let count = (await this.signalsCollection.ref.get()).docs.length;
    while (count > 0) {
      console.log('Found ' + count + ' stale Signal entries in db');
      const items = await this.signalsCollection.ref.get();
      for (const doc of items.docs) {
        console.log('Removing stale Signal entry from db = ' + doc.id);
        try {
          await this.signalsCollection.doc(doc.id).delete();
        } catch (error) {
          console.error('Error removing document: ', error);
        }
      }
      count = (await this.signalsCollection.ref.get()).docs.length;
    }
  }





  public async InitWebRTC(): Promise<boolean> {

    if (this.pc) {
      console.log(' Unlawfull state !!!!. PC should be dead !!! ')
      await this.killPC();
    }

    if (this.ses.Master) {
      await this.CleanupSignals();
    }

    this.signalAddSub = this.signalAddObs.subscribe(
      x => this.readSignal(x),
      err => console.error('signal got an error: ' + err),
      () => console.log(' signal got a complete notification')
    );

    this.pc = new RTCPeerConnection(SERVERS);

    let mc = new MediaChannel('audio0', this.pc, () => { this.MakeOfferProxy(); });
    mc.mediaConstraints = this.audioStreamSpec(0);
    mc.autostart = true;
    mc.mediaT = MediaT.AUDIO;
    this.MediaChannels.push(mc);

    mc = new MediaChannel('video0', this.pc, () => { this.MakeOfferProxy(); });
    mc.mediaConstraints = this.videoStreamSpec(0);
    mc.autostart = true;
    mc.mediaT = MediaT.VIDEO;
    this.MediaChannels.push(mc);

    mc = new MediaChannel('audio1', this.pc, () => { this.MakeOfferProxy(); });
    mc.mediaConstraints = this.audioStreamSpec(1);
    mc.mediaT = MediaT.AUDIO;
    this.MediaChannels.push(mc);

    mc = new MediaChannel('video1', this.pc, () => { this.MakeOfferProxy(); });
    mc.mediaConstraints = this.videoStreamSpec(1);
    mc.mediaT = MediaT.VIDEO;
    this.MediaChannels.push(mc);

    // pc2.ontrack = ({track, streams: [stream]}) => {
    //   track.onunmute = () => {
    //     if (!video2.srcObject) video2.srcObject = stream;
    //   };
    //   stream.onremovetrack = ({track}) => {
    //     console.log(`${track.kind} track was removed.`);
    //     if (!stream.getTracks().length) {
    //       console.log(`stream ${stream.id} emptied (effectively removed).`);
    //     }
    //   };
    // };




    // this.pc.ontrack = (event) => {
    this.pc.ontrack = ({ transceiver: trans }) => {
      //      const trans = event.transceiver;
      //      const stream = event.streams[0];
      //     const track = event.track;
      console.log('OnTrack Transceiver mid=' + trans.mid + '  track kind=' + trans.receiver.track.kind);
      //      console.log('OnTrack event with transceiver mid=' + transceiver.mid + ' recv track kind=' + transceiver.receiver.track.kind);
      this.HookupReceiver(trans);
    }; // use ontrack

    this.pc.onicecandidate = event => {
      event.candidate ? this.sendSignal(this.senderId, JSON.stringify({ ice: event.candidate }))
        : console.log('Sent All ICE');
    };
    this.pc.onsignalingstatechange = this.onSignalStateChange.bind(this);
    this.pc.onconnectionstatechange = this.onConnectStateChange.bind(this);


    console.log(' Config Data Channels..');
    this.txDataChan = this.pc.createDataChannel('[data]' + this.senderId);
    this.txDataChan.onopen = this.onTxDataChanStatusChange.bind(this);
    this.txDataChan.onclose = this.onTxDataChanStatusChange.bind(this);

    this.txSignalChan = this.pc.createDataChannel('[signal]' + this.senderId);
    this.pc.ondatachannel = this.onRxChanCallback.bind(this);


    if (this.ses.Master) {
      console.log('Opening Class...');
      this.ses.ClassOpen = true;
    }

    this.Loaded = true;
    return Promise.resolve(true);
  }




  private HookupReceiver(trans: RTCRtpTransceiver) {
    console.log('Hookup receiver mid=' + trans.mid + ' recv track kind=' + trans.receiver.track.kind);
    const indx = parseInt(trans.mid, 10);

    const stream =  new MediaStream([trans.receiver.track]);
    stream.onremovetrack = () => { this.MediaChannels[indx].onRemoteTrackRemoved(); };
    this.MediaChannels[indx].remStream = stream;
    this.MediaChannels[indx].HookRemoteSinks(true);

    if (!trans.receiver.track.onmute) {
     trans.receiver.track.onmute = () => { this.MediaChannels[indx].onRecvTrackStopped(indx); };
     trans.receiver.track.onunmute = () => { this.MediaChannels[indx].onRecvTrackStarted(indx); };
    }
    this.MediaChannels[indx].transceiver = trans;

  }

  private HookupReceivers() {
    const trans: RTCRtpTransceiver[] = this.pc.getTransceivers();
    for (const trx of trans) {
      if (trx.mid) {
        this.HookupReceiver(trx);
      }
    }

  }

  private async onSignalStateChange(evt: any) {

    switch (this.pc.signalingState) {
      case 'stable':
        console.log('SignalState:ICE neg complete');
        break;
      case 'have-remote-offer':
        const len = this.pc.getTransceivers().length;
        const transc = this.pc.getTransceivers();
        console.log('SignalState: Have Rem-offer with ' + this.pc.getTransceivers().length + ' transceivers');
        this.HookupReceivers();
        for (const chan of this.MediaChannels) {
          if (chan.autostart) {
           await chan.mountRemote();
          }
        }
        await this.MakeAnswer();
        break;
    }

  }

  private resetConnection() {
    this.sigLayerOnData = false;
    if (this.pcReset) { return; }
    this.pcReset = true;
    console.log('Resetting connection=>' + this.pc.connectionState);
    //    this.resetLocalVideo(true);
    //    this.resetRemoteVideo(true);

    if (this.pc) {

      if (this.txDataChan) {
        this.txDataChan.close();
        this.txDataBuffer.length = 0;
        this.txDataChan = null;
        this.rxDataChan = null;
      }
      if (this.txSignalChan) {
        this.txSignalChan.close();
        this.txSignalChan = null;
        this.rxSignalChan = null;
      }

      this.pc = null;
    }

    this.InitWebRTC();
  }


  onConnectStateChange(evt: any) {
    console.log('Connection => ' + this.pc.connectionState);
    switch (this.pc.connectionState) {
      case 'connected':
        // The connection has become fully connected
        //        this.sigLayerOnData = true;
        this.ses.emitClassInSession(true);
        if (this.connectCounts > 0) {
          //          this.MakeRemoteOffer();
        }
        break;
      case 'disconnected':
      case 'failed':
        // One or more transports has terminated unexpectedly or in an error
        //        this.resetConnection();
        this.ses.emitClassInSession(false);
        break;
      case 'closed':
        // The connection has been closed
        //        this.resetConnection();
        break;
    }
  }

  // ################################################################################################################
  // ################################################################################################################
  ///                                     DATA Channel realted code
  // ################################################################################################################
  // ################################################################################################################


  public doPing(count: number) {
    this.ExeLocalCommand('&ping=' + count);
  }


  onRxSignal(event) {
    const signal: string = event.data;
    const sigArr: RtcSignal[] = new Array(1);
    sigArr[0] = JSON.parse(signal);
    this.readSignal(sigArr);
  }


  onRxData(event) {
    const msg: string = event.data;
    this.RxDataRaw.next(msg);

    if (msg.charAt(0) === '#') {
      this.ExeRemoteCommand(msg);
    }
  }

  public TransmitSignal(signal: string) {
    if (this.txSignalChan.readyState === 'open') {
      this.txSignalChan.send(signal);
    }
  }

  private TransmitDataTry() {
    while ((this.txDataChan.readyState === 'open') && (this.txDataBuffer.length > 0)) {
      const msg = this.txDataBuffer.pop();
      this.txDataChan.send(msg);
    }
  }

  public TransmitData(msg: string) {
    this.txDataBuffer.push(msg);
    this.TransmitDataTry();
  }

  public SendMBoardTxt(msg: string) {
    this.TransmitData('#' + DATA_CHAN.MBOARD_TXT + '=' + msg);
  }

  public SendWBoardCtrlAction(param: string) {
    this.TransmitData('#' + DATA_CHAN.WBOARD_CTRL + '=' + param);
  }

  public SendWBoardDrawAction(param: string) {
    this.TransmitData('#' + DATA_CHAN.WBOARD_DRAW + '=' + param);
  }

  public SendMasterCtrlAction(cmd: string, param0: number = 0, param1: number = 0, msg: string = '') {
    const param = '[' + cmd + ':' + param0.toString() + ':' + param1.toString() + ']' + msg;
    this.TransmitData('#' + DATA_CHAN.MASTER_CTRL + '=' + param);
  }

  public SendRemoteCommand(cmd: string, param: string) {
    this.TransmitData('#' + cmd + '=' + param);
  }

  private ExeRemoteCommand(text: string) {
    if (text.charAt(0) === '#') {
      const snips = text.split('=');
      if (snips.length > 1) {
        const cmd = snips[0].substr(1);
        const param = snips[1];
        switch (cmd) {
          case 'EXIT':
            //              this.doExit(false);
            break;
          case 'PINGX':
            this.SendRemoteCommand('PINGR', param);
            break;
          case 'PINGR':
            const ttx = +param;
            const tnow = +performance.now();
            const trx = tnow - ttx;
            this.pingSum = +this.pingSum + trx;
            if (this.pingCount > 0) { this.pingCount--; }
            const denom = +(this.pingMax - this.pingCount);
            const mavg = +this.pingSum / denom;
            console.log('Ping transit[' + this.pingCount + ']=', trx.toString() + 'ms  ' + '   avg=' + mavg);
            if (this.pingCount > 0) {
              this.SendRemoteCommand('PINGX', tnow.toString());
            }
            break;
          case DATA_CHAN.MASTER_CTRL:
            this.RxMasterCtrlCmd.next(param);
            break;
          case DATA_CHAN.WBOARD_DRAW:
            this.RxWBoardDrawCmd.next(param);
            break;
          case DATA_CHAN.WBOARD_CTRL:
            this.RxWBoardCtrlCmd.next(param);
            break;
          case DATA_CHAN.MBOARD_TXT:
            this.RxMsgBoardText.next(param);
            break;
        }
      }
    }
  }

  ExeLocalCommand(text: string) {
    if (text.charAt(0) === '&') {
      const snips = text.split('=');
      if (snips.length > 1) {
        const cmd = snips[0].substr(1);
        const param = snips[1];
        console.log('Exec Local CMD = ', cmd + '[' + param + ']');

        switch (cmd) {
          case 'ping':
            this.pingCount = +param;
            this.pingMax = +param;
            this.pingSum = 0;
            const tnow = performance.now();
            this.SendRemoteCommand('PINGX', tnow.toString());
            break;
          case 'moderator':
            localStorage.setItem(cmd, param);
            // if (param === 'true') this.Moderator = true
            // else this.Moderator = false;
            break;
          case 'debug':
            localStorage.setItem(cmd, param);
            break;
        }
      }
    }
  }


  onRxChanCallback(event) {
    const label: string = event.channel.label;
    //   console.log('onRx callback..' + label);
    if (label.startsWith('[data]')) {
      this.rxDataChan = event.channel;
      this.rxDataChan.onmessage = this.onRxData.bind(this);
      this.rxDataChan.onopen = this.onRxDataChanStatusChange.bind(this);
      this.rxDataChan.onclose = this.onRxDataChanStatusChange.bind(this);
    }
    if (label.startsWith('[signal]')) {
      this.rxSignalChan = event.channel;
      this.rxSignalChan.onmessage = this.onRxSignal.bind(this);
      this.rxSignalChan.onopen = this.onRxSignalChanStatusChange.bind(this);
      this.rxSignalChan.onclose = this.onRxSignalChanStatusChange.bind(this);
    }
  }

  onRxDataChanStatusChange(event) {
    if (this.rxDataChan) {
      //      console.log('RxData stat=> ' + this.rxDataChan.readyState);
      if (this.rxDataChan.readyState === 'closed') {
        //        this.resetConnection();
      }
    }
  }

  onTxDataChanStatusChange(event) {
    if (this.txDataChan) {
      const state = this.txDataChan.readyState;
      //     console.log('TxData stat=> ' + state);
      switch (state) {
        case 'open':
          this.TransmitDataTry();
          break;
        case 'closed':
          break;
      }
    }
  }


  onRxSignalChanStatusChange(event) {
    if (this.rxSignalChan) {
      const state = this.rxDataChan.readyState;
      //      console.log('RxSignal stat=> ' + state);
      switch (state) {
        case 'open':
          this.sigLayerOnData = true;
          break;
        case 'closed':
          this.sigLayerOnData = false;
          break;
      }
    }
  }



  // ####################################################################################### //
  // ####################################################################################### //
  // ##############################  SIGNALS used to negotiate the connection ############### //
  // ####################################################################################### //
  // ####################################################################################### //


  async delSignal(docId: string) {
    if (this.sigLayerOnData) {
      return;
    }
    await this.signalsCollection.doc(docId).delete().then(() => {
      //      console.log('removed =>' + docId);
    }).catch((error) => {
      console.error('Error removing document: ', error);
    });
  }


  sendSignal(senderId, data) {

    const msg: RtcSignal = { id: null, uid: this.userId, senderUid: senderId, message: data };
    if (this.txSignalChan.readyState !== 'open') {
      this.sigLayerOnData = false;
    }

    if (this.sigLayerOnData) {
      const signal = JSON.stringify(msg);
      this.TransmitSignal(signal);
    } else {
      this.signalsCollection.add(msg).then((doc) => {
        this.delSignal(doc.id);
      });
    }
  }

  readSignal(data: any) {
    if (!data) { return; }
    const webdata: RtcSignal[] = data as RtcSignal[];

    for (const wdat of webdata) {

      if (wdat.message === undefined) { break; }
      if (wdat.senderUid === undefined) { break; }
      if (wdat.senderUid === '0') { break; }  // dont delete class-is-open doc entry

      if (wdat.uid === this.userId) {
        // I remove my own, the other client also recieved a copy already
        this.delSignal(wdat.id);
        break;
      }

      if (wdat.senderUid === this.senderId) { break; }

      const msg = JSON.parse(wdat.message);
      const msdp = msg.sdp;

      if (msg.ice !== undefined) {
        //    console.log('..add-ice..' + wdat.id);
        this.pc.addIceCandidate(new RTCIceCandidate(msg.ice)).then(() => {
        });
      }
      else if (msdp.type === 'offer') {
        const sd = new RTCSessionDescription(msdp);
        //      console.log('..offer..' + sd.sdp.length);
        this.pc.setRemoteDescription(sd).then(() => {
        });
      }
      else if (msdp.type === 'answer') {
        const sd = new RTCSessionDescription(msdp);
        //        console.log('..answer..' + sd.sdp.length);
        this.pc.setRemoteDescription(sd).then(() => {
        });
      }
    }
  }












}
// https://jsfiddle.net/w2p96x0b/2/
// https://jsfiddle.net/w2p96x0b/1/
// https://jsfiddle.net/w2p96x0b/

// <button onclick='trans1 = pc1.addTransceiver(trackA)'>transceiver = pc.addTransceiver(trackA, {streams})</button><br>
// <button onclick='trans1.direction = "recvonly";trans1.sender.replaceTrack(null);'>tranceiver.direction = "recvonly"</button><br>
// <button onclick='trans1.sender.replaceTrack(trackA);trans1.direction = "sendrecv"'>tranceiver.direction = "sendrecv"</button><br>
// <button onclick='trans1.stop()'>transceiver.stop()</button><br>

// pc2.ontrack = ({transceiver:trans2}) => {
//   log("pc.ontrack with transceiver ");

//   trans2.receiver.track.onmute = () => {
//    log("transceiver.receiver.track.onmute");
//     video.srcObject = null;
//    };
//   trans2.receiver.track.onunmute = () => {
//     log("transceiver.receiver.track.onunmute");
//     video.srcObject = new MediaStream([trans2.receiver.track]);
//   };
// };
// <script src="https://webrtc.github.io/adapter/adapter-latest.js"></script>

// const console = { log: msg => div.innerHTML += msg + "<br>" };
// const pc1 = new RTCPeerConnection(), pc2 = new RTCPeerConnection();

// let videoSender, audioSender;

// (async () => {
//   try {
//     const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
//     audioSender = pc1.addTrack(stream.getAudioTracks()[0], stream);
//     videoSender = pc1.addTrack(stream.getVideoTracks()[0], stream);
//     video1.srcObject = stream;
//   } catch (e) {
//     console.log(e);
//   }
// })();

// removeAudio.onclick = () => pc1.removeTrack(audioSender);
// removeVideo.onclick = () => pc1.removeTrack(videoSender);

// pc1.onicecandidate = e => pc2.addIceCandidate(e.candidate);
// pc2.onicecandidate = e => pc1.addIceCandidate(e.candidate);
// pc1.oniceconnectionstatechange = () => console.log(pc1.iceConnectionState);

// pc1.onnegotiationneeded = async () => {
//   try {
//     await pc1.setLocalDescription(await pc1.createOffer());
//     await pc2.setRemoteDescription(pc1.localDescription);
//     await pc2.setLocalDescription(await pc2.createAnswer());
//     await pc1.setRemoteDescription(pc2.localDescription);
//   } catch (e) {
//     console.log(e);
//   }
// }

// pc2.ontrack = ({track, streams: [stream]}) => {
//   track.onunmute = () => {
//     if (!video2.srcObject) video2.srcObject = stream;
//   };
//   stream.onremovetrack = ({track}) => {
//     console.log(`${track.kind} track was removed.`);
//     if (!stream.getTracks().length) {
//       console.log(`stream ${stream.id} emptied (effectively removed).`);
//     }
//   };
// };

// function whiteNoise() {
//   let canvas = Object.assign(document.createElement("canvas"), {width: 320, height: 240});
//   let ctx = canvas.getContext('2d');
//   ctx.fillRect(0, 0, canvas.width, canvas.height);
//   let p = ctx.getImageData(0, 0, canvas.width, canvas.height);
//   requestAnimationFrame(function draw(){
//     for (var i = 0; i < p.data.length; i++) {
//       p.data[i++] = p.data[i++] = p.data[i++] = Math.random() * 255;
//     }
//     ctx.putImageData(p, 0, 0);
//     requestAnimationFrame(draw);
//   });
//   return canvas.captureStream(60).getTracks()[0];
// }
