import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { Observable } from 'rxjs';
import { SysEventsService } from '../services/sysevents.service';

@Injectable({
  providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {

  constructor(
    public auth: AuthService,
    public router: Router,
    public ses: SysEventsService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ses.LoggedIn) {
//       window.alert('You are not allowed to access this URL!');
       this.router.navigate(['dashboard']);
    }
    return true;
  }

}
