import { FieldType } from '@ngx-formly/core';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-prop-wrapper',
  template: `
  <style>
  .prop-table {
    width: 100%;
    table-layout: fixed;
    }
  .prop-label{
    width: 25%;
    text-align: right;
    word-break: normal;
    word-wrap: break-word;
    }
  .prop-value{
    text-align: left;
   }
  </style>
  <table class="prop-table">
    <tr>
      <td class="prop-label" valign="middle">
        <div style="margin-right:5px">
          {{ to.label }}
        </div>
      </td>
      <td class="prop-value">
        <div style="margin-left:5px">
          <ng-container #fieldComponent>
          </ng-container>
        </div>
      </td>
    </tr>
  </table>
  `,
})
export class PropWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-color-picker',
   template: `
   <style>
     .formly-col-picker{
       width: 100%;
       outline-color: black;
       border-color: white;
       border-width: 1px;
      }
   </style>
  <input class="formly-col-picker" [(colorPicker)]="colour" [style.background]="colour" [value]="colour" [cpFallbackColor]="'#808080'"  [cpPosition]="'top'" [cpPositionRelativeToArrow]="'true'"
    [formControl]="formControl" [formlyAttributes]="field">
  `
  ,
})

// tslint:disable-next-line:component-class-suffix
export class FormlyColorPicker extends FieldType {

  colorSave: string;
  get colour(): string {
    this.colorSave = this.field.model[this.key];
    return this.colorSave;
  }
  set colour(col: string) {
    this.colorSave = col;
    this.field.model[this.key] = this.colorSave;
  }

//   template: `
//  <input [(colorPicker)]="color" [style.background]="color" [cpPosition]="'top'" [cpPositionRelativeToArrow]="'true'"
//    [formControl]="formControl" [formlyAttributes]="field">
//  `

// template: `
// <input type="color" value="colour" [formControl]="formControl" [formlyAttributes]="field">
// `


}
