<style>
  .draw-toolbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  #tb-Row1 {
    margin: 0 auto;
  }

  #tb-Row2 {
    margin: 0 auto;
  }

  #tb-Row3 {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  #tb-Row4 {
    margin: 0 auto;
  }

  .btn-icon {
    height: 40px;
    width: 40px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    vertical-align: webkit-baseline-middle;
    color: black;
    background-color: white;
    border-color: black;
  }

  .custom-btns {
    margin-left: 3px;
    height: fit-content;
  }
  .custom-btns.active {
    color: black;
    background-color:#17a2b8;
    border-color: black;
  }


  .custom-btns:not(.active) {
    color: white;
    background-color: #303030;
    border-color:black;
  }

  .custom-btns.focus {
    box-shadow: 0 0 0 0 white;
  }

  .btn {
    background-color:#ffc107;
    color: black;
    border-color: black;
  }

  .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 white;
  }

  #color-picker {
    background-color: lightgrey;
    padding: 2px;
    width: 208px;
  }


  /* HIDE RADIO */
  [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type=radio]+img {
    cursor: pointer;
  }

  /* CHECKED STYLES */
  [type=radio]:checked+img {
    outline: 1px solid #f00;
  }

  .pen-nib-select {
    margin: 2px;
    padding: 2px;
  }
</style>


<div class="draw-toolbar">

  <div id="tb-Row1"
       [style.display]="divWBCtrlsDisplay">
    <div class="btn-group btn-group-toggle">

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [(ngModel)]="PenModeState">
        <fa-icon [icon]="bnDrawIcon"
                 placement="bottom"
                 ngbTooltip="Draw Pen"></fa-icon>
      </label>

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [(ngModel)]="MoveModeState">
        <fa-icon [icon]="bnMoveIcon"
                 placement="bottom"
                 ngbTooltip="Move Objects"></fa-icon>
      </label>

      <button type="button"
              class="btn btn-outline-dark"
              (click)="bnClearClick()"
              placement="bottom"
              ngbTooltip="Clear">
        <fa-icon [icon]="bnClearIcon"></fa-icon>
      </button>

      <!-- <button type="button"
              class="btn btn-outline-dark"
              (click)="bnLoadClick()">
        <fa-icon [icon]="bnLoadIcon"
                 placement="bottom"
                 ngbTooltip="Load"></fa-icon>
      </button>

      <button type="button"
              class="btn btn-outline-dark"
              (click)="bnSaveClick()">
        <fa-icon [icon]="bnSaveIcon"
                 placement="bottom"
                 ngbTooltip="Download"></fa-icon>
      </button> -->

    </div>


  </div>

  <div id="tb-Row2"
       [style.display]="divWBPenToolDisplay">
    <!-- <github-picker id="color-picker"
                   [control]="ghColorControl"
                   style="width:auto"></github-picker> -->
  </div>


  <div id="tb-Row3"
       [style.display]="divWBPenToolDisplay">

    <div class="btn-group btn-group-toggle"
         style = "border: 1px solid rgba(0,0,0,.2); border-radius:4px; background-color:#D3D3D3 ; box-shadow: rgba(0,0,0,.15) 0 3px 12px; ">
      <span style="font-size:16px; margin-top:4px; margin-left:4px;">Pen-size</span>
      <label class="pen-nib-select">
        <input type="radio"
               name="pen-size"
               (click)="this.penWidth=5">
        <img src="/assets/circle-fill-4.svg"
             width="24px"
             height="24px"
             ngbTooltip="5px">
      </label>

      <label class="pen-nib-select">
        <input type="radio"
               name="pen-size"
               (click)="this.penWidth=10"
               checked>
        <img src="/assets/circle-fill-8.svg"
             width="24px"
             height="24px"
             ngbTooltip="10px">
      </label>

      <label class="pen-nib-select">
        <input type="radio"
               name="pen-size"
               (click)="this.penWidth=25">
        <img src="/assets/circle-fill-16.svg"
             width="24px"
             height="24px"
             ngbTooltip="25px">
      </label>

      <label class="pen-nib-select">
        <input type="radio"
               name="pen-size"
               (click)="this.penWidth=50">
        <img src="/assets/circle-fill-32.svg"
             width="24px"
             height="24px"
             ngbTooltip="50px">
      </label>
    </div>

  </div>


  <!-- <div id="tb-Row3" [style.display]="divWBPenToolDisplay" style="height: auto;">
    <div class="form-group row">
      <label style="vertical-align:middle; height:20px; width:auto; font-size:14px; text-align:left; margin:0px; ; margin-right:5px;margin-left:5px;"
             class="control-label" for="pWidth">Pen Size</label>
      <input name="pWidth"
             style="vertical-align:middle; width:60px; height:20px;"
             [(ngModel)]="penWidth"
             class="form-control"
             type="number"
             maxlength="2"
             max="50"
             min="5"
             step="5">
    </div>
  </div> -->


  <div id="tb-Row4"
       [style.display]="divWBRemCtrlDisplay">
    <div class="btn-group btn-group-toggle">

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [(ngModel)]="WBRemoteVisible"> Remote
        <fa-icon [icon]="bnWBRemoteVisibleIcon"
                 placement="bottom"
                 ngbTooltip="Remote On/Off"></fa-icon>
      </label>

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [disabled]="!WBRemoteVisible ? 'disabled': null"
               [(ngModel)]="WBRemotePenMode">
        <fa-icon [icon]="bnWBRemoteDrawIcon"
                 placement="bottom"
                 ngbTooltip="Remote-Draw"></fa-icon>
      </label>

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [disabled]="!WBRemoteVisible ? 'disabled': null"
               [(ngModel)]="WBRemoteMoveMode">
        <fa-icon [icon]="bnWBRemoteMoveIcon"
                 placement="bottom"
                 ngbTooltip="Remote-Move"></fa-icon>
      </label>

    </div>
  </div>


  <input type="file"
         id="filePicker"
         style="visibility:hidden; height:0px">
  <a id="svgFile"> </a>
</div>
