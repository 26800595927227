import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';


export enum TBI_TYPE {
  NONE = '',
  BOOL = 'BOOL',
  BUTTON = 'BUTTON',
  CMDBUTTONS = 'CMDBUTTONS',
  BTNGROUP = 'BTNGROUP',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  COLOR = 'COLOR',
  MENU = 'MENU'
}

// export class ValueObject{
//   value: any;
// }

export class ToolbarItem {
  name: string;
  type?: string;
  id?: any;
  index?: number;
  selIndex?: number;
  width?: string;
  callBack?: any;
  value?: any;
  icon?: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  items?: ToolbarItem[];
  update?: any;
  offsetX?: string;
  position?: string;
}



@Component({
  selector: 'app-toolbar-item',
  templateUrl: './toolbar-item.component.html'
})

export class ToolbarItemComponent implements OnInit {
  @Input() item: ToolbarItem;
  @Input() color: string;
  public hidden = false;
  public type: string;
  public id: any;

  public submenuMode = {
    name: 'onClick',
    delay: { show: 0, hide: 300 }
  };

  public presetColors = [null, '#980000', '#ff0000', '#ff9900', '#ffff00', '#00ff00', '#00ffff', '#4a86e8', '#0000ff', '#9900ff', '#ff00ff', '#ff3466'];

  constructor(public cdr: ChangeDetectorRef) {
  }


  public set Hidden(state: boolean) {
    this.hidden = state;
  }

  updateGUI(newvalue: any){
    switch (this.type) {
      case 'BOOL':
      case 'BUTTON':
      case 'CMDBUTTONS':
       break;


      case 'NUMBER':
      case 'TEXT':
      case 'COLOR':
       this.item.value = newvalue;
       break;

       case 'BTNGROUP':
        break;

      case 'SELECT':
        const indx = this.item.items.findIndex(itm => itm.index === newvalue);
        if (indx > -1){
          this.item.selIndex = indx;
        }
        break;
      }
    this.cdr.detectChanges();
  }

  ngOnInit() {

    this.item.callBack = this.item.callBack ? this.item.callBack : this.dummyCB.bind(this);
    this.item.update = this.updateGUI.bind(this);

    if (this.item.items) {
      for (const [index, element] of this.item.items.entries()) {
        element.index = element.index ? element.index : index;
      }
    }
    this.type = this.item.type;
    this.id = this.item.id;

    switch (this.type) {
      case 'BOOL':
        break;

      case 'BUTTON':
        break;

      case 'CMDBUTTONS':
        for (const [index, element] of this.item.items.entries()) {
          element.callBack = element.callBack ? element.callBack : this.item.callBack;
        }
        if (this.item.width === undefined){
          this.item.width = (this.item.items.length * 28).toString() + 'px';
        }
        break;

      case 'BTNGROUP':
        break;

      case 'NUMBER':
        break;

      case 'TEXT':
        break;

      case 'COLOR':
        break;

      case 'SELECT':
        for (const [index, element] of this.item.items.entries()) {
          if (element.id === undefined) {
            element.id = element.value ? element.value : this.item.id + '_' + index.toString();
          }
          if (element.index === undefined) {
            element.index = index;
          }
        }
        break;

      case 'MENU':
        // this.item.callBack = this.routeCallBack.bind(this);
        // this.items = [{
        //   name: this.name,
        //   icon: this.icon,
        //   id: this.id,
        //   disabled: this.disabled,
        //   callBack: this.callBack,
        //   items: this.item.items
        // }];

        // for (const [index, element] of this.items.entries()) {
        //   if (element.id === undefined) {
        //     element.id = element.value ? element.value : this.id + '_' + index.toString();
        //   }
        //   if (element.index === undefined) {
        //     element.index = index;
        //   }
        // }
        break;
    }
  }

  public routeCallBack(id: string, obj: any) {
    if (obj.callBack) {
      if (obj.value) {
        obj.callBack.call(this, id, obj.value);
      } else {
        obj.callBack.call(this, id, obj.name);
      }
    }

  }

  public dummyCB(id: string, ev: any) {
  }


}
