import { Component, EventEmitter, OnInit, OnDestroy, Input, AfterViewInit, Output } from '@angular/core';
import { fabric } from 'fabric';
import { SysEventsService, CANV } from 'src/app/shared/services/sysevents.service';
import { Subscription } from 'rxjs';
import { WBREM_CTRL, WebRtcService } from 'src/app/shared/services/webrtc.service';
import { FormGroup } from '@angular/forms';

import {
  faBroom,
  faEraser,
  faPen,
  faChalkboardTeacher,
  faChalkboard,
  faArrowsAlt
} from '@fortawesome/free-solid-svg-icons';

import {
  faEdit,
  faObjectUngroup,
  faEye,
  faEyeSlash,
  faSave,
  faFolderOpen,
} from '@fortawesome/free-regular-svg-icons';
import { DrawCanvasComponent } from './draw-canvas.component';


@Component({
  selector: 'app-draw-tools',
  templateUrl: './draw-tools.component.html'
})

export class DrawToolsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() drawCanvID: string;

  @Input() set WBEditMoveMode(value: boolean) {
    this.wbEditMoveMode = value;
    if (!this.ses.Master) {
      this.divWBCtrlsDisplay = 'none';
      this.divWBRemCtrlDisplay = 'none';

      this.setCanvasEditMode(value);
    }
  }

  get WBEditMoveMode(): boolean {
    return this.wbEditMoveMode;
  }


  @Input() set WBEditPenMode(value: boolean) {
    this.wbEditPenMode = value;
    if (!this.ses.Master) {
      this.divWBCtrlsDisplay = 'none';
      this.divWBRemCtrlDisplay = 'none';
      if (this.canvas) {
        this.canvas.isDrawingMode = value;
      }
      if (value) {
        this.divWBPenToolDisplay = 'block';
      } else {
        this.setCanvasEditMode(false);
        this.divWBPenToolDisplay = 'none';
      }
    }
  }
  get WBEditPenMode(): boolean {
    return this.wbEditPenMode;
  }

  @Input() set RemOnline(value: boolean) {
    this.divWBRemCtrlDisplay = 'block';
  }

  @Input() drawCanvasComp: DrawCanvasComponent;

  public drawCanvContainer: HTMLDivElement;
  public canvas: fabric.Canvas;
  public tbDiv: HTMLDivElement;

  public checkboxGroupForm: FormGroup;
  public bnMoveIcon = faArrowsAlt;
  public bnDrawIcon = faPen;
  public bnClearIcon = faEraser;

  public bnWBRemoteVisibleIcon = faEyeSlash;
  public bnWBRemoteDrawIcon = faPen;
  public bnWBRemoteMoveIcon = faArrowsAlt;

  public bnSaveIcon = faSave;
  public bnLoadIcon = faFolderOpen;

  private penModeState = false;
  private moveModeState = true;


  private wbRemoteVisible = false;
  private wbRemoteDraw = false;
  private wbRemoteMove = false;

  private mySESubs: Subscription;

  public divWBRemCtrlDisplay = 'none';
  public divWBCtrlsDisplay = 'block';
  public divWBPenToolDisplay = 'block';

  private wbEditPenMode = false;
  private wbEditMoveMode = false;


  fileContent = '';



//  public ghColorControl = new ColorPickerControl().setValueFrom('#7F7F7F').setColorPresets(['#7F7F7F', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF']);

  private svgFile: HTMLElement;

  constructor(public ses: SysEventsService, public webrtc: WebRtcService) {
  }


  ngOnDestroy(): void {
    this.mySESubs.unsubscribe();
  }

  ngOnInit(): void {
    const drawCanvasDiv = document.getElementById(this.drawCanvID) as HTMLCanvasElement;
    this.drawCanvContainer = drawCanvasDiv.parentElement as HTMLDivElement;
    this.drawCanvContainer.onkeydown = this.onKeyDown.bind(this);
    this.drawCanvContainer.onmousedown = this.onContainerMouseDown.bind(this);
    this.drawCanvContainer.tabIndex = 1000;

    this.canvas = this.ses.gDrawCanvas;

//    this.ghColorControl.valueChanges.subscribe((col) => { this.setPenColor(col); });
    this.mySESubs = this.ses.onWBVisibleChange.subscribe((visible: boolean) => { this.setWBVisibleState(visible); });
    if (this.ses.Master) {
      this.divWBCtrlsDisplay = 'block';
      this.divWBRemCtrlDisplay = 'none';
      this.divWBPenToolDisplay = 'block';
    } else {
      this.divWBCtrlsDisplay = 'none';
      this.divWBRemCtrlDisplay = 'none';
      this.divWBPenToolDisplay = 'none';
      this.setCanvasEditMode(false);
    }
    this.penWidth = 10;
    this.MoveModeState = false;
    this.PenModeState = false;
  }

  ngAfterViewInit() {
    if (!this.ses.Master) {
      // notify master that remote is on-line
      this.SendRemoteCmd(WBREM_CTRL.REMON, true);
    }
  }

  private clrAllObjects() {
    if (this.canvas) {
      this.canvas.discardActiveObject();
      this.canvas.forEachObject((o) => {
        this.canvas.remove(o);
      });
      this.canvas.renderAll();
    }
    //    this.checkDirty();
  }

  private setCanvasEditMode(value: boolean) {
    if (this.canvas) {
      this.canvas.discardActiveObject();
      this.canvas.forEachObject((o) => {
        o.selectable = value;
        o.hasControls = value;
        o.hasBorders = value;
        o.transparentCorners = value;
      });
      this.canvas.renderAll();
    }
  }

  private setWBVisibleState(visible: boolean) {
//    if (!this.ses.Master) {
      if (!visible) {
        this.WBRemoteMoveMode = false;
        this.WBRemotePenMode = false;
        this.WBRemoteVisible = false;
        this.setCanvasEditMode(false);
      }
//    }
  }


  SendRemoteCmd(act: string, param: any) {
    const cmd = '[' + act + ':' + param + ']';
    this.webrtc.SendWBoardCtrlAction(cmd);
  }



  private drawAction(act: string, body: string) {
    const w = this.canvas.getWidth();
    const h = this.canvas.getHeight();
    const z = this.canvas.getZoom();
    const cmd = '[' + act + ':' + w + ':' + h + ':' + z + ']' + body;
    this.webrtc.SendWBoardDrawAction(cmd);
  }


  sendAllObjects() {
    if (this.canvas) {
      this.canvas.forEachObject((fobj) => {
        const json = JSON.stringify(fobj.toJSON(['data']));
        this.drawAction(CANV.OBJ_ADD, json);
      });
    }
  }


  get WBRemoteVisible(): boolean {
    return this.wbRemoteVisible;
  }

  set WBRemoteVisible(state: boolean) {
    this.wbRemoteVisible = state;
    if (this.wbRemoteVisible) {
      this.bnWBRemoteVisibleIcon = faEye;
    } else {
      this.bnWBRemoteVisibleIcon = faEyeSlash;
      this.WBRemotePenMode = false;
      this.WBRemoteMoveMode = false;
    }
    if (this.ses.Master) {
      // force remote to follow
      this.SendRemoteCmd(WBREM_CTRL.VISIBLE, state);
      if (state) {
        this.drawAction(CANV.CLEAR_ALL, '');
        this.drawAction(CANV.REF_SIZE, '');
        this.sendAllObjects();
      }
    }
  }

  get WBRemotePenMode(): boolean {
    return this.wbRemoteDraw;
  }

  set WBRemotePenMode(state: boolean) {
    if (this.wbRemoteVisible) {
      this.wbRemoteDraw = state;
    } else {
      this.wbRemoteDraw = false;
    }
    if (this.wbRemoteDraw) {
      this.WBRemoteMoveMode = false;
    }
    if (this.ses.Master) {
      // force remote to follow
      this.SendRemoteCmd(WBREM_CTRL.DRAW, this.wbRemoteDraw);
    }
  }

  get WBRemoteMoveMode(): boolean {
    return this.wbRemoteMove;
  }

  set WBRemoteMoveMode(state: boolean) {
    if (this.wbRemoteVisible) {
      this.wbRemoteMove = state;
    } else {
      this.wbRemoteMove = false;
    }
    if (this.wbRemoteMove) {
      this.WBRemotePenMode = false;
    }
    if (this.ses.Master) {
      // force remote to follow
      this.SendRemoteCmd(WBREM_CTRL.MOVE, this.wbRemoteMove);
    }
  }


  get PenModeState(): boolean {
    return this.penModeState;
  }

  set PenModeState(value: boolean) {
    if (value) {
      this.MoveModeState = false;
    }
    if (this.canvas) {
      this.canvas.isDrawingMode = value;
    }
    this.divWBPenToolDisplay = value ? 'block' : 'none';
    this.penModeState = value;
  }

  get MoveModeState(): boolean {
    return this.moveModeState;
  }

  set MoveModeState(value: boolean) {
    if (value) {
      this.PenModeState = false;
    }
    this.moveModeState = value;
    if (this.canvas) {
      this.setCanvasEditMode(value);
    }

  }

  public get penWidth(): number {
    const w = this.canvas.freeDrawingBrush.width;
    return w;
  }

  public set penWidth(width: number) {
    this.canvas.freeDrawingBrush.width = width;
  }

  public setPenColor(col: any) {
//    this.canvas.freeDrawingBrush.color = col.toRgbString();
  }

  public bnPenSizeClick() {
  }

  public bnClearClick() {
    //    this.canvas.clear();
    this.clrAllObjects();
  }

  public bnSaveClick() {
    const w = this.canvas.getWidth();
    const h = this.canvas.getHeight();
    const svgStr = this.canvas.toSVG({
      viewBox: {
        x: 0,
        y: 0,
        width: w,
        height: h
      }
    });
    this.saveFile('mcs-canvas-.svg', svgStr);
  }


  public bnSaveJSONClick() {
    const w = this.canvas.getWidth();
    const h = this.canvas.getHeight();
    const svgStr = JSON.stringify(this.canvas);
    this.saveFile('mcs-canvas-.json', svgStr);
  }

  public bnLoadClick() {
    const cnv = this.canvas;
    this.loadFile().then((f: File) => {
      const reader = new FileReader();
      // load the file
      reader.onload = (event) => {
        const svgStr = event.target.result as string;
        fabric.loadSVGFromString(svgStr, (objects: any, options: any) => {
          // Group elements to fabric.PathGroup (more than 1 elements) or to fabric.Path
          const loadedObject = fabric.util.groupSVGElements(objects, options);
          // Set sourcePath
          // loadedObject.set('sourcePath', elem.getAttribute('data-url'));
          cnv.add(loadedObject);
          //          loadedObject.center().setCoords();
          cnv.renderAll();
        });
      };
      reader.readAsText(f);
    }, (ex: any) => {
      alert('File load failed');
    });
  }

  private saveFile(filename: string, contents: string) {
    filename = filename || 'Untitled.txt';
    const opts = { type: 'text/plain' };
    const file = new File([contents], '', opts);
    const svgFile = document.getElementById('svgFile');
    svgFile.setAttribute('href', window.URL.createObjectURL(file));
    svgFile.setAttribute('download', filename);
    svgFile.click();
  }



  private loadFile(): Promise<File> {
    const promise = new Promise<File>((resolve, reject) => {
      const filePicker = document.getElementById('filePicker') as HTMLInputElement;
      filePicker.onchange = (e) => {
        const afile = filePicker.files[0];
        if (afile) {
          resolve(afile);
        }
        reject(null);
      };
      filePicker.click();
    });
    return promise;
  }

  // private checkDirty(){
  //   this.ses.gDrawIsDirty = (this.canvas.getObjects().length > 0) ? true : false;
  // }


  private delObjects() {
    const selected = this.canvas.getActiveObjects();
    if (selected) {
      this.canvas.remove(...selected);
      this.canvas.discardActiveObject().renderAll();
    }
    // this.checkDirty();
  }


  private loadSVG() {
    fabric.loadSVGFromURL('/assets/arm.svg', (objects) => {
      const group = new fabric.Group(objects);
      this.canvas.add(group);
      this.canvas.renderAll();
    });
  }


  private placeCircle(rad: number, x: number, y: number) {
    const circ = new fabric.Circle({
      fill: 'red',
      radius: rad,
    });
    circ.left = x - rad;
    circ.top = y - rad;
    this.canvas.add(circ);
    circ.setCoords();
    this.canvas.renderAll();
  }


  private placeSquare(w: number, h: number, x: number, y: number) {
    const sq = new fabric.Rect({
      fill: 'white',
      width: w,
      height: h
    });
    sq.left = x - (w / 2);
    sq.top = y - (h / 2);
    this.canvas.add(sq);
    sq.setCoords();
    this.canvas.renderAll();
  }

  private onKeyDown(evt: any) {
    if (evt.key === 'Delete') {
      this.delObjects();
    }


    if (evt.key === '*') {
      const zoom = this.canvas.getZoom();
      const w = this.canvas.getWidth() / zoom;
      const h = this.canvas.getHeight() / zoom;
      console.log('CIRC W=' + w + ' H=' + h + ' Zoom=' + zoom);
      this.placeCircle(10, w / 2, h / 2);
    }


    if (evt.key === '=') {
      this.loadSVG();
    }



    if (evt.key === '+') {

      const zoom = this.canvas.getZoom();
      const w = this.canvas.getWidth() / zoom;
      const h = this.canvas.getHeight() / zoom;

      console.log('SQ W=' + w + ' H=' + h + ' Zoom=' + zoom);


      this.placeSquare(20, 20, w / 2, h / 2);
      this.placeSquare(20, 20, 10, 10);
      this.placeSquare(20, 20, w - 10, 10);
      this.placeSquare(20, 20, 10, h - 10);
      this.placeSquare(20, 20, w - 10, h - 10);
    }
  }

  private onContainerMouseDown(evt: MouseEvent) {
    if (evt.button === 2) {
      console.log('context menu at ' + evt.pageX + ':' + evt.pageY);
    }

  }

}

