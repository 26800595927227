import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DxTreeViewComponent, DxContextMenuComponent } from 'devextreme-angular';
import { FrameClassListComponent } from '../classes/frame-class-list.component';

export class MenuItem {
  id: string;
  text: string;
}


@Component({
  selector: 'app-frame-browser',
  templateUrl: './frame-browser.component.html'
})

export class FrameBrowserComponent implements OnInit {

  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;
  @ViewChild(DxContextMenuComponent, { static: false }) contextMenu: DxContextMenuComponent;
  @Input() frameScroller: FrameClassListComponent;




  selectedTreeItem: any;
  logItems: string[];
  menuItems: MenuItem[] = [
    { id: 'expand', text: 'Expand category' },
    { id: 'collapse', text: 'Collapse category' },
    { id: 'details', text: 'Show product details' },
    { id: 'copy', text: 'Copy product info' }
  ];

  products = [
    { id: '0', text: 'Catalog Library' },
    { id: '1', text: 'Fruits', parentId: '0'  },
    { id: '1_1', text: 'Apples', parentId: '1' },
    { id: '1_1_1', text: 'Granny Smith', parentId: '1_1' },
    { id: '1_2', text: 'Oranges', parentId: '1' },
    { id: '1_2_1', text: 'Big Ones', parentId: '1_2' },
    { id: '2', text: 'Vegetables', parentId: '0' },
    { id: '2_1', text: 'Cucumbers', parentId: '2' },
    { id: '2_2', text: 'Tomatoes', parentId: '2' }
];



  constructor() {
  }

  treeViewItemContextMenu(e) {
    this.selectedTreeItem = e.itemData;

    const isProduct = e.itemData.price !== undefined;
    const contextMenu = this.contextMenu.instance;
    contextMenu.option('items[0].visible', !isProduct);
    contextMenu.option('items[1].visible', !isProduct);
    contextMenu.option('items[2].visible', isProduct);
    contextMenu.option('items[3].visible', isProduct);

    contextMenu.option('items[0].disabled', e.node.expanded);
    contextMenu.option('items[1].disabled', !e.node.expanded);
}


contextMenuItemClick(e) {
  let logEntry = '';
  const treeView = this.treeView.instance;
  switch (e.itemData.id) {
      case 'expand': {
          logEntry = `The '${this.selectedTreeItem.text}' group was expanded`;
          treeView.expandItem(this.selectedTreeItem.id);
          break;
      }
      case 'collapse': {
          logEntry = `The '${this.selectedTreeItem.text}' group was collapsed`;
          treeView.collapseItem(this.selectedTreeItem.id);
          break;
      }
      case 'details': {
          logEntry = `Details about '${this.selectedTreeItem.text}' were displayed`;
          break;
      }
      case 'copy': {
          logEntry = `Information about '${this.selectedTreeItem.text}' was copied`;
          break;
      }
  }
//  this.logItems.push(logEntry);
}

  public createChildren(parent: any): any {
    console.dir(parent);
  }

  public async ngOnInit() {
  }

}
