<style>
  .slide-toolbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  #tb-Row1 {
    margin: 0 auto;
  }


  .btn-icon {
    height: 40px;
    width: 40px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    vertical-align: webkit-baseline-middle;
    color: black;
    background-color: white;
    border-color: black;
  }

  .custom-btns {
    margin-left: 3px;
    height: fit-content;
  }
  .custom-btns.active {
    color: black;
    background-color:#17a2b8;
    border-color: black;
  }


  .custom-btns:not(.active) {
    color: white;
    background-color: #303030;
    border-color:black;
  }

  .custom-btns.focus {
    box-shadow: 0 0 0 0 white;
  }

  .btn {
    background-color:#ffc107;
    color: black;
    border-color: black;
  }

  .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 white;
  }



  /* HIDE RADIO */
  [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type=radio]+img {
    cursor: pointer;
  }

  /* CHECKED STYLES */
  [type=radio]:checked+img {
    outline: 1px solid #f00;
  }

</style>


<div class="slide-toolbar">

  <div id="tb-Row1">
    <div class="btn-group btn-group-toggle">

      <button type="button"
              class="btn btn-outline-dark"
              (click)="bnPrevPageClick()">
        <fa-icon [icon]="bnPrevPageIcon"
                 placement="bottom"
                 ngbTooltip="Prev"></fa-icon>
      </button>

      <button type="button"
              class="btn btn-outline-dark"
              (click)="bnNextPageClick()">
        <fa-icon [icon]="bnNextPageIcon"
                 placement="bottom"
                 ngbTooltip="Next"></fa-icon>
      </button>

      <button type="button"
              class="btn btn-outline-dark"
              (click)="bnLoadFileClick()">
        <fa-icon [icon]="bnLoadFileIcon"
                 placement="bottom"
                 ngbTooltip="Load File"></fa-icon>
      </button>

      <button type="button"
              class="btn btn-outline-dark"
              (click)="bnLoadFolderClick()">
        <fa-icon [icon]="bnLoadFolderIcon"
                 placement="bottom"
                 ngbTooltip="Load Folder"></fa-icon>
      </button>

      <button type="button"
              class="btn btn-outline-dark"
              (click)="bnSaveFileClick()">
        <fa-icon [icon]="bnSaveFileIcon"
                 placement="bottom"
                 ngbTooltip="Download"></fa-icon>
      </button>
    </div>
 </div>



</div>
