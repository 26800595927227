import { zim } from "../zimjs/zimjs.js";
import { zog, zogStyle, zogg, zogp, zogb, zogr, zogy, zogo, zogl, zogd, zta } from "../zimjs/zimjs.js";
import { zid, zss, zgo, zum, zot, zop, zil, zet, zob, zik, zor } from "../zimjs/zimjs.js";
import { ZIMONON } from "../zimjs/zimjs.js";
import { zComponent } from "./zcomponent.js";
import { zMirror, ZMirror } from "./zmirror.js";



function makeZComp(that, name) {
   that.name = name;
   var comp = ZBuilder ? ZBuilder.bindComp(name) : undefined;
   that.component = new zComponent(that, that.arguments, comp);
}

class zFrame extends zim.Frame {
   constructor(name, ...args) {
      super(...args);
      makeZComp(this, name);
      this.component.setFunc("SayHello", "console.log('Frame says Hello');");
   }
}

class zCircle extends zim.Circle {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
}

class zRectangle extends zim.Rectangle {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
}

class zTriangle extends zim.Triangle {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
}

class zLine extends zim.Line {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
}

class zLabel extends zim.Label {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
}

class zBlob extends zim.Blob {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
   transform() {
      return (this);
   }
}

class zSquiggle extends zim.Squiggle {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
   transform() { return (this); }
}

class zText extends zim.TextArea {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
}

class zPolygon extends zim.Poly {
   constructor(name, ...args) { super(...args); makeZComp(this, name); }
}

const zClassImport = "import { zFrame, zRectangle, zCircle, zTriangle, zLine, zBlob, zSquiggle, zLabel, zText, zPolygon } ";
export { zFrame, zRectangle, zCircle, zTriangle, zLine, zBlob, zSquiggle, zLabel, zText, zPolygon };

class zBuilder {

   constructor(mode, debug, width, height, time, imports) {
      this.FrameDiv = mode;
      this.srcCode = '';
      this.debug = debug;
      this.document = null;
      var fSections = zBuilder.frameTemplate(this.FrameDiv, width, height, time, debug, imports);
      this.startCode = fSections[0];
      this.endCode = fSections[1];
      this.components = [];


      this.frame = undefined;
      this.stage = undefined;

      this.PARAMS = {};
      this.PARAMS_IN = '';
      this.PARAMS_EX = '';

      window.ZBuilder = this;

      this.FrameLoaded = undefined;
      this.CompSelected = undefined;
      this.TFManager = undefined;

   }



   static frameTemplate(mode, width, height, time, debug, exlibs) {
      var scaleStr = mode ? 'var scaling = "' + mode + '";\n' : 'var scaling = "zimHolder";\n';
      var widthStr = width ? 'var width =' + width : 'var width = 1024';
      var heightStr = height ? 'var height =' + width : 'var height = 768';
      var colorStr = 'var color = dark; var outerColor = darker;\n';
      var frameStr = scaleStr + widthStr + ';' + heightStr + ';' + colorStr;

      const timestr = time ? 'zim.TIME="' + time + '";' : 'zim.TIME="seconds";';
      const libpath = ' from "./scripts/zimext/zbuilder.js";\n';
      const debugstr = debug ? ' debugger;' : '';
      let importstr = 'import { zim, ZBuilder, zBuilder, zMirror, ZMirror, zComponent}' + libpath +
                       'import { zog, zogStyle, zogg, zogp, zogb, zogr, zogy, zogo, zogl, zogd, zta }' + libpath +
                       'import { zid, zss, zgo, zum, zot, zop, zil, zet, zob, zik, zor }' + libpath +
                      'import { ZIMONON }' + libpath  +
                       zClassImport + libpath;
      if (exlibs) {
         if (exlibs[0] == '+') {
            importstr = importstr  + exlibs.slice(1);
         } else {
            importstr = exlibs;
         }
      }

      // 'var frame = new zFrame("FRAME",scaling, width, height, color, outerColor);\n' +
      // 'var $ = ZBuilder.PARAMS;\n' +
      // '\n////  FRAME START ////\n' +
      // 'frame.on("ready", function() {\n' +
      // ' var stage = this.stage;\n\n';
   const header = [debugstr, importstr, timestr, '//// START-SCRIPT ////'].join('\n');
      const frameBegin = frameStr +
         'var frame = new zim.Frame(scaling, width, height, color, outerColor);\n' +
         '\n////  FRAME START ////\n' +
         'frame.on("ready", function() {\n' +
         ' var stage = this.stage;\n\n';

      const frameEnd = ' stage.update();\n' + 
      ' });\n';
      // ' ZBuilder.onFrameLoaded(frame);\n});\n';

      const srcCode = [];
      srcCode.push([header, frameBegin].join('\n'));
      srcCode.push(frameEnd);
      return srcCode;
   }





   ShowFrame(document, code) {
      if (this.frame) this.frame.dispose();

      const oldScript = document.getElementById('zScript');
      if (oldScript) {
         document.getElementsByTagName('head')[0].removeChild(oldScript);
      }
      const scriptElem = document.createElement('script');
      scriptElem.type = 'module';
      scriptElem.id = 'zScript';
      scriptElem.text = this.Compile(code);
//      console.log(scriptElem.text);
      this.PARAMS_IN = JSON.stringify(this.PARAMS);
      document.getElementsByTagName('head')[0].appendChild(scriptElem);
      this.document = document;
   }





   onTransformHide() {
      if (this.CompSelected) this.CompSelected(null);
   }


   onTransformShow(evt) {
      var obj = evt.transformObject;
      if (obj.transformControls) {
         obj.transformControls.resize();
         if (this.CompSelected) this.CompSelected(obj);
      }
   }


   onFrameKeyDown(evt) {
      //      console.dir(evt);
      if (evt.key == "Delete") {
         if (this.TFManager) {
            //            var zcomp = this.TFManager.currentObject;
            //            if (zcomp) this.deleteComponent(zcomp);
         }
      }
   }

   onFrameLoaded(frame) {
      this.components.length = 0;
      this.frame = frame;
      this.frame.component.readProps();
      this.frame.on("keydown", this.onFrameKeyDown, this);
      this.stage = frame.stage;
      if (this.TFManager) {
         this.TFManager.dispose();
      }
      var zcomps = this.stage.children.filter(cmp => cmp.component !== undefined);
      zcomps.forEach(cmp => {
         cmp.component.readProps();
         this.components.push(cmp.component);
      });
      this.TFManager = new zim.TransformManager(zcomps);
      this.TFManager.on("transformshow", this.onTransformShow, this);
      this.TFManager.on("transformhide", this.onTransformHide, this);
      this.TFManager.resize();
      this.TFManager.hideAll();
      this.PARAMS_EX = JSON.stringify(this.PARAMS);
      if (this.FrameLoaded) this.FrameLoaded(this.frame);
   }

   Compile(code) {
      var src = this.startCode;
      if (code) {
         src = src + code;
      } else {
         // compile all components 
         this.components.forEach(comp => {
            src = src + '/// ' + comp._name + ':' + comp._class + '///\n';
            src = src + zBuilder.jsBuild(comp) + '\n';
         });
      }
      src = src + this.endCode;
      this.srcCode = src;
      if (this.debug) console.log(src);
      return this.srcCode;
   }

   bindComp(hostName) {
      var comp;
      var indx = this.components.findIndex(comp => comp._name == hostName);
      if (indx > -1) {
         comp = this.components[indx];
      }
      return comp;
   }

   LoadFrame(json, params) {
      this.components.length = 0;
      var jsonAR = JSON.parse(json);
      jsonAR.forEach(js => {
         var comp = JSON.parse(js);
         this.components.push(comp);
      });
      this.PARAMS = params ? params : this.PARAMS;
   }

   SaveFrame(read) {
      var comps = [];
      this.components.forEach(comp => {
         if (read) comp.readProps();
         // host.name could have been changed in design mode
         comp._name = comp.host.name;
         comps.push(comp);
      });
      return JSON.stringify(comps);
   }

   Refresh() {
      var json = this.SaveFrame(true);
      this.LoadFrame(json);
      if (this.document) {
         this.ShowFrame(this.document);
      } else {
         alert('ZBuilder.Refresh => No document to ShowFrame!');
      }
   }

   ClearComponents() {
      this.components.length = 0;
      this.TFManager.dispose();
      this.TFManager = null;
   }

   makeName(name) {
      var prefix = name;
      var pos = name.indexOf('#');
      var num = 0;
      if (pos > -1) {
         prefix = name.slice(0, pos);
         var siblings = this.components.filter(cmp => cmp._name.startsWith(prefix));
         num = siblings.length + 1;
         while (siblings.find(cmp => cmp._name == (prefix + num))) {
            num = num + 1;
         }
         return prefix + num;
      } else {
         var numstr = '';
         while (this.components.find(cmp => cmp._name == (prefix + numstr))) {
            num = num + 1;
            numstr = num.toString();
         }
         return numstr;
      }
   }


   CreateComp(name, ctype, ...args) {
      name = this.makeName(name);
      var zClass = eval(ctype);
      var zComp = new zClass(name, ...args);
      if (this.frame) {
         zComp.center(this.stage);
         zComp.transform();
         zComp.drag();
         this.stage.update();
         this.TFManager.add(zComp);
      }
      zComp.component.setFunc("#", "addTo(stage).transform().drag()");
      this.components.push(zComp.component);
      return zComp;
   }



   DeleteComponent(zcomp) {
      if (this.frame.stage) {
         var indx = this.components.indexOf(zcomp.component);
         this.components.splice(indx, 1);
         zcomp.removeFrom();
         this.TFManager.hideAll();
      }
   }


   static jsCreate(comp) {
      var name = comp._name;
      var cname = comp._class;
      var icode = 'var ' + name + '= new ' + cname + '("' + name + '")';
      if (comp.funcs['.']) {
         var func = comp.funcs['.'];
         if (func[0] == '(') {
            func =',' + func.slice(1);
            icode = icode.slice(0, icode.length - 1); // remove closing bracket to allow arguments to be added
            icode = icode + func; // add arguements and constructor trail functions
         } else {
            icode = icode + '.' + func; // add constructor trail functions
         }
      }
      icode = icode + ';\n';
      return icode;
   }

   static jsProps(comp) {
      var scode = '';
      for (const key in comp.props) {
         var val = comp.props[key];
         if (val) {
            if (isNaN(val)) {
               scode = scode + comp._name + '["' + key + '"]="' + val + '"; ';
            } else {
               scode = scode + comp._name + '["' + key + '"]=' + val + '; ';
            }
         }
      }
      scode = scode + '\n';
      return scode;
   }


   static jsBuild(comp) {

      function replaceSymbol(text, symb) {
         // scan for symb occurances and replace with host.name ; escape '_symb' to 'symb'
         var ccode = text;
         var sects = ccode.split(symb);
         if (sects.length > 1) {
            ccode = '';
            for (var i = 0; i < sects.length - 1; i++) {
               var sec = sects[i];
               if (sec[sec.length - 1] == '_') {
                  sec = sec.slice(0, sec.length - 1) + symb;
               } else {
                  sec = sec + comp._name;
               }
               ccode = ccode + sec;
            }
            ccode = ccode + sects[sects.length - 1];
         }
         return ccode;
      }


      var srcCode = zBuilder.jsCreate(comp);
      for (const func in comp.funcs) {
         if (func.startsWith('on.')) {
            var evt = func.slice(3);
            var ecode = replaceSymbol(comp.funcs[func],'#');
            srcCode = srcCode + comp._name + '.on("' + evt + '",' + ecode + ');\n';
         } else {
            var startChar = func[0];
            switch (startChar) {
               case '.': break;
               case '#':
                  var ccode = replaceSymbol(comp.funcs[func],'#');
                  srcCode = srcCode + ccode + ';\n';
                  break;
               default:
                  var dcode = replaceSymbol(comp.funcs[func],'#');
                  srcCode = srcCode + 'function ' + func + '{\n ' + dcode + '\n}\n';
                  break;
            }
         }
      }
      return srcCode;
   }

}

let ZBuilder = window.ZBuilder;

// zBuilder export
export { zBuilder, ZBuilder };

// zMirror export
export { zMirror, ZMirror };

// zComponent export
export { zComponent };

/// zimjs exports
export { zim };
export { zog, zogStyle, zogg, zogp, zogb, zogr, zogy, zogo, zogl, zogd, zta };
export { zid, zss, zgo, zum, zot, zop, zil, zet, zob, zik, zor };
export { ZIMONON };



