<style>
  .toolbar-item {
    border-radius: 0px 0px 5px 5px;
    padding-left: 1px;
    padding-right: 1px;
    margin: 1px;
    width: auto;
    font-size: 12px;
  }

  .cmd-btn-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  ::ng-deep .dx-dropdownbutton-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    padding: 3px;
  }

  ::ng-deep .dx-menu-item {
    background-color: white;
    border-radius:4px;
    border: 1px solid #ddd;
  }

  ::ng-deep .dx-menu .dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text {
  padding: 0 19px 3px 3px;
  }

  ::ng-deep .dx-menu-base {
  font-size: 11px;
  line-height: 1.25;
}

  ::ng-deep .dx-texteditor-input {
    padding: 3px;
    min-height: 24px;
  }

  ::ng-deep .dx-button .dx-button-content {
    padding: 3px;
  }

  /* ::ng-deep .dx-button .dx-icon {
    font-size: 30px;
  } */

  .item-label {
    font-size: 10px;
    height: 16px;
  }
</style>


<div class="toolbar-item" [style.background-color]="color" *ngIf=!hidden>

  <div *ngIf="type === 'BOOL'">
  </div>

  <div [style.width]="item.width" *ngIf="type === 'CMDBUTTONS'">
    <div class='cmd-btn-panel'>
      <div *ngFor="let btn of item.items">
        <dx-button [icon]="btn.icon"
                   stylingMode="contained"
                   [disabled]= "btn.disabled"
                   [text]="btn.value"
                   (onClick)="btn.callBack(btn.id, $event, btn.index )">
        </dx-button>
      </div>
    </div>
    <div class='item-label'>
    <table [style.width]="item.width" style="table-layout:fixed;">
        <tr>
          <td style="overflow:hidden;"  *ngFor="let btn of item.items">
            {{btn.name}}
          </td>
        </tr>
      </table>
    </div>
  </div>


  <div [style.width]="item.width" *ngIf="type === 'BUTTON'">
    <dx-button [width]="item.width"
               stylingMode="contained"
               [icon]="item.icon"
               [text]="item.value"
               (onClick)="item.callBack(id,  $event, item.index)">
    </dx-button>
    <div class='item-label'> {{item.name}} </div>
  </div>

  <div *ngIf="type === 'BTNGROUP'">
    <dx-button-group [width]="item.width"
                     [items]="item.items"
                     stylingMode="contained"
                     keyExpr="index"
                     [selectionMode]="item.value"
                     (onSelectionChanged)="item.callBack(id, $event, item.index )">
    </dx-button-group>
    <div class='item-label'>
      <table [style.width]="item.width" style="table-layout:fixed;">
          <tr>
            <td style="overflow:hidden;"  *ngFor="let btn of item.items">
              {{btn.name}}
            </td>
          </tr>
        </table>
      </div>
    </div>

  <div *ngIf="type === 'NUMBER'">
    <dx-number-box [width]="item.width"
                   [(value)]="item.value"
                   [min]="item.min"
                   [step]="item.step"
                   [max]="item.max"
                   stylingMode="contained"
                   [showSpinButtons]="true"
                   [showClearButton]="false"
                   (onValueChanged)="item.callBack(id, $event.value, item.index )">
    </dx-number-box>
    <div class='item-label'> {{item.name}} </div>
  </div>

  <div *ngIf="type === 'TEXT'">
    <dx-text-box [width]="item.width"
                 [(value)]="item.value"
                 stylingMode="contained"
                 valueChangeEvent="change"
                 (onValueChanged)="item.callBack(id, $event.value, item.index)">
    </dx-text-box>
    <div class='item-label'> {{item.name}} </div>
  </div>

  <div *ngIf="type === 'SELECT'">
    <dx-drop-down-button [useSelectMode]="true"
                         [dataSource]="item.items"
                         displayExpr="name"
                         keyExpr="index"
                         stylingMode="contained"
                         [splitButton]="true"
                         [width]="item.width"
                         [selectedItemKey]="item.selIndex"
                         (onItemClick)="item.callBack(id,$event.itemData.value, $event.itemData.index, false )"
                         (onButtonClick)="item.callBack(id, $event.selectedItem.value, $event.selectedItem.index, true )">
    </dx-drop-down-button>
    <div class='item-label'> {{item.name}} </div>
  </div>

  <!-- (onSelectionChanged)="item.callBack(id,$event.item.value,$event.item.index, false)" -->

  <!-- [cpPosition]                 // Dialog position: 'auto', 'top', 'bottom', 'left', 'right',
  //  'top-left', 'top-right', 'bottom-left', 'bottom-right' ('auto').
[cpPositionOffset]           // Dialog offset percentage relative to the directive element (0%).
[cpPositionRelativeToArrow]  // Dialog position is calculated relative to dialog arrow (false). -->

  <div *ngIf="type === 'COLOR'">
    <div style="padding:1px; padding-top:2px">
      <input [style.background]="item.value"
             [style.width]="item.width"
             [style.cursor]="'default'"
             [(colorPicker)]="item.value"
             [cpSaveClickOutside]="false"

             [cpWidth] = "'230px'"
             [cpHeight] = "'auto'"
             [cpPosition]="item.position"
             [cpPositionRelativeToArrow]="false"
             [cpPositionOffset]="item.offsetX"

             [cpOKButton]="true"
             [cpCancelButton]="true"
             [cpPresetColors]="presetColors"
             (colorPickerChange)="item.callBack(id, $event, item.index )" />
    </div>
    <div class='item-label'> {{item.name}} </div>
  </div>

  <div *ngIf="type === 'MENU'">
    <dx-menu [dataSource]="item.items"
             displayExpr="name"
             stylingMode="contained"
             [showFirstSubmenuMode]="submenuMode"
             orientation="horizontal"
             submenuDirection="rightOrBottom"
             [hideSubmenuOnMouseLeave]="true"
             (onItemClick)="item.callBack(id,  $event.itemData, $event.itemData.index)">
    </dx-menu>
    <div class='item-label' style="height: 15px;"> . . . </div>
  </div>
</div>
