import { Component, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { FrameClass } from '../frame-store.service';
import { FEToolbarComponent } from './frame-editor-toolbar.component';
import { fabric } from 'fabric';
import { FramePropsComponent } from '../props/frame-props.component';
import { zMirror, zComponent, zFrame, zBuilder, ZBuilder} from '../../../../scripts/zimext/zbuilder';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ResizedEvent } from 'angular-resize-event';
import { FrameCoderComponent } from '../coder/frame-coder.component';
import { FrameScriptComponent } from '../script/frame-script.component';
import { HttpClient, HttpHandler } from '@angular/common/http';

// import 'ace-builds/src-noconflict/mode-javascript';
// import 'ace-builds/src-noconflict/theme-github';
// import 'ace-builds/src-noconflict/ext-language_tools';

@Component({
  selector: 'app-frame-editor',
  templateUrl: './frame-editor.component.html'
})

export class FrameEditorComponent implements AfterViewInit {

  @ViewChild(FEToolbarComponent) toolBarComp;
  @Input() frameProps: FramePropsComponent;
  @Input() frameCoder: FrameCoderComponent;
  @Input() frameScript: FrameScriptComponent;

  width: number = window.innerWidth;
  height: number = window.innerHeight;

  initDone = false;

  params = {};
  comps: zComponent[];
  json = '';

  zimFrame: zFrame;
  zimMirror: zMirror;
  zimBuilder: zBuilder;


  frameEditorComp: FrameEditorComponent;
  frameClass: FrameClass;

  public isDrawerOpen = true;


  xbootFrame = `
    var circ1 = new zCircle("circ1", 35, 'red').center(stage).drag().transform();
    circ1.component.setFunc(".", "(50,'red').addTo(stage).transform().drag()");
    circ1.component.delProps("color");
    circ1.component.setFunc("#", "#.mov(-10,0); #.color=$.color1;console.log('aha _#')");
    circ1.component.setFunc("BeepCirc()", "console.log('Beep Circle');");
    circ1.component.setEvent("mousedown", "BeepCirc");


    var rect1 = new zRectangle("rect1", 30, 30, 'blue').center(stage).mov(-100,0).drag().transform();
    rect1.component.setFunc(".", "addTo(stage).transform().drag().rot(45)");
    rect1.component.setFunc("#", "#.mov(10,0);#.wire(circ1,'x',true)");
    rect1.component.setFunc("BeepRect()", "console.log('Beep Rectangle');");
    rect1.component.setFunc("on.mousedown", "BeepRect");

    rect1.mov(0,100);
    rect1.wire(circ1,"x",true);

    var circ2 = new zCircle("circ2", 25, 'green').center(stage).mov(0,-100).drag().transform();
    circ2.component.setFunc(".", "addTo(stage).transform().drag()");
    circ2.component.setFunc("#", "$.scaleX=#.scaleX; $.scaleY=#.scaleY");

    var loader = frame.loadAssets("chicken.jpg","assets/");
    loader.on("error", function () {
      zog("error load");
      debugger;
    });

    loader.on("complete", function() {
      debugger;
      zog("complete load");
	  	var chicken = frame.asset("chicken.jpg");
	  	chicken.center();
	  	stage.update();
	  });
    `;

    bootFrame = `
    var progressBar = new ProgressBar({barType:"rectangle"});
    var loader = frame.loadAssets({assets:"chicken.png", path:"assets/", progress:progressBar});

    console.dir(frame.preload);
    progressBar.center(stage);
    loader.on("error", function () {
      zog("error load");
      debugger;
    });

    loader.on("progress", function (p) {
      zog("progress=" + p);
    });

    loader.on("complete", function() {
      debugger;
      zog("complete load");
	  	var chicken = frame.asset("chicken.png");
	  	chicken.center(stage);
	  	stage.update();
    });


    var button = new Button(200,60,"CLICK");
    button.center(stage).mov(0,-200);
    button.on("click", function(){
      console.dir(frame.preload);
      var chicken = frame.asset("chicken.png");
      chicken.center(stage);
      chicken.setBounds(0,0,100,100);
	  	stage.update();
      zog("clicking");
    });

//    var circ = new zim.Circle(35, 'red').center(stage).drag();

    `;



  constructor(@Inject(DOCUMENT) private document: Document, public cdr: ChangeDetectorRef) {
  }


  zimFrameLoaded(frame: zFrame) {
    this.zimFrame = frame;
    this.initDone = true;
    this.frameScript.UpdateBuilder(this.zimBuilder);
  }


  public resize() {
    console.log('Resize - Editor');
    //    this.toolBarComp.resize();
  }

  beepHere(msg) {
    console.log('Script called beep with ' + msg);
  }


  ngAfterViewInit(): void {
    this.params = {
      color1: 'red',
      radius1: 0,
      radius2: 0,
      beepFunc: this.beepHere.bind(this),
    };
    //    this.zimMirror = new zMirror(true);
    this.zimBuilder = new zBuilder('zimCanvas', true, 500, 500);
    this.zimBuilder.FrameLoaded = this.zimFrameLoaded.bind(this);
    this.zimBuilder.CompSelected = this.zimCompSelected.bind(this);
    this.zimBuilder.PARAMS = this.params;
    this.zimBuilder.ShowFrame(this.document, this.bootFrame);
    this.frameEditorComp = this;
//    const data = this.http.get('https://cast-ink.com/index.html');
}


  zimCompSelected(comp: any){
    this.frameProps.Select(comp ? comp.component : null);
    this.toolBarComp.Select(comp ? comp.component : null);
    this.frameCoder.Select(comp ? comp.component : null);
  }

  doResize(event) {
  }


  public UnLoad() {
    //    this.toolBarComp.Disable();
    // if (this.canvas) {
    //   this.canvas.dispose();
    //   this.canvas = null;
    // }
  }


  public async Load(frame: FrameClass) {
    this.UnLoad();
    //    this.frame = frame;

    //    this.canvas = await this.frame.LoadCanvas(this.domCanvas);
    // this.canvas.on('object:added', (evt) => this.objectAdded(evt.target));
    // this.canvas.on('mouse:down', (evt) => this.onMouseDown(evt));
    // this.canvas.on('mouse:up', (evt) => this.onMouseUp(evt));
    // this.canvas.on('mouse:move', (evt) => this.onMouseMove(evt));

    const divContainer = document.getElementById('frame_editor_div') as HTMLDivElement;
    const width = divContainer.clientWidth;
    const height = divContainer.clientHeight;

    // const drawCanvasDiv = document.getElementById('frameCanvas') as HTMLCanvasElement;
    // this.drawCanvContainer = drawCanvasDiv.parentElement as HTMLDivElement;
    // this.drawCanvContainer.onkeydown = this.onKeyDown.bind(this);
    // this.drawCanvContainer.onkeyup = this.onKeyUp.bind(this);
    // this.drawCanvContainer.onwheel = this.onWheel.bind(this);
    // this.drawCanvContainer.tabIndex = 1000;


    // this.canvas.perPixelTargetFind = true;
    // this.canvas.preserveObjectStacking = true;


    // this.canvas.renderAll();
    this.toolBarComp.Enable(this.zimBuilder, this.document, this.params);
    //  this.frameProps.Load(this.frame);
  }


  public async Save() {
    //    await this.frame.Save();
  }

  public async Render() {
    //    this.canvas = await this.frame.Render(this.toolBarComp.zoom, this.domCanvas);
  }

  private objectAdded(fobj: fabric.Object) {
    this.toolBarComp.objectAdded(fobj);
  }


  public onResized(ev: any) {
    if (this.toolBarComp) {
      this.toolBarComp.onResized(ev);
    }
    this.doResize(ev);
  }

  private onWheel(evt: any) {
    this.toolBarComp.onWheel(evt);
  }

  private onKeyUp(evt: any) {
    if (evt.key === 'Alt') {
      this.isDrawerOpen = !this.isDrawerOpen;
      console.log(this.isDrawerOpen);
      this.cdr.detectChanges();
    }
    this.toolBarComp.onKeyUp(evt);
  }

  private onKeyDown(evt: any) {
    this.toolBarComp.onKeyDown(evt);
  }

  private onMouseMove(evt: any) {
    this.toolBarComp.onMouseMove(evt);
  }

  private onMouseDown(evt: any) {
    this.toolBarComp.onMouseDown(evt);
  }

  private onMouseUp(evt: any) {
    this.toolBarComp.onMouseUp(evt);
  }









}

