import { zim } from "../zimjs/zimjs.js";

// func prefixes:  "."  gets tagged onto the create instance "center().rot(45)" =>  "new Zomponent().center().rot(45)" :: if first char of body = ',' then make provision for constructor params 
//                 "#", get inserted literal => "console.log('aha!'); alert('hello');";  any '#' inside code is subsititued with comp.name; '_#' become literal '#' (escaped) 
//                 "on.xxx", creates an event xxx :  any '#' inside code is subsititued with comp.name; '_#' become literal '#' (escaped) 

const zcode = { ztype: 'zcode', func: null, vtype: 'zstr' };
const zany = { ztype: 'zany', value: null, default: {}, nullable: true };
const zbool = { ztype: 'zbool', value: null, default: false, nullable: true };
const zrange = { ztype: 'zrange', value: null, default: '', nullable: true, max: 100, min: 0, step: 1 };
const zstr = { ztype: 'zstr', value: null, default: '', nullable: true };
const zlist = { ztype: 'zlist', value: null, default: 0, nullable: true, items: [] };
const zpick = { ztype: 'zpick', value: null, default: 0, nullable: true, items: [], values: [] };
const zint = { ztype: 'zint', value: null, default: 0, step: 1.0, min: null, max: null, nullable: true };
const zfloat = { ztype: 'zfloat', value: null, default: 0, nullable: true };
const zfrac = { ztype: 'zfrac', value: null, default: 0, nullable: true, min: 1.0, max: 0 };
const zcoord = { ztype: 'zcoord', value: null, default: 0.0, nullable: true, step: 0, min: -5000, max: 5000 };
const zcolrgb = { ztype: 'zcolrgb', value: null, default: [0, 0, 0], nullable: true, min: [0, 0, 0], max: [255, 255, 255] };
const zcolzim = { ztype: 'zcolzim', value: 0, default: 0, nullable: false, items: ['black', 'white', 'red', 'green', 'blue', 'pink', 'purple', 'yellow'] };
const zdate = { ztype: 'zdate', value: null, default: '1/1/2020', nullable: true };

function zpa(obj1, obj2) {
   return Object.assign({}, obj1, obj2);
}


// #label = property editor - dynamic binding with optional label; .fname = toolbar static binding to field-name 

const blendModes = ['source-atop', 'source-in', 'source-out', 'source-over', 'destination-atop', 'destination-in', 'destination-out', 'destination-over',
   'lighter', 'lighten', 'darker', 'darken', 'xor', 'copy', 'multiply', 'screen', 'overlay', 'color-dodge', 'color-burn', 'hard-light',
   'soft-light', 'difference', 'exclusion', 'hue', 'saturation', 'color', 'luminosity'];

// var aProps = "name,scaleX,scaleY,widthOnly,heightOnly,color,borderColor,borderWidth,corner,dashed,strokeObj,style,group,pattern";
// var iProps = "alpha,id,x,y,skewX,skewY,regX,regY,rotation,shadow,visible,mouseEnabled,level,depth,draggable";
// var props = "name,scaling,width,height,color,outerColor,assets,path,progress,rollover,touch,scrollTop,align,valign," +
// "canvasID,rollPerSecond,delay,canvasCheck, gpu, gpuObj, nextFrame, nextStage, allowDefault, loadFailObj," +
// "sensors, retina, mouseMoveOutside, captureMouse, shim";


const zpd = Object({
   $name: zpa(zstr, { view: '#' }),
   $scaleX: zpa(zcoord, { view: '#' }),
   $scaleY: zpa(zcoord, { view: '#' }),
   $widthOnly: zpa(zcoord, { view: '#width', step: 1 }),
   $heightOnly: zpa(zcoord, { view: '#height', step: 1 }),
   $color: zpa(zcolrgb, { view: '.fillFGColor' }),
   $borderColor: zpa(zcolrgb, { view: '.penColor' }),
   $borderWidth: zpa(zint, { view: '.penWidth' }),
   $corner: zpa(zint, { view: '#' }),
   $dashed: zpa(zlist, { view: '.penPattern', items: ['Solid', 'Dot', 'Dash', 'DotDash'], values: [false, [3, 3], [10, 3], [10, 3, 3, 10]] }),
   $shadowBlur: zpa(zrange, { view: '#shdw blur', max: 14.0, min: 0, step: 0.1 }),
   $textAlign: zpa(zlist, { view: '.textAlign', items: ['left', 'right', 'center', 'justify'] }),
   $textValign: zpa(zlist, { view: '.textValign', items: ['top', 'center', 'bottom'] }),
   $blendMode: zpa(zlist, { view: '#', items: blendModes }),
   // $style: zpa(zstr, { view: '#' }),
   // $group: zpa(zstr),
   $alpha: zpa(zrange, { view: '#', max: 1.0, min: 0, step: 0.01 }),
   $pointSize: zpa(zrange, { view: '#point size', max: 1.0, min: 0, step: 0.1 }),
   $sides: zpa(zint, { view: '#', min: 3, max: 50 }),
   $x: zpa(zcoord, { view: '#', step: 0.1 }),
   $y: zpa(zcoord, { view: '#', step: 0.1 }),
   $skewX: zpa(zcoord, { view: '#' }),
   $skewY: zpa(zcoord, { view: '#' }),
   $regX: zpa(zcoord, { view: '#' }),
   $regY: zpa(zcoord, { view: '#' }),
   $rotation: zpa(zint, { view: '.rotation', min: 0, max: 360 }),
   $visible: zpa(zbool),
   $mouseEnabled: zpa(zbool),
   $depth: zpa(zint),
   $width: zpa(zcoord, { view: '#', step: 1, min: 1, max: 5000 }),
   $thickness: zpa(zcoord, { view: '#', step: 0.1, min: 0.1, max: 5000 }),
   $radius: zpa(zcoord, { view: '#', step: 1, min: 1, max: 5000 }),
   $height: zpa(zcoord, { view: '#', step: 1, min: 1, max: 5000 }),
   $scaling: zpa(zfloat, { view: '#', step: 0.1, min: 0.1, max: 10 }),
   $rollPerSecond: zpa(zint, { view: '#roll/sec' }),
   $delay: zpa(zint, { view: '#' }),
   $outerColor: zpa(zcolrgb, { view: '.fillBGColor' }),
   $shadowColor: zpa(zcolrgb, { view: '.fillBGColor' }),
   $pattern: zpa(zcode, { view: '#', root: 'dashed', func: '_patternStr' }),
   $fixed: zpa(zcode, { view: '#', root: 'draggable', func: '_fixedBool', vtype: 'zbool' }),
});



class zComponent {


   constructor(host, args, comp) {
      this.host = host;
      this.args = args;
      this._name = host.name;
      this.onPropChange = null;
      this._class = host.constructor.name;
      this.props = {};

      for (const [k, value] of Object.entries(zpd)) {
         var key = value.root ? value.root : k.slice(1);
         var pname = k.slice(1);
         if (key in host) {
            this.props[k] = zpa(value);
            if (this.props[k].ztype == 'zcode') {
               // route access through the appropriate getter and setter
               zComponent.makeProxy(this, pname, this.props[k].func);
            } else {
               // route access directly to the host property
               this.props[key] = this.host[key] ? this.host[key] : undefined;
            }
         }
      }

      this.funcs = {};
      if (comp) {
         this.props = comp.props;
         this.funcs = comp.funcs;
         for (const key in this.props) {
            if (key in this.host) {
               this.host[key] = this.props[key];
            }
         }
      }
   }


   static makeProxy(obj, prop, func) {
      Object.defineProperty(obj.props, prop, {
         get: function () {
            return obj[func];
         },
         set: function (val) {
            obj[func] = val;
         },
      });
   }

   get _fixedBool() {
      if ('draggable' in this.host) {
         var val = !this.host.draggable;
//         console.log('fixed:' + val);
         return val;
      }
      return true;
   }

   set _fixedBool(value) {
      if ('draggable' in this.host) {
         this.host.draggable = !value;
//         console.log('fixed:' + this.host.draggable + ':' + value);
      }
   }

   get _patternStr() {
      if (this.host.dashed) {
         var str = JSON.stringify(this.host.dashed);
         return str;
      }
      return 'Solid';
   }

   set _patternStr(value) {
      if (!value || (value == 'Solid')) {
         this.host.dashed = false;
      } else {
         var val = JSON.parse(value);
         this.host.dashed = val;
      }
   }

   fromCOMP(comp, load, ignore) {
      this.props = comp.props;
      this.funcs = comp.funcs;
      if (load) this.writeProps(ignore);
   }


   static makeCOMP(comp, name) {
      // create the host object 
      var zClass = eval(comp._class);
      var zComp = new zClass(comp._name);
      zComp.component.fromCOMP(comp, true);
      if (name) {
         zComp.name = name;
         zComp.component._name = name;
      }
      return zComp;
   }



   static bootJSON(json, name) {
      var comp = JSON.parse(json);
      // now create the host object 
      var zClass = eval(comp._class);
      var zComp = new zClass(comp._name);
      zComp.component.fromJSON(json, true);
      if (name) {
         zComp.name = name;
         zComp.component._name = name;
      }
      return zComp;
   }

   fromJSON(json, load, ignore) {
      var comp = JSON.parse(json);
      this.props = comp.props;
      this.funcs = comp.funcs;
      if (load) this.writeProps(ignore);
   }

   toJSON() {
      // turn this into a JSON string
      function omitKeys(obj, keys) {
         var dup = {};
         for (var key in obj) {
            if (keys.indexOf(key) == -1) {
               dup[key] = obj[key];
            }
         }
         return dup;
      }
      var json = JSON.stringify(omitKeys(this, ['host', 'designMode']));
      return json;
   }


   writeProps(ignore) {
      // update host properties from the props store
      ignore = ignore ? ignore : [''];
      for (const key in this.props) {
         if (ignore.indexOf(key) == -1) {
            if (key in this.host) {
               this.host[key] = this.props[key];
            }
         }
      }
   }


   readProps() {
      // read the host properties and store them in props
      if (this.host) {
         for (const key in this.props) {
            if (key in this.host) {
               this.props[key] = this.host[key];
            }
         }
      }
   }

   delProps(keys) {
      var propKeys = keys.split(',');
      for (var i = 0; i < propKeys.length; i++) {
         propKeys[i] = propKeys[i].trim();
      }
      for (var k = 0; k < propKeys.length; k++) {
         if (propKeys[k] in this.props) {
            delete this.props[propKeys[k]];
         }
      }
   }

   addProps(keys) {
      var propKeys = keys.split(',');
      for (var i = 0; i < propKeys.length; i++) {
         propKeys[i] = propKeys[i].trim();
      }
      var first = 0;
      if (propKeys[0] == '#') {
         this.props.length = 0;
         first = 1;
      }
      for (var k = first; k < propKeys.length; k++) {
         this.props[propKeys[k]] = undefined;
      }
   }

   setProp(key, value) {
      this.props[key.trim()] = value;
   }

   getProp(key) {
      return this.props[key];
   }

   getEvents() {
      var events = [];
      for (const func in this.funcs) {
         if (func.startsWith('on.')) {
            var evt = func.slice(3);
            var body = this.funcs[func];
            events.push({ event: evt, code: body });
         }
      }
      return events;
   }

   getFuncs() {
      var funcs = [];
      var special = ['.', '#'];
      for (const fn in this.funcs) {
         if (special.includes(fn[0])) continue;
         if (fn.startsWith('on.')) continue;
         var body = this.funcs[fn];
         funcs.push({ func: fn, code: body });
      }
      return funcs;
   }

   getFunc(prefix) {
      for (const func in this.funcs) {
         if (func.startsWith(prefix)) {
            return this.funcs[func];
         }
      }
   }

   delFunc(fname) {
      delete this.funcs[fname.trim()];
   }

   setFunc(fname, fbody) {
      this.funcs[fname.trim()] = fbody.trim();
   }

   setEvent(etrigger, ebody) {
      this.funcs['on.' + etrigger.trim()] = ebody.trim();
   }


   onChange(key, value, dir) {
      if (this.onPropChange) {
         this.onPropChange(key, value, dir);
      }
      return value;
   }


   updateProp(key, value) {
      const k = key.trim();
      const sk = '$' + k;
      if (this.props[sk]) {
         this.props[k] = value;
         this.host[k] = value;
         this.update();
      }
   }

   update() {
      if (this.host.stage) {
         this.host.stage.update();
      }
   }

   wireup(key, onChange) {


      // var anyobj = {name:'stefan', age: 34};
      // zim.noWire({ source: anyobj });
      // console.dir(anyobj);

      // debugger;

      if (!key) {
         zim.noWire({ source: this.props });
         // kill the circ ref bugg
         this.props.arguments = null;
         return;
      }

      this.onPropChange = null;
      if (onChange && this.host) {
         this.onPropChange = onChange ? onChange : null;
         const wobj = zim.addWires(this.props);
         if (key in this.host) {
            if (this.host[key] !== undefined) {
               var filterFunction = (value, dir) => { return this.onChange(key, value, dir); };
               wobj.wire(this.host, key, true, true, filterFunction, null, key);
            }
         }
      }
   }

}


export { zComponent };
export { zany, zbool, zstr, zlist, zpick, zint, zfloat, zfrac, zcoord, zcolrgb, zcolzim, zdate, zrange };







