<style>
  .slide_canvas_class {
    opacity: 1.0;
    background-color:red;
    width: 800px;
    height: 600px;
    cursor: zoom-in;
  }
</style>

<div (click)="onSlideClick()"  (mousewheel)="onSlideZoom($event)" style="background-color: blueviolet; padding:5px; width:auto; height:auto;">
<canvas class="slide_canvas_class" id="slide_canvas"   >
  Your browser does not support the canvas element.
</canvas>
</div>

<input type="file" id="imgFilePicker" style="visibility:hidden; height:0px; width:0px">
<input type="file" id="imgFolderPicker" webkitdirectory directory multiple style="visibility:hidden; height:0px; width:0px">
<a id="svgSaverProxy"> </a>

