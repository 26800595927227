import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { fabric } from 'fabric';
import { SysEventsService, CANV, CanvasRect } from 'src/app/shared/services/sysevents.service';
import { Subscription } from 'rxjs';
import { WebRtcService } from 'src/app/shared/services/webrtc.service';
import { AuthService } from 'src/app/shared/services/auth.service';








@Component({
  selector: 'app-draw-canvas',
  templateUrl: './draw-canvas.component.html'
})

export class DrawCanvasComponent implements OnInit, OnDestroy {

  @Input() drawCanvID: string;
  private canvas: fabric.Canvas;
  private ignoreUpdates = false;
  private remCursor: fabric.Object = null;
  private myDrawCmdSubs: Subscription;

  private targetCanvWidth = 800;
  private targetCanvHeight = 600;
  private refCanvWidth = 800;
  private refCanvHeight = 600;
  private refCanvAR = this.refCanvWidth / this.refCanvHeight;
  private canvFreeSize = true;
  private remCursorMove: fabric.Group;
  private remCursorDraw: fabric.Group;
  private remCursorPoint: fabric.Group;

  private mouseCrumbColor: string = null;

  constructor(public ses: SysEventsService, public webrtc: WebRtcService, public auth: AuthService) {
  }

  ngOnDestroy(): void {
    this.myDrawCmdSubs.unsubscribe();
  }

  ngOnInit(): void {

    this.canvas = new fabric.Canvas(this.drawCanvID);
    this.canvas.perPixelTargetFind = true;
    this.canvas.preserveObjectStacking = true;

    this.Resize({ width: this.refCanvWidth, height: this.refCanvHeight, zoom: 1.0});
    this.ses.gDrawCanvas = this.canvas;
    this.ses.gDrawIsDirty = false;

    // Disable context menu
    const uc = (document.getElementsByClassName('upper-canvas draw_canvas_class')[0]) as HTMLElement;
    fabric.util.addListener(uc, 'contextmenu', (e) => { e.preventDefault(); });

    this.canvas.freeDrawingBrush.color = 'rgb(100,100,100)';
    this.canvas.freeDrawingBrush.width = 10;

    this.canvas.on('mouse:out', (evt) => { if (!evt.target) { this.updMouseOUT(); } });
    this.canvas.on('mouse:move', (evt) => { this.updMouseMOV(evt); });
    this.canvas.on('mouse:down', (evt) => { this.updMouseDWN(evt); });
    this.canvas.on('mouse:up', (evt) => { this.updMouseUP(evt); });


    this.canvas.on('object:added', (evt) => this.updObject(CANV.OBJ_ADD, evt.target));
    this.canvas.on('object:removed', (evt) => this.updObject(CANV.OBJ_DELETE, evt.target));

    this.canvas.on('object:moving', (evt) => this.updPosition(CANV.OBJ_MOVE, evt.target));
    this.canvas.on('object:modified', (evt) => this.updPosition(CANV.OBJ_MODIFY, evt.target));

    // we disable group selection for the moment..
    this.canvas.selection = false;
    // this.canvas.on('selection:cleared', (evt) => this.selObject('cleared', evt));
    // this.canvas.on('selection:updated', (evt) => this.selObject('updated', evt));
    // this.canvas.on('selection:created', (evt) => this.selObject('created', evt));


    this.myDrawCmdSubs = this.webrtc.RxWBoardDrawCmd.subscribe((act) => { this.remoteDrawActions(act); });
    this.loadIcons();
  }



  private loadIcons() {
    fabric.loadSVGFromURL('/assets/finger.svg', (objects) => {
      this.remCursorPoint = new fabric.Group(objects);
    });
    fabric.loadSVGFromURL('/assets/hand.svg', (objects) => {
      this.remCursorMove = new fabric.Group(objects);
    });
    fabric.loadSVGFromURL('/assets/draw.svg', (objects) => {
      this.remCursorDraw = new fabric.Group(objects);
    });
  }


  public selObject(act: string, evt: any) {
    console.log('sel[' + act + '] = ' + JSON.stringify(evt.target));
  }

  private setCanvasFrame(W: number, H: number, Z: number) {
    this.refCanvWidth = W;
    this.refCanvHeight = H;
    this.refCanvAR = W / H;

    this.canvas.setWidth(W);
    this.canvas.setHeight(H);
    this.canvas.setZoom(Z);

    //    console.log('W=' + W + ' H=' + H + ' Z=1.0');

    if (this.ses.Master) {
      this.drawAction(CANV.REF_SIZE, '');
    }
  }


  private fitCanvasFrame(W: number, H: number) {

    let w = W;
    let h = H;
    let zoom = 1.0;

    const AR = W / H;
    if (AR > this.refCanvAR) {
      h = H;
      w = Math.round(h * this.refCanvAR);
    } else {
      w = W;
      h = Math.round(W / this.refCanvAR);
    }
    zoom = w / this.refCanvWidth;
    //console.log('w=' + w + ' h=' + h + ' z=' + zoom);
    this.canvas.setHeight(h);
    this.canvas.setWidth(w);
    this.canvas.setZoom(zoom);
  }

  private ScaleToMaster(W: number, H: number, Z: number) {
    this.setCanvasFrame(W, H, Z);
    this.Resize({ width: this.targetCanvWidth, height: this.targetCanvHeight, zoom: 1.0} );
    // invoke a resize event onto main frame
    this.ses.emitResize();
  }


  // public Resize(W: number, H: number): [number, number] {

  //   this.targetCanvWidth = W;
  //   this.targetCanvHeight = H;

  //   if (this.ses.Master) { // master resize
  //     if (this.ses.gDrawIsDirty) {
  //       if (this.canvFreeSize) {
  //         this.setCanvasFrame(W, H, 1.0);
  //         this.canvFreeSize = false;
  //       } else {
  //         this.fitCanvasFrame(W, H);
  //       }
  //     } else {
  //       this.canvFreeSize = true;
  //       this.setCanvasFrame(W, H, 1.0);
  //     }
  //   } else {
  //     this.fitCanvasFrame(W, H);
  //   }
  //   return [this.canvas.getWidth(), this.canvas.getHeight()];
  // }


  public Resize(state: CanvasRect): CanvasRect {



    // we store these for the slave to resize to in case it gets to lock onto a new master canvas size

    const W = state.width;
    const H = state.height;

    this.targetCanvWidth = W;
    this.targetCanvHeight = H;

    if (this.ses.Master) { // master resize
      if (this.ses.gDrawIsDirty) {
        this.fitCanvasFrame(W, H);
      } else {
        this.setCanvasFrame(W, H, 1.0);
      }
    } else {
      this.fitCanvasFrame(W, H);
    }
    const ret: CanvasRect = {width : this.canvas.getWidth(), height: this.canvas.getHeight(), zoom: this.canvas.getZoom()};
    return ret;
  }



  private drawAction(act: string, body: string) {
    const w = this.canvas.getWidth();
    const h = this.canvas.getHeight();
    const z = this.canvas.getZoom();
    const cmd = '[' + act + ':' + w + ':' + h + ':' + z + ']' + body;
    this.webrtc.SendWBoardDrawAction(cmd);
  }



  private addRemObj(orgWidth: number, orgHeight: number, json: string) {
    const fabobj = JSON.parse(json) as fabric.Object;
    const cnv = this.canvas;
    fabric.util.enlivenObjects([fabobj], (objects: fabric.Object[]) => {
      const origRenderOnAddRemove = cnv.renderOnAddRemove;
      cnv.renderOnAddRemove = false;

      objects.forEach((o) => {
        o.selectable = false;
        cnv.add(o);
      });

      cnv.renderOnAddRemove = origRenderOnAddRemove;
      cnv.renderAll();
    }, '');
  }

  private delRemObj(json: string) {
    const fabobj = JSON.parse(json);
    const cnv = this.canvas;
    cnv.forEachObject((obj) => {
      if (obj.data === fabobj.data) {
        cnv.remove(obj);
      }
    });
    cnv.renderAll();
  }

  private movRemObj(orgWidth: number, orgHeight: number, json: string) {
    const fabobj: fabric.Object = JSON.parse(json);
    const cnv = this.canvas;
    cnv.forEachObject((obj) => {
      if (obj.data === fabobj.data) {
        obj.top = fabobj.top;
        obj.left = fabobj.left;
      }
    });
    cnv.renderAll();
  }

  private modRemObj(orgWidth: number, orgHeight: number, json: string) {
    this.delRemObj(json);
    this.addRemObj(orgWidth, orgHeight, json);
  }

  private delRemCursor() {
    if (this.remCursor) {
      //      console.log('Delete rem cursor');
      const cnv = this.canvas;
      cnv.remove(this.remCursor);
      this.remCursor = null;
      cnv.renderAll();
    }
  }

  private xScale(width: number, px: number): number {
    return (px * this.canvas.getWidth()) / (this.canvas.getZoom() * width);
  }

  private yScale(height: number, py: number): number {
    return (py * this.canvas.getHeight()) / (this.canvas.getZoom() * height);
  }


  private plotRemCursor(orgWidth: number, orgHeight: number, point: any) {
    const pointobj = JSON.parse(point);
    const cnv = this.canvas;

    if (this.remCursor === null) {
      //    console.log('Make rem cursor');
      this.remCursor = this.remCursorPoint;
      this.remCursor.selectable = false;
      this.remCursor.lockRotation = true;
      this.remCursor.lockUniScaling = true;
      this.remCursor.lockMovementX = true;
      this.remCursor.lockMovementY = true;
      this.remCursor.lockScalingFlip = true;
      this.remCursor.hoverCursor = 'default';
      this.remCursor.data = 'remcursor';

      cnv.add(this.remCursor);
    }

    const locX = this.xScale(orgWidth, (+pointobj.x));
    const locY = this.yScale(orgHeight, (+pointobj.y));

    this.remCursor.left = locX - 14;
    this.remCursor.top =  locY - 13;
    this.remCursor.setCoords();
    this.remCursor.bringToFront();

    if (this.mouseCrumbColor) {
      this.dropCrumb(locX, locY);
    }

    cnv.renderAll();
    //    console.log('plot-remc L= ' + this.remCursor.left);
  }

  private clearMouseTrail() {
    this.mouseCrumbColor = null;
    this.canvas.discardActiveObject();
    this.canvas.forEachObject((o) => {
      if (o.data === 'crumb') {
        this.canvas.remove(o);
        o = null;
      }
    });
    this.canvas.renderAll();
 }

  private dropCrumb(x: number, y: number) {
    const crumb = new fabric.Circle({
      fill: this.mouseCrumbColor,
      radius: 2,
    });
    crumb.left = x - 2;
    crumb.top = y - 2;
    crumb.selectable = false;
    crumb.lockRotation = true;
    crumb.lockUniScaling = true;
    crumb.lockMovementX = true;
    crumb.lockMovementY = true;
    crumb.lockScalingFlip = true;
    crumb.hoverCursor = 'default';
    crumb.data = 'crumb';
    this.canvas.add(crumb);
    crumb.setCoords();
    crumb.bringToFront();
  }


  private remoteDrawActions(action: string) {
    if (action.startsWith('[')) {
      const endcmd = action.indexOf(']');
      const head = action.slice(1, endcmd);
      const sections = head.split(':', 4);
      const cmd = sections[0];
      const orgWidth = parseInt(sections[1], 10);
      const orgHeight = parseInt(sections[2], 10);
      const orgZoom = parseInt(sections[3], 10);
      const objstr = action.substring(endcmd + 1);
      this.ignoreUpdates = true;
      //      console.log('RemDrawAct:' + cmd);
      switch (cmd) {

        case CANV.REF_SIZE:
          this.clearMouseTrail();
          this.ScaleToMaster(orgWidth, orgHeight, orgZoom);
          break;

        case CANV.CLEAR_ALL:
          this.clearMouseTrail();
          this.canvas.clear();
          this.ScaleToMaster(orgWidth, orgHeight, orgZoom);
          break;

        case CANV.MOUSE_UP:
          this.clearMouseTrail();
          break;

        case CANV.MOUSE_DWN:
          this.mouseCrumbColor = objstr;
          break;

        case CANV.MOUSE_OUT:
          this.clearMouseTrail();
          this.delRemCursor();
          break;

        case CANV.MOUSE_MOVE:
          this.plotRemCursor(orgWidth, orgHeight, objstr);
          break;

        case CANV.OBJ_ADD:
          this.addRemObj(orgWidth, orgHeight, objstr);
          break;

        case CANV.OBJ_DELETE:
          this.delRemObj(objstr);
          break;
        case CANV.OBJ_MOVE:
          this.movRemObj(orgWidth, orgHeight, objstr);
          break;
        case CANV.OBJ_MODIFY:
          this.modRemObj(orgWidth, orgHeight, objstr);
          break;

      }
      this.ignoreUpdates = false;
    }
    //            console.log(action);
  }

  private checkDirty() {

    if (this.canvas.getObjects().length === 0) {
      this.ses.gDrawIsDirty = false;
      return;
    }
    //  we check for remote cursor
    if ((this.canvas.getObjects().length === 1)) {
      if (this.canvas.getObjects()[0].data === 'remcursor') {
        this.ses.gDrawIsDirty = false;
        return;
      }
    }
    this.ses.gDrawIsDirty = true;
  }


  private updPosition(action: string, fobj: fabric.Object) {

    if (this.ignoreUpdates === false) {
      let json;

      switch (action) {

        case CANV.OBJ_MODIFY:
          if (fobj.data === undefined) {
            fobj.data = this.ses.GuidV4();
          }
          json = JSON.stringify(fobj.toJSON(['data']));
          break;

        case CANV.OBJ_MOVE:
          const proxy = {
            data: fobj.data,
            top: fobj.top,
            left: fobj.left
          };
          json = JSON.stringify(proxy);
          break;
      }
      this.drawAction(action, json);
    }
  }

  private updObject(action: string, fobj: fabric.Object) {

    if (this.ignoreUpdates === false) {
      let json;

      switch (action) {
        case CANV.OBJ_ADD:
          if (fobj.data === undefined) {
            fobj.data = this.ses.GuidV4();
          }
          json = JSON.stringify(fobj.toJSON(['data']));
          break;

        case CANV.OBJ_DELETE:
          const proxy = {
            data: fobj.data,
            top: fobj.top,
            left: fobj.left
          };
          json = JSON.stringify(proxy);
          break;
      }
      this.drawAction(action, json);
    }
    this.checkDirty();
  }

  private updMouseOUT() {
    this.drawAction(CANV.MOUSE_OUT, '');
  }

  private updMouseMOV(evt: fabric.IEvent) {
    //  console.log('MMx=' + evt.pointer.x + ' : targ:' + evt.target);
    // removed criteria yo send only when evt.target === null ?????
    const proxy = {
      x: evt.pointer.x,
      y: evt.pointer.y
    };
    const json = JSON.stringify(proxy);
    this.drawAction(CANV.MOUSE_MOVE, json);
  }

  private updMouseUP(evt: fabric.IEvent) {
    this.drawAction(CANV.MOUSE_UP, '');
  }

  private updMouseDWN(evt: fabric.IEvent) {
    this.drawAction(CANV.MOUSE_DWN, this.canvas.freeDrawingBrush.color);
  }

}

