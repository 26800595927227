import { Subject } from 'rxjs';



export const enum MediaT {
  AUDIO = 'audio',
  VIDEO = 'video'
}

export class MediaChannel {
  enabled = false;
  autostart = false;
  mutedState = true;
  mounted = false;
  mediaT: MediaT;
  twin: MediaChannel = null;

  kbCount = 0;

  defDir: RTCRtpTransceiverDirection = 'inactive';
  transceiver: RTCRtpTransceiver;
  locTrack: MediaStreamTrack;
  remTrack: MediaStreamTrack;
  locStream: MediaStream;
  remStream: MediaStream;
  mediaConstraints: MediaStreamConstraints;
  localVideoSink: HTMLVideoElement;
  remoteVideoSink: HTMLVideoElement;
  remoteAudioSink: HTMLAudioElement;
  onMuteChange: Subject<boolean>;

  constructor(public name: string, public pc: RTCPeerConnection, public updMediaCB: any) {
    this.locTrack = null;
    this.transceiver = null;
    this.locStream = null;
    this.mediaConstraints = null;
    this.localVideoSink = null;
    this.remoteVideoSink = null;
    this.onMuteChange = new Subject<boolean>();
  }

  public ScaleVideoSender(height: number) {
    if ((this.mediaT === 'video') && (this.mounted)) {
      const param = this.transceiver.sender.getParameters();
      const vh = this.transceiver.sender.track.getSettings().height;
      const scale = vh / height;
      param.degradationPreference = 'maintain-resolution';
      if (!param.encodings) { param.encodings = [{}]; } // Firefox workaround!
      param.encodings[0].scaleResolutionDownBy = scale;
      this.transceiver.sender.setParameters(param);

      const newheight = vh / scale;
      const newscale = this.transceiver.sender.getParameters().encodings[0].scaleResolutionDownBy;
    }
  }




  onLocalStreamError(e: any) {
    console.log('gotError');
  }

  private dirSendMode() {
    this.transceiver.direction = 'sendrecv';
    // if (this.defDir === 'recvonly') {
    //   this.transceiver.direction = 'sendrecv';
    // } else {
    //   this.transceiver.direction = 'sendonly';
    // }
  }


  public async setLocalStream(stream: MediaStream): Promise<void> {
    if (stream) {
      if (this.twin) {
        await this.twin.setLocalStream(stream);
      }
      const mtrack = stream.getTracks().find(track => track.kind === this.mediaT);
      if (mtrack) {
        this.locStream = stream;
        this.locTrack = mtrack;
        if (this.transceiver) {
          this.defDir = this.transceiver.direction;
          //          this.transceiver.sender.setStreams(stream);
          await this.transceiver.sender.replaceTrack(this.locTrack);
          this.transceiver.sender.track.onmute = () => { this.onSendTrackStopped(); };
          this.transceiver.sender.track.onunmute = () => { this.onSendTrackStarted(); };
          this.dirSendMode();
          console.log('SETLOCSTREAM: Replaced..track in ' + this.name + ' dir=' + this.transceiver.direction);
        } else {
          this.transceiver = this.pc.addTransceiver(this.locTrack, { direction: 'sendrecv', streams: [this.locStream] });
          this.transceiver.sender.track.onmute = () => { this.onSendTrackStopped(); };
          this.transceiver.sender.track.onunmute = () => { this.onSendTrackStarted(); };
          this.defDir = this.transceiver.direction;
          console.log('SETLOCSTREAM: Created..track in ' + this.name + ' dir=' + this.transceiver.direction);
        }
        if (this.localVideoSink) {
          this.localVideoSink.srcObject = this.locStream;
        }
        this.mounted = true;
      }
    }
  }

  public async mountLocal(): Promise<void> {

    const Navigator = navigator as any;

    if (this.mediaConstraints) {
      const mca = this.mediaConstraints.audio as MediaTrackConstraints;
      const mcv = this.mediaConstraints.video as MediaTrackConstraints;
      const devID = mcv ? mcv.deviceId : mca.deviceId;
      const kind = mcv ? 'video' : 'audio';
      if (devID) {  // we have a legitimate local device to mount
        if (devID === 'screen') {
          try {
            const stream = await Navigator.mediaDevices.getDisplayMedia(this.mediaConstraints);
            await this.setLocalStream(stream);
          } catch (e) {
            this.onLocalStreamError(e);
          }
        } else {
          try {
            const stream = await navigator.mediaDevices.getUserMedia(this.mediaConstraints);
            await this.setLocalStream(stream);
          } catch (e) {
            this.onLocalStreamError(e);
          }
        }
        console.log('loc-mounted=>' + this.name + ' dir=' + this.transceiver.direction);
        this.mounted = true;
      } else {  // make a dummy transceiver without any local tracks
        if (this.transceiver) {
          this.transceiver.sender.replaceTrack(null);
        } else {
          this.transceiver = this.pc.addTransceiver(kind, { direction: 'sendrecv' });
          this.defDir = this.transceiver.direction;
        }
        console.log('loc-dummy-not-mounted=>' + this.name);
        this.mounted = false;
      }
    }
  }


  public async mountRemote(): Promise<void> {
    if (this.mediaConstraints && !this.mounted) {  // we have a legitimate local device to mount onto the remote transceiver
      const mca = this.mediaConstraints.audio as MediaTrackConstraints;
      const mcv = this.mediaConstraints.video as MediaTrackConstraints;
      const devID = mca ? mca.deviceId : mcv.deviceId;
      const kind = mca ? 'audio' : 'video';

      if (devID) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(this.mediaConstraints);
          await this.setLocalStream(stream);
        } catch (e) {
          this.onLocalStreamError(e);
        }
        this.HookRemoteSinks(true);
        console.log('rem-mounted=>' + this.name);
        this.mounted = true;
      } else {  // make a dummy transceiver without any local tracks
        if (this.transceiver) {
          this.transceiver.sender.replaceTrack(null);
        } else {
          this.transceiver = this.pc.addTransceiver(kind, { direction: 'sendrecv' });
          this.defDir = this.transceiver.direction;
        }
        console.log('rem-dummy-not-mounted=>' + this.name);
        this.mounted = false;
      }
    }
  }


  public async startMedia(doOffer: boolean): Promise<void> {
    if (this.mounted) {
      await this.transceiver.sender.replaceTrack(this.locTrack);
      this.transceiver.direction = 'sendrecv';
      this.transceiver.sender.track.onmute = () => { this.onSendTrackStopped(); };
      this.transceiver.sender.track.onunmute = () => { this.onSendTrackStarted(); };
      //      this.dirSendMode();
    } else {
      await this.mountLocal();
      if (doOffer) {
        this.updMediaCB();
      }
    }
    if (this.localVideoSink) {
      this.localVideoSink.srcObject = this.locStream;
    }
    this.mutedState = false;
  }



  public async stopMedia() {
    if (this.transceiver) {
      this.transceiver.direction = 'recvonly';
      if (this.transceiver.sender) {
        await this.transceiver.sender.replaceTrack(null);
      }
      this.resetVideoSink(this.localVideoSink);
    }
    this.mutedState = true;
  }

  public async UnMount() {
    if (this.mounted) {

      console.log('Unmount:' + this.name);
      if (this.twin) {
        const mctwin = this.twin;
        await mctwin.UnMount();
        this.twin = null;
      }

      if (this.transceiver) {
        this.transceiver.direction = 'recvonly';
        if (this.transceiver.sender) {
          await this.transceiver.sender.replaceTrack(null);
        }
        if (this.locTrack) {
          this.locTrack.stop();
        }
        this.locTrack = null;
        this.resetVideoSink(this.localVideoSink);
      }
    }
    this.mounted = false;
    this.mutedState = true;
  }

  public async Mute(state: boolean) {
    if (state) {
      await this.stopMedia();
    } else {
      await this.startMedia(true);
    }
  }

  public onSendTrackStopped() {
    console.log('Sender track STOPPED: ' + this.name);
    this.mutedState = true;
    this.onMuteChange.next(this.mutedState);
  }

  public onSendTrackStarted() {
    console.log('Sender track STARTED: ' + this.name);
    this.mutedState = false;
    this.onMuteChange.next(this.mutedState);
  }


  public HookRemoteSinks(state: boolean) {
    const medStream = state ? this.remStream : null;
    if (this.remoteAudioSink) {
      this.remoteAudioSink.srcObject = medStream;
    }
    if (this.remoteVideoSink) {
      this.remoteVideoSink.srcObject = medStream;
    }
  }


  public onRemoteTrackRemoved() {
    console.log('Remote Track REMOVED: ' + this.name);
  }


  public onRecvTrackStarted(indx: number) {
    console.log('Receiver track STARTED: ' + indx + ':' + this.name);
    this.HookRemoteSinks(true);
    // if (this.remoteVideoSink && this.remStream) {
    //   this.remoteVideoSink.srcObject = this.remStream;
    // }
    // if (this.remoteAudioSink && this.remStream) {
    //   this.remoteAudioSink.srcObject = this.remStream;
    // }

  }


  public onRecvTrackStopped(indx: number) {
    console.log('Receiver track STOPPED: ' + indx + ':' + this.name);
    this.HookRemoteSinks(false);
    // if (this.remoteVideoSink) {
    //   this.remoteVideoSink.srcObject = null;
    // }
    // if (this.remoteAudioSink) {
    //   this.remoteAudioSink.srcObject = null;
    // }
  }


  public async closeMedia() {
    console.log('Closing media' + this.name);
    await this.UnMount();
    this.enabled = false;
    this.mutedState = true;
    this.kbCount = 0;
    this.locTrack = null;
    this.transceiver = null;
    this.locStream = null;
  }


  // this elaborate procedure is to clear remnant images in firefox
  private resetVideoSink(videoSink: HTMLVideoElement) {
    if (videoSink !== null) {
      videoSink.pause();
      videoSink.removeAttribute('src'); // empty source
      videoSink.srcObject = null;
      videoSink.load();
    }
  }

}
