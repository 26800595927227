import { Injectable } from '@angular/core';

export interface AVconfig {
  lmAudio: string[];
  lmVideo: string[];

  ssAudio: string;
  lmFPS: number[];
  ssFPS: number;

  lmWidth: number[];
  lmHeight: number[];

  canvWidth: number;
  canvHeight: number;
  rcolWidth: number;
  echoCancel: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private avconfig: AVconfig;


  constructor() {
    this.loadAVConfig();
  }

  public validAudioDevice(indx: number): boolean {
    return this.avconfig.lmAudio[indx] ? true : false;
  }

  public validVideoDevice(indx: number): boolean {
//    return this.avconfig.lmVideo[indx] ? true : false;
    return this.avconfig.lmVideo[indx] ? true : false;
  }

  set AVConfig(value: AVconfig) {
    this.avconfig = value;
    localStorage.setItem('avconfig', JSON.stringify(this.avconfig));
  }

  get AVConfig(): AVconfig {
    this.loadAVConfig();
    return this.avconfig;
  }

  public defaultConfig(): AVconfig {
    const defConfig: AVconfig =
     {
      lmAudio: ['default', null],
      lmVideo: ['default', null],
      ssAudio: 'default',
      echoCancel: false,
      lmWidth: [640, 640],
      lmHeight: [480, 480],
      canvWidth: 1024,
      canvHeight: 768,
      rcolWidth: 240,
      lmFPS: [10, 10],
      ssFPS: 10
    };
    return defConfig;
  }

  private loadAVConfig() {
    this.avconfig = JSON.parse(localStorage.getItem('avconfig'));
    if (this.avconfig === null) {
      this.avconfig = this.defaultConfig();
    }
  }


}
