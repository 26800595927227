<app-toolbar [panels] = "tbPanels" ></app-toolbar>

<dx-context-menu
  [dataSource]="ctxMenuItems"
  [(visible)] = "showCtxMenu"
  displayExpr = "name"
  [closeOnOutsideClick] = "true"
  (onItemClick)="ctxMenuClick($event)">
</dx-context-menu>



