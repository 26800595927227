import { Component, EventEmitter, OnInit, OnDestroy, Input, AfterViewInit, Output } from '@angular/core';
import { fabric } from 'fabric';
import { SysEventsService, CANV } from 'src/app/shared/services/sysevents.service';
import { Subscription } from 'rxjs';
import { WBREM_CTRL, WebRtcService } from 'src/app/shared/services/webrtc.service';
import { FormGroup } from '@angular/forms';

import {
  faPen,
  faArrowsAlt,
  faArrowLeft,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

import {
  faSave,
  faFile,
  faFolderOpen
} from '@fortawesome/free-regular-svg-icons';
import { SlideViewComponent } from './slide-view.component';


@Component({
  selector: 'app-slide-tools',
  templateUrl: './slide-tools.component.html'
})

export class SlideToolsComponent implements OnInit {

  @Input() slideViewComp: SlideViewComponent;

  // public slideCanvContainer: HTMLDivElement;

  // public checkboxGroupForm: FormGroup;

  public bnNextPageIcon = faArrowRight;
  public bnPrevPageIcon = faArrowLeft;
  public bnSaveFileIcon = faSave;
  public bnLoadFolderIcon = faFolderOpen;
  public bnLoadFileIcon = faFile;


  constructor() {
  }



  ngOnInit(): void {
  }

  public bnSaveFileClick() {
      this.slideViewComp.SaveFile();
  }

  public async bnLoadFolderClick() {
      this.slideViewComp.LoadFolder();
  }

  public async bnLoadFileClick() {
      this.slideViewComp.LoadFile();
  }

  public bnPrevPageClick() {
     this.slideViewComp.PrevPage();
  }


  public bnNextPageClick() {
     this.slideViewComp.NextPage();
  }

}

