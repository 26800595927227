import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { TokenInterceptor } from './shared/services/token.interceptor';

import { FormsModule } from '@angular/forms';

// Reactive Form
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './shared/routing/app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

// Auth service
import { AuthService } from './shared/services/auth.service';
import { SysEventsService } from './shared/services/sysevents.service';
import { ConfigComponent } from './components/config/config.component';
import { MasterConsoleComponent } from './components/master-console/master-console.component';

import { DrawCanvasComponent } from './tools/draw-view/draw-canvas.component';
import { DrawToolsComponent } from './tools/draw-view/draw-tools.component';

import { SlideViewComponent } from './tools/slide-view/slide-view.component';
import { SlideToolsComponent } from './tools/slide-view/slide-tools.component';

import { SessionControlComponent } from './tools/session-view/session-view.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { WebRtcService } from './shared/services/webrtc.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfigService } from './shared/services/config.service';
import { MessageBoardComponent } from './tools/mesg-view/message-board.component';
import { BgBannerComponent } from './shared/bg-banner/bg-banner.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { FrameBuilderComponent } from './components/frame-builder/frame-builder.component';
import { FrameEditorComponent } from './components/frame-builder/editor/frame-editor.component';
import { FrameClassListComponent } from './components/frame-builder/classes/frame-class-list.component';
import { FrameBrowserComponent } from './components/frame-builder/browser/frame-browser.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxFileManagerModule } from 'devextreme-angular/ui/file-manager';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxTileViewModule, DxNumberBoxModule, DxFormModule, DxMenuModule } from 'devextreme-angular';
import { FrameVarsComponent } from './components/frame-builder/vars/frame-vars.component';
import { DxButtonGroupModule, DxSelectBoxModule, DxDataGridModule, DxToolbarModule, DxTemplateModule, } from 'devextreme-angular';
import { DxPopupModule, DxTabPanelModule, DxTextBoxModule, DxDropDownButtonModule, DxCheckBoxModule } from 'devextreme-angular';
import { AngularSplitModule } from 'angular-split';
import { FramePropsComponent } from './components/frame-builder/props/frame-props.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormlyColorPicker, PropWrapperComponent } from './formly-custom-template';
import { FEToolbarComponent } from './components/frame-builder/editor/frame-editor-toolbar.component';
import { ToolbarItemComponent } from './components/frame-builder/editor/toolbar/toolbar-item.component';
import { ToolbarPanelComponent } from './components/frame-builder/editor/toolbar/toolbar-panel.component';
import { ToolbarComponent } from './components/frame-builder/editor/toolbar/toolbar.component';
import { DxColorBoxModule, DxDrawerModule} from 'devextreme-angular';
import { FrameCoderComponent } from './components/frame-builder/coder/frame-coder.component';
import { DxAccordionModule } from 'devextreme-angular';
import { FrameScriptComponent } from './components/frame-builder/script/frame-script.component';
import { AceEditorModule } from 'ng2-ace-editor';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    ConfigComponent,
    MasterConsoleComponent,
    DrawCanvasComponent,
    DrawToolsComponent,
    SlideViewComponent,
    SlideToolsComponent,
    SessionControlComponent,
    MessageBoardComponent,
    BgBannerComponent,
    FrameBuilderComponent,
    FrameEditorComponent,
    FrameClassListComponent,
    FrameBrowserComponent,
    FrameVarsComponent,
    FramePropsComponent,
    FormlyColorPicker,
    PropWrapperComponent,
    FEToolbarComponent,
    ToolbarItemComponent,
    ToolbarPanelComponent,
    ToolbarComponent,
    FrameCoderComponent,
    FrameScriptComponent
  ],
  imports: [
    AngularSplitModule.forRoot(),
    BrowserModule,
    // HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ColorPickerModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    AngularResizedEventModule,
    NgbModule,
    FontAwesomeModule,
    PdfJsViewerModule,
    DxButtonModule,
    DxFileManagerModule,
    DxTreeViewModule,
    DxListModule,
    DxColorBoxModule,
    DxTreeViewModule,
    DxContextMenuModule,
    DxTileViewModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DxPopupModule,
    DxTemplateModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxDropDownButtonModule,
    DxToolbarModule,
    DxTabPanelModule,
    DxButtonGroupModule,
    DxFormModule,
    DxDrawerModule,
    DxMenuModule,
    FormlyBootstrapModule,
    DxAccordionModule,
    AceEditorModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'propPanel', component: PropWrapperComponent }],

      types: [
        { name: 'colorPicker', component: FormlyColorPicker, wrappers: ['propPanel'] },
      ]
    }),
  ],
  providers: [AuthService, SysEventsService, WebRtcService, ConfigService,
              // { provide: HTTP_INTERCEPTORS,
              //   useClass: TokenInterceptor,
              //   multi: true}
              ],
  bootstrap: [AppComponent]
})
export class AppModule { }
