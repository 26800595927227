<style>
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
  }



  .locview {
    grid-area: g_locview;
    position: relative;
    object-fit: fill;
  }


  .remview {
    grid-area: g_remview;
    position: relative;
    object-fit: fill;
  }

  .controls {
    grid-area: g_controls;
  }

  .tools {
    grid-area: g_tools;
  }

  .message {
    grid-area: g_message;
  }

  .grid-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    width: 100%;
    display: grid;
    grid-template-areas: 'g_main g_locview''g_main  g_remview''g_main  g_controls''g_main   g_tools''g_main g_message';
    grid-gap: 2px;
    background-color: #202020;
    /* opacity: 0.5; */
    padding: 5px;
  }

  .grid-container>div {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0px;
    font-size: 30px;
  }

  .mainview {
    grid-area: g_main;
    position: relative;
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px;
    z-index: 1;
  }

  .main_frame_layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #draw_view_container {
    z-index: 50;
  }

  #video_view_container {
    z-index: 40;
  }

  #slide_view_container {
    z-index: 30;
  }

  .bn-image-toggle {
    position: absolute;
    top: 0px;
    right: 0px;
    outline-style: none;
    color: black;
    font-weight: bold;
    font-size: 12px;
    padding: 0px;
    padding-top: -2px;
    margin: 0px;
    border-style: none;
    height: 18px;
    width: 18px;
    z-index: 50;
  }
</style>


<div id="container">
  <div class="grid-container"
       id="viewer_grid_container"
       [ngStyle]="gridSize()">


    <div class="mainview"
         *ngIf="this.webrtc.Loaded"
         id="main_window">


      <div class="main_frame_layer" (resized)="onResized($event)" id="draw_view_container"  [style.visibility]="wbCanvasHiddenTag">
        <app-draw-canvas
                         [drawCanvID]="drawCanvasID">
        </app-draw-canvas>
      </div>

      <div class="main_frame_layer" id="video_view_container"  [style.visibility]="mainVideoHiddenTag">
        <video id="video_main"
               autoplay
               muted
               width="800px"
               height="600px">
        </video>
      </div>

      <div class="main_frame_layer" id="slide_view_container">
        <app-slide-view [style.visibility]="slideViewHiddenTag"
                        [slideCanvID]="slideCanvasID">
        </app-slide-view>
      </div>

    </div>


    <div class="locview">
      <video id="video_local"
             autoplay
             muted
             width="100%"
             height="100%"
             poster="/assets/dummy-user.png">
      </video>
      <button class="bn-image-toggle"
              (click)="onLocViewToggle()">{{locViewBnText}}</button>
    </div>

    <div class="remview"
         (dblclick)="onRemViewDblClick()"
         (mousewheel)="onRemViewZoom($event)"
         style="cursor: zoom-in;">
      <video id="video_remote"
             autoplay
             width="100%"
             height="100%"
             poster="/assets/dummy-user.png"></video>
      <audio id="audio_remote"
             autoplay> REMOTE AUDIO </audio>
      <!--<div>
                <video autoplay="true"  width="320" height="192" id="video" src="media/video.mp4" style="display:none"/>
            </div>
            <div>
                <canvas id="c1" width="320" height="192" style="display:none"></canvas>
                <canvas id="c2" width="320" height="192" style="background-image: url(media/foo.png);background-repeat: no-repeat;"></canvas>
            </div>-->
    </div>

    <div *ngIf="this.webrtc.Loaded"
         class="controls"
         style="height:auto;">
      <app-session-control [style.display]="ctrlsDisplayTag"
                           [RemOnline]="RemOnline"
                           [viewPortSwitch]="viewPortAltMode">
      </app-session-control>
    </div>

    <div *ngIf="this.webrtc.Loaded"
         class="tools"
         style="height:auto; font-size:10px;">

      <app-draw-tools [style.display]="wbToolsDisplayTag"
                      [drawCanvID]="drawCanvasID"
                      [drawCanvasComp]="drawCanvasComp"
                      [WBEditPenMode]="wbEditPenMode"
                      [WBEditMoveMode]="wbEditMoveMode"
                      [RemOnline]="RemOnline">
      </app-draw-tools>

      <app-slide-tools [style.display]="slideToolsDisplayTag"
                       [slideViewComp]="slideViewComp">
      </app-slide-tools>
    </div>


    <div *ngIf="this.webrtc.Loaded"
         class="message">
      <app-message-board></app-message-board>
    </div>
  </div>
</div>
