import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SysEventsService, CanvasRect } from 'src/app/shared/services/sysevents.service';
import { WebRtcService } from 'src/app/shared/services/webrtc.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GlobalWorkerOptions, getDocument, PDFDocumentProxy } from 'pdfjs-dist';
import { fabric } from 'fabric';
import { FrameClass } from 'src/app/components/frame-builder/frame-store.service';
//import { FrameStatic } from '../../components/frame-builder/frame-class';




@Component({
  selector: 'app-slide-view',
  templateUrl: './slide-view.component.html'
})


export class SlideViewComponent implements OnInit, OnDestroy {
  @Input() slideCanvID: string;

  private frameSeq: FrameClass[] = [];
  private mySlideViewCmdSubs: Subscription;
  private zoomLevel = 1.0;
  private currentPage = 1.0;
  private numPages = 0;
  private fileName = '';




  constructor(public ses: SysEventsService, public cdr: ChangeDetectorRef, public webrtc: WebRtcService, public auth: AuthService) {
  }

  ngOnDestroy(): void {
    this.mySlideViewCmdSubs.unsubscribe();
  }

  ngOnInit(): void {
  }


  public onSlideZoom(evt: WheelEvent) {
    if (this.ses.Master) {
      if (evt.deltaY < 0) {
        // zoom in
        if (this.ZoomLevel < 2) {
          this.ZoomLevel = this.ZoomLevel * 1.2;
        }
      } else {
        // zoom out
        if (this.ZoomLevel > 0.5) {
          this.ZoomLevel = this.ZoomLevel / 1.2;
        }
      }
      console.log('Zoom = ' + this.zoomLevel);
      this.cdr.detectChanges();
    }
  }

  public onSlideClick() {
    if (this.ses.Master) {
      this.cdr.detectChanges();
    }
  }

  public NextPage() {
    if (this.currentPage < this.numPages) {
      this.PageNumber = this.currentPage + 1;
    }
  }

  public get NumPages(): number {
    return this.numPages;
  }

  public PrevPage() {
    if (this.currentPage > 1) {
      this.PageNumber = this.currentPage - 1;
    }
  }

  public get PageNumber(): number {
    return this.currentPage;
  }


  public set PageNumber(pgn: number) {
    this.currentPage = this.slideLoadpage(pgn);
    this.slideRenderPage(this.currentPage);
  }

  public get ZoomLevel(): number {
    return this.zoomLevel;
  }

  public set ZoomLevel(zoom: number) {
    this.zoomLevel = zoom;
    this.slideRenderPage(this.currentPage);
  }


  private slideLoadpage(pgn: number): number {


    return pgn;
  }

  private slideRenderPage(pgn: number) {
 //   this.frameSeq[pgn].render(this.canvas, this.zoomLevel );
  }

  private fabLoadNewPage(fname: string) {

  }

  private fabAddNewPage(bgImage: string) {

    //   this.fabCanvas.setBackgroundImage(canvas.toDataURL('image/png'),
    //   this.fabCanvas.renderAll.bind(this.fabCanvas),
    //   { minimumScaleTrigger: 1.0 });
    // this.fabCanvas.setWidth(canvas.width);
    // this.fabCanvas.setHeight(canvas.height);
    // this.fabCanvas.setZoom(this.zoomLevel);


  }


  private async pdfRenderPage(pdfDoc: PDFDocumentProxy, pgnum: number, zoom: number): Promise<string> {
    const page = await pdfDoc.getPage(pgnum);
    if (page) {
      const viewPort = page.getViewport({ scale: zoom });
      const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
      const context = canvas.getContext('2d');
      canvas.height = viewPort.height;
      canvas.width = viewPort.width;
      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewPort,
      };
      page.render(renderContext).promise.then(() => {
        console.log('Page rendered tp png:' + pgnum);
        return Promise.resolve(canvas.toDataURL('image/png'));
      });
    }
    return Promise.reject('');
  }


  private async loadPDF(pdfData: string) {
    GlobalWorkerOptions.workerSrc = './assets/pdf/build/pdf.worker.js';
    const loadingTask = getDocument({ data: pdfData });
    const pdf = await loadingTask.promise;
    if (pdf) {
      console.log('Pages = ' + pdf.numPages);
      for (let i = 1; i < pdf.numPages + 1; i++) {
        const imgStr = await this.pdfRenderPage(pdf, i, 1.0);
        this.fabAddNewPage(imgStr);
      }
    }
  }

  public Resize(state: CanvasRect) {
    //this.canvas.width = state.width;
    //this.canvas.height = state.height;
  }


  public remoteSlideViewActions(act: string) {

  }


  public Clear(indx: number) {
    // if (this.frameSeq) {
    //   this.frameSeq.discardActiveObject();
    //   this.frameSeq.forEachObject((o) => {
    //     this.frameSeq.remove(o);
    //   });
    //   this.frameSeq.renderAll();
    // }
    //    this.checkDirty();
  }



  public SaveFile() {
    // const w = this.frameSeq.getWidth();
    // const h = this.frameSeq.getHeight();
    // const svgStr = this.frameSeq.toSVG({
    //   viewBox: {
    //     x: 0,
    //     y: 0,
    //     width: w,
    //     height: h
    //   }
    // });
//    this.saveFile('mcs-canvas-.svg', svgStr);
  }


  public SaveJSON() {
    // const w = this.frameSeq.getWidth();
    // const h = this.frameSeq.getHeight();
    const svgStr = JSON.stringify(this.frameSeq);
    this.saveFile('mcs-canvas-.json', svgStr);
  }

  public async LoadFolder() {
    const files = await this.loadFolder();
    if (files) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < files.length; i++) {
        console.log(files[i].name);
      }
    }
  }

  public async LoadFile() {
    const cnv = this.frameSeq;
    this.loadFile().then((f: File) => {
      const reader = new FileReader();
      reader.onload = async (event) => {

        if (f.name.endsWith('.pdf')) {
          const strData = event.target.result as string;
          // const blobData = new Blob([strData], { type: 'application/pdf' });
          // const urlData = window.URL.createObjectURL(blobData);
          await this.loadPDF(strData);
        } else
          if (f.name.endsWith('.fab')) {
            const strData = event.target.result as string;
            fabric.loadSVGFromString(strData, (objects: any, options: any) => {
              // Group elements to fabric.PathGroup (more than 1 elements) or to fabric.Path
              const loadedObject = fabric.util.groupSVGElements(objects, options);
              // Set sourcePath
              // loadedObject.set('sourcePath', elem.getAttribute('data-url'));
  //            cnv.add(loadedObject);
              //          loadedObject.center().setCoords();
  //            cnv.renderAll();
            });
          } else {  // any ither valid image format like pbg, bmp, tiff, jpeg etc
            const arrData = event.target.result as ArrayBuffer;



          }

      };
      reader.readAsBinaryString(f);
    }, (ex: any) => {
      alert('File load failed');
    });
  }

  private saveFile(filename: string, contents: string) {
    filename = filename || 'Untitled.txt';
    const opts = { type: 'text/plain' };
    const file = new File([contents], '', opts);
    const svgFile = document.getElementById('svgSaverProxy');
    svgFile.setAttribute('href', window.URL.createObjectURL(file));
    svgFile.setAttribute('download', filename);
    svgFile.click();
  }

  private loadFolder(): Promise<FileList> {
    const promise = new Promise<FileList>((resolve, reject) => {
      const filePicker = document.getElementById('imgFolderPicker') as HTMLInputElement;
      filePicker.onchange = (e) => {
        const files = filePicker.files;
        if (files.length > 0) {
          resolve(files);
        }
        reject(null);
      };
      filePicker.click();
    });
    return promise;
  }


  private loadFile(): Promise<File> {
    const promise = new Promise<File>((resolve, reject) => {
      const filePicker = document.getElementById('imgFilePicker') as HTMLInputElement;
      filePicker.onchange = (e) => {
        const afile = filePicker.files[0];
        if (afile) {
          resolve(afile);
        }
        reject(null);
      };
      filePicker.click();
    });
    return promise;
  }

}
