<style>
  .dx-button {
    margin-left: 20px;
  }

  ::ng-deep .dx-datagrid-headers {
    color: white;
    background-color: #202020;
    font-style: italic;
  }

  ::ng-deep .dx-overlay-shader {
    background-color: rgba(0, 0, 0, 0);
  }

  ::ng-deep .dx-datagrid-headers .cell-highlighted {
    color: Black;
    background-color: white;
    font-style: italic;
  }
</style>



<div id="container">
  <dx-data-grid #VarDataGrid
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onContextMenuPreparing)="onContextMenuPreparing($event)"
                (onOptionChanged)="onGridOpionChanged($event)"
                (onContentReady)="onContentReadyAction($event)"
                (onCellPrepared)="onCellPrepared($event)"
                (onFocusedCellChanging)="onFocusCellChange($event)"
                [rowAlternationEnabled]="true"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [dataSource]="FrameVarStore"
                [columnAutoWidth]="true"
                [wordWrapEnabled]="true"
                height ="100%"
                width = "100%"
                [showBorders]="true"
                [showColumnLines]="true"
                [showRowLines]="true"
                [columns]="GridColumns">
    <dxo-state-storing [enabled]="true"
                       type="localStorage"
                       storageKey={{gridStorageID}}></dxo-state-storing>
    <dxo-editing mode="batch"
                 [useIcons]="true"
                 [allowAdding]="true"
                 [allowDeleting]="true"
                 [allowUpdating]="true"
                 [selectTextOnEditStart]="false"
                 [startEditAction]="'dblClick'">
    </dxo-editing>
    <dxo-row-dragging [allowReordering]="true"
                      [onReorder]="onReorder"
                      [showDragIcons]="false">
    </dxo-row-dragging>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-sorting mode="none"></dxo-sorting>
  </dx-data-grid>


  <dx-popup [width]="330"
            [height]="360"
            [showTitle]="true"
            title="Column Add"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [(visible)]="showColAdd">

    <div class="form">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">Column Name</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="newColumnName"></dx-text-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Default Value</div>
          <div class="dx-field-value">
            <dx-text-box placeholder="Enter a value..."
                         [(value)]="newColumnValue"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <dx-button stylingMode="contained"
                     text="Cancel"
                     type="danger"
                     [width]="100"
                     (onClick)="modalCancel($event)">
          </dx-button>
          <dx-button stylingMode="contained"
                     text="Continue"
                     type="success"
                     [width]="100"
                     (onClick)="columnModalAdd($event)">
          </dx-button>
        </div>

      </div>

    </div>
  </dx-popup>

  <dx-popup [width]="330"
            [height]="200"
            [showTitle]="true"
            title="Column Rename"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [(visible)]="showColRename">

    <div class="form">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">New Column Name</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="newColumnName"></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <dx-button stylingMode="contained"
                     text="Cancel"
                     type="danger"
                     [width]="100"
                     (onClick)="modalCancel($event)">
          </dx-button>
          <dx-button stylingMode="contained"
                     text="Continue"
                     type="success"
                     [width]="100"
                     (onClick)="columnModalRename($event)">
          </dx-button>
        </div>

      </div>

    </div>
  </dx-popup>




</div>
