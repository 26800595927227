import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChildren, QueryList } from '@angular/core';
import { isInstanceOf } from 'rxdb/dist/types/rx-query';
import { ToolbarItem, ToolbarItemComponent } from './toolbar-item.component';

export class ToolbarPanel {
  host: any;
  name: string;
  color?: string;
  icon?: string;
  index?: number;
  items: ToolbarItem[];
  update?: any;
  collapsed?: boolean;
  getItemValue?: any;
  setItemValue?: any;
  callBack?: any;
}

@Component({
  selector: 'app-toolbar-panel',
  templateUrl: './toolbar-panel.component.html'
})
export class ToolbarPanelComponent implements OnInit {

  @ViewChildren(ToolbarItemComponent) tbPanelItems: QueryList<ToolbarItemComponent>;
  @Input() panel: ToolbarPanel;
  @Input() index: number;

  private isHidden = false;
  public labelStr;

  get hidden(): boolean {
    return this.isHidden;
  }

  set hidden(hide: boolean) {
    this.isHidden = hide;
  }

  get collapsed(): boolean {
    return this.panel.collapsed || false;
  }

  set collapsed(collapse: boolean) {
    this.panel.collapsed = collapse;
    this.tbPanelItems.forEach(item => { item.Hidden = collapse; });
    this.labelStr = collapse ? ' ' + this.panel.name + ' ' : this.panel.name;
    this.cdr.markForCheck();
  }

  updateGUI() {
    this.cdr.detectChanges();
  }

  constructor(public cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    for (const [index, elm] of this.panel.items.entries()) {
      elm.index = elm.index ? elm.index : index;
      elm.callBack = elm.callBack ? elm.callBack : this.panel.callBack;
    }
    this.panel.setItemValue = this.setItemValue.bind(this);
    this.panel.getItemValue = this.getItemValue.bind(this);
    this.labelStr = this.panel.name;
    this.panel.color = '#8080FF';
    this.panel.index = this.index;
    this.panel.update = this.updateGUI.bind(this);
  }

  getItemValue(id: any): any {
    let elm;
    if (id instanceof Number) {
      elm = this.panel.items.find(e => e.index === id);
    }
    if (id instanceof String) {
      elm = this.panel.items.find(e => e.id === id);
    }
    if (elm) {
      return elm.value;
    }
    return null;
  }

  setItemValue(id: any, value: any): any {
    let elm;
    if (id instanceof Number) {
      elm = this.panel.items.find(e => e.index === id);
    }
    if (id instanceof String) {
      elm = this.panel.items.find(e => e.id === id);
    }
    if (elm) {
      elm.value = value;
    }
  }

  togglePanel(ev: any) {
    this.collapsed = !this.collapsed;
    if (this.panel.host.PanelToggle) {
      this.panel.host.PanelToggle(this.panel);
    }
  }

}
