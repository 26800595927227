


<div class="container">

  <app-bg-banner></app-bg-banner>

  <div class="col-fixed"
       id="signupPanel" >

    <fieldset>

      <legend>Sign Up</legend>

      <div class="displayTable">

        <div class="displayTableCell">

          <div class="formGroup">
            <input type="email"
                   class="formControl"
                   placeholder="EMail Address"
                   #userMail
                   required>
          </div>


          <div class="formGroup">
            <input type="password"
                   class="formControl"
                   placeholder="Password"
                   #userPassword
                   required>
          </div>

          <div class="formGroup">
            <input type="text"
                   class="formControl"
                   placeholder="Choose your Display Name"
                   #userDisplayName
                   required>
          </div>


          <div class="formGroup">
            <input type="button"
                   class="btn btn-info"
                   value="Sign up"
                   (click)="authService.SignUp(userMail.value, userPassword.value, userDisplayName.value)">
          </div>


          <span>Already have an account?</span>
          <div class="formGroup">
            <input type="button"
                   class="btn btn-warning"
                   value="Go to Sign in"
                   (click)="authService.GotoSignIn()">
          </div>
        </div>

      </div>
    </fieldset>
  </div>
</div>
