import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['..//styles/entry-dialogs.css']

})

export class SignInComponent implements OnInit {

  constructor(public authService: AuthService) {
  }

  ngOnInit() {
  }


}
