<div class="container">

  <app-bg-banner></app-bg-banner>

  <div class="col-fixed"
       id="signinPanel"
       style="display:block; z-index:10; opacity: 1.0;">

    <fieldset>

      <legend>Forgot Password...</legend>

      <div class="displayTable">
        <div class="displayTableCell">

          <span>Please enter your email address to request a password reset.</span>

          <div class="formGroup">
            <input type="email"
                   class="formControl"
                   placeholder="Email Address"
                   #passwordResetEmail
                   required>
          </div>


          <!-- Calling ForgotPassword from AuthService Api -->
          <div class="formGroup">
            <input type="submit"
                   class="btn btn-danger"
                   value="Reset Password"
                   (click)="authService.ForgotPassword(passwordResetEmail.value)">
          </div>

          <div class="formGroup">
            <input type="button"
                   class="btn btn-warning"
                   value="Back to Sign-in..."
                   (click)="authService.GotoSignIn()">
          </div>

        </div>
      </div>
    </fieldset>
  </div>
</div>
