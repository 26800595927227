import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FrameSet, FrameClass } from '../frame-store.service';
import { FrameEditorComponent } from '../editor/frame-editor.component';
import { FrameVarsComponent } from '../vars/frame-vars.component';


@Component({
  selector: 'app-frame-class-list',
  templateUrl: './frame-class-list.component.html'
})

export class FrameClassListComponent implements OnInit {

  @Input() frameEditor: FrameEditorComponent;
  @Input() frameVars: FrameVarsComponent;

  frameSet: FrameSet = null;
  cmItems: any;
  selectedFrameIndx = 0;
  selectedFrame: FrameClass;

  constructor(public cdr: ChangeDetectorRef) {
    this.cmItems = [
      { text: 'Add' },
      { text: 'Delete' },
      { text: 'Move-Up' },
      { text: 'Move-Dwn' },
      { text: 'Duplicate' },
      { text: 'Export' },
      {
        text: 'Import',
        items: [
          { text: 'One' },
          { text: 'Multiple' }]
      }
    ];
  }


  public resize(){
    console.log('Resize - Scroller');
  }

  ngOnInit() {
  }

  public async OnItemCM(e: any) {
    console.dir(e);
    if (!e.itemData.items) {
      switch (e.itemData.text) {

        case 'Add':
          if (this.frameSet) {
            await this.frameSet.AddFrame();
          }
          break;

        case 'Delete':
          if (this.frameSet) {
            if (this.frameSet.FrameClasses.length > 1) {
              this.frameEditor.UnLoad();
              await this.frameSet.DeleteFrame(this.selectedFrameIndx);
            }
          }
          break;

        case 'Move-Up':
          if (this.frameSet) {
            if (this.selectedFrameIndx > 0) {
              await this.frameSet.MoveFrame(this.selectedFrameIndx, -1);
            }
          }
          break;

        case 'Move-Dwn':
          if (this.frameSet) {
            if (this.selectedFrameIndx < this.frameSet.FrameClasses.length - 1) {
              await this.frameSet.MoveFrame(this.selectedFrameIndx, +1);
            }
          }
          break;

        case 'Duplicate':
          if (this.frameSet) {
            await this.frameSet.CopyFrame(this.selectedFrameIndx, this.selectedFrameIndx + 1);
          }
          break;

      }
    }
  }

  public async OnItemContext(e: any) {
    await this.SetSelectedFrameIndx(e.itemIndex);
  }


 async SetSelectedFrameIndx(value: number) {
    this.selectedFrameIndx = value;
    this.selectedFrame = this.frameSet.FrameClasses[this.selectedFrameIndx];
    await this.frameEditor.Load(this.selectedFrame);
    await this.frameVars.Load(this.selectedFrame);
    this.cdr.detectChanges();
  }

  async OnFrameSelect(e: any) {
    const indx = e.itemIndex;
    if (indx !== this.selectedFrameIndx) {
      await this.SetSelectedFrameIndx(indx);
    }
  }

  public async Load(frameSet: FrameSet) {
    this.frameSet = frameSet;
    await this.SetSelectedFrameIndx(0);
  }


}
