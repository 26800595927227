<style>
  .ngb_left {
    margin: 0px;
    margin-right: 4px;

    padding: 0px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    text-align: left;
  }

  .ngb_right {
    margin: 0px;
    margin-left: 4px;
    padding: 0px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    text-align: right;
  }
</style>

<div style="height:100%; display: flex; flex-flow: column; background-color:indigo;">
  <div class="input-group"
       style="flex: 0 0 34px;">
    <input id="sendInput"
           type="text"
           #msgText
           (keyup.enter)="sendTxt(msgText.value)"
           (keypress)= "keyPress($event)"
           class="form-control"
           style="height:30px; width:80%;"
           placeholder="Message...">
    <button id="sendButton"
            style="height:30px; padding:2px; margin:0px; box-shadow: 0 0 0 0 white;"
            class="btn btn-danger"
            type="button"
            (click)="sendTxt(msgText.value)">Send</button>
  </div>

  <div style="flex: 0 0 auto; overflow:auto; height:90%;">
      <p *ngFor="let msg of messages"
         style="margin:2px;">
        <ngb-alert [ngClass]="msg.align"
                   [dismissible]="false"
                   [type]="msg.type">{{ msg.text }}</ngb-alert>
      </p>
    </div>
</div>
