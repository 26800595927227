
<div class="container">

<app-bg-banner></app-bg-banner>

  <div class="col-fixed"
       id="signinPanel"
       style="display:block; z-index:10; opacity: 1.0;">

    <fieldset>

      <legend>Sign In</legend>

      <div class="displayTable">

        <div class="displayTableCell">

          <div class="formGroup">
            <input type="email"
                   class="formControl"
                   placeholder="Email"
                   #userMail
                   required>
          </div>

          <div class="formGroup">
            <input type="password"
                   class="formControl"
                   placeholder="Password"
                   #userPassword
                   required>
          </div>


          <div class="formGroup">
            <input type="button"
                   class="btn btn-info"
                   value="Log in"
                   (click)="authService.SignIn(userMail.value, userPassword.value)">
          </div>

          <div class="formGroup">
            <input type="button"
                   class="btn btn-danger"
                   value="Forgot Password?"
                   (click)="authService.GotoForgotPassword()">
          </div>

          <span>Don't have an account?</span>
          <div class="formGroup">
            <input type="button"
                   class="btn btn-warning"
                   value="Register with us.."
                   (click)="authService.GotoSignUp()">
          </div>
        </div>

      </div>
    </fieldset>
  </div>
</div>
