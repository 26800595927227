<style>
  .frame-edit-toolbar {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    margin: 3px;
    padding: 3px;
  }

</style>

<div class="frame-edit-toolbar">
    
  <div *ngFor="let panel of panels; index as i">
    <app-toolbar-panel [panel]="panel"
                       [index]="i">
    </app-toolbar-panel>
  </div>
</div>
