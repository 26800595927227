<style>
  .draw_canvas_class {
    opacity: 0.5;
    width: 800px;
    height: 600px;
  }
</style>

<canvas class="draw_canvas_class" id="draw_canvas" >
  Your browser does not support the canvas element.
</canvas>
