<style>
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: black;
  }

  .split-col {
    height: 100vh; 
    outline: 1px dashed #cca7e2;
    margin: 0px;
  }

  .split-row {
    outline: 1px dashed #cca7e2;
    margin: 0px;
  }

  as-split.as-transition.as-init:not(.as-dragging) ::ng-deep>.as-split-gutter,
  as-split.as-transition.as-init:not(.as-dragging)>.as-split-area {
    transition: flex-basis 1.5s !important;
  }

  as-split.as-disabled ::ng-deep>.as-split-gutter {
    cursor: pointer !important;
  }
</style>


<div class="container-split"
     style="margin:0px;">
  <as-split class="split-col"
            unit="percent"
            useTransition="true"
            direction="horizontal"
            #splitCol="asSplit"
            gutterDblClickDuration="300"
            (gutterDblClick)="onGutterDblClick($event)"
            (transitionEnd)="onResize($event)"
            (dragEnd)="onResize($event)">


    <!-- LEFT COLUMN -->
    <as-split-area [size]="colLeft"  maxSize="40" minSize="5" #frameBrowser="asSplitArea" style="background-color:grey">
      <app-frame-browser [frameScroller]="frameScrollerComp"> </app-frame-browser>
    </as-split-area>

    <!-- MID COLUMN -->
    <as-split-area [size]="colMid" id="frameEdit">

      <as-split class="split-row"
                unit="percent"
                direction="vertical"
                #splitRow="asSplit"
                (dragEnd)="onResize($event)">
        <as-split-area size="80"
                       maxSize="90"
                       minSize="50"
                       #frameEditor="asSplitArea">
          <app-frame-editor [frameProps]="framePropsComp" [frameCoder]="frameCoderComp" [frameScript]="frameScriptComp" > </app-frame-editor>
        </as-split-area>

        <as-split-area size="20"
                       minSize="10"
                       maxSize="50"
                       #varEditor="asSplitArea"
                       style="background-color:cornflowerblue;">

          <dx-tab-panel #tabPanel>
            <dxi-item title="Template[]">
              <app-frame-class-list [frameEditor]="frameEditorComp"
                                  [frameVars]="frameVarsComp"> </app-frame-class-list>
            </dxi-item>
            <dxi-item title="Var[]">
              <app-frame-vars [frameEditor]="frameEditorComp"> </app-frame-vars>
            </dxi-item>
            <dxi-item title="Frame[]">
            
            </dxi-item>
          </dx-tab-panel>

        </as-split-area>
      </as-split>
    </as-split-area>

    <!-- RIGHT COLUMN -->
    <as-split-area [size]="colRight"  maxSize="40"  minSize="5"  #linkEditor="asSplitArea" id="propFrame"  style="background-color:darkgray">
      <dx-tab-panel #tabPanel  (onTitleClick)="selectTab($event)"  >
        <dxi-item title="Props">
          <app-frame-props [frameEditor]="frameEditorComp"> </app-frame-props>
        </dxi-item>
        <dxi-item title="Coder">
           <app-frame-coder [frameEditor]="frameEditorComp"> </app-frame-coder> 
        </dxi-item>
        <dxi-item title="Script">
          <app-frame-script [frameEditor]="frameEditorComp"> </app-frame-script> 
       </dxi-item>
     </dx-tab-panel>
    </as-split-area>

  </as-split>
</div>



<!-- <style>
  :host {
    display: block;
    width: 100%;
    height: 100%;
    background-color: darkorchid;
  }

  .bloc {
    height: 100%;
    background-color: cyan;
  }

  .explanations {
    padding: 15px;
    background-color: coral;
  }

  .panel {
    font-size: 100px;
    font-weight: bold;
    color: #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: cadetblue;
    overflow: hidden;
  }

  .panel>p {
    margin: 0;
  }

  button {
    margin-bottom: 10px;
  }
</style>



<div>
  <as-split *ngIf="config"
  direction="horizontal"
  [disabled]="config.disabled"
  (dragEnd)="onDragEnd(-1, $event)">
<ng-template ngFor let-column [ngForOf]="config.columns" let-icol="index">
   <as-split-area *ngIf="column.visible"
               [order]="icol"
               [size]="column.size">
       <as-split direction="vertical"
              [disabled]="config.disabled"
              (dragEnd)="onDragEnd(icol, $event)">
           <ng-template ngFor let-row [ngForOf]="column.rows" let-irow="index">
               <as-split-area *ngIf="row.visible"
                           [order]="irow"
                           [size]="row.size">
                   <div [ngSwitch]="row.type" class="bloc">
                       <div *ngSwitchCase="'doc'" class="explanations">
                           <p>All areas size and visibility are saved to localStorage.<br>
                           Toggle areas visibility using following buttons:</p>
                           <ng-template ngFor let-c [ngForOf]="config.columns">
                               <ng-template ngFor let-r [ngForOf]="c.rows">
                                   <button *ngIf="r.type !== 'doc'"
                                           (click)="r.visible = !r.visible; refreshColumnVisibility()"
                                           [class.active]="!r.visible"
                                           class="btn btn-warning">{{ r.type }}</button>
                               </ng-template>
                           </ng-template>
                           <br>
                           <button class="btn btn-warning" [class.active]="!config.disabled" (click)="toggleDisabled()">{{ 'isDisabled: ' + config.disabled }}</button>
                           <br>
           <button (click)="resetConfig()" class="btn btn-warning">Reset localStorage</button>
                       </div>
                       <div *ngSwitchDefault class="panel">
                           <p>{{ row.type }}</p>
                       </div>
                   </div>
               </as-split-area>
           </ng-template>
       </as-split>
   </as-split-area>
</ng-template>
</as-split>
</div>
 -->
<!-- <style>

  .fbrowser {
    grid-area: gfbrowser;
    background-color: white;
    overflow: auto;
    resize: horizontal;
    border: 3px blue;
    min-width: 1fr;
    max-width: 30vw;
  }

  .fscroller {
    grid-area: gfscroller;
    background-color: lightpink;
    z-index: 50;
  }


  .feditor {
    grid-area: gfeditor;
    background-color: lightcyan;
  }

  .ftools {
    grid-area: gftools;
    background-color: lightseagreen;
  }

  .fcoder {
    grid-area: gfcoder;
    background-color: lightgreen;
  }


  .grid-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-areas: "gfbrowser gftools gftools gfcoder" "gfbrowser gfeditor gfeditor gfcoder" "gfscroller gfscroller gfscroller gfscroller";
    grid-template-columns: 1fr 2fr 2fr 2fr;
    grid-template-rows: 42px auto 240px;
    grid-gap: 2px;
    background-color: #202020;
    padding: 5px;
  }

</style>

<div>
  <div class="grid-container">

    <div class="fbrowser">
      <app-frame-browser [frameScroller]="frameScrollerComp"> </app-frame-browser>
    </div>

    <div class="ftools">
      <app-frame-tools [frameEditor]="frameEditorComp"> </app-frame-tools>
    </div>



    <div class="fscroller">
      <div id="container" style="width:100%;">
        <div id="right-bar"
             style="float:right; margin-top: 5px; margin-right: 5px;">
        </div>


        <div id="left-bar">
          <div class="d-flex">
            <ul ngbNav
                #navScroller="ngbNav"
                class="nav-pills"
                orientation="horizontal"
                [destroyOnHide]="false">
              <li ngbNavItem="navPreview">
                <a ngbNavLink>Frames</a>
                <ng-template ngbNavContent>
                  <app-frame-scroller
                  [frameEditor]="frameEditorComp"
                  [frameVars]="frameVarsComp"
                  > </app-frame-scroller>
                </ng-template>
              </li>
              <li ngbNavItem="navVars">
                <a ngbNavLink>Inputs</a>
                <ng-template ngbNavContent>
                  <app-frame-vars
                  [frameEditor]="frameEditorComp"
                  [varLinkComp] = "varLinkComp"
                  > </app-frame-vars>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
        <div [ngbNavOutlet]="navScroller"
             class="ml-4">
        </div>


      </div>

    </div>

    <div class="fcoder">
      <div class="d-flex"
           style="float:left;">
        <ul ngbNav
            #navCodeBox="ngbNav"
            class="nav-pills"
            orientation="horizontal"
            [destroyOnHide]="false">
          <li ngbNavItem="navLinks">
            <a ngbNavLink>Links</a>
            <ng-template ngbNavContent>
              <app-frame-varlink
              [frameEditor]="frameEditorComp"
              > </app-frame-varlink>
            </ng-template>
          </li>
          <li ngbNavItem="navCoder">
            <a ngbNavLink>Coder</a>
            <ng-template ngbNavContent>
              <app-frame-coder> </app-frame-coder>
            </ng-template>
          </li>
        </ul>
      </div>
      <div [ngbNavOutlet]="navCodeBox"
           class="ml-4">
      </div>
    </div>


    <div class="feditor">
      <app-frame-editor
      [frameToolsComp]= "frameToolsComp"
      > </app-frame-editor>
    </div>

  </div>
</div> -->
