import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FrameLibService, FrameSetCatalog, FrameClass } from './frame-store.service';
import { FrameEditorComponent } from './editor/frame-editor.component';
import { FrameClassListComponent } from './classes/frame-class-list.component';
import { FrameVarsComponent } from './vars/frame-vars.component';
import { ChangeDetectionStrategy } from '@angular/core';
import { SplitComponent, SplitAreaDirective } from 'angular-split';
import { FramePropsComponent } from './props/frame-props.component';
import { FrameScriptComponent } from './script/frame-script.component';
import { FrameCoderComponent } from './coder/frame-coder.component';
import * as ace from 'ace-builds/src-noconflict/ace';



const COL_LEFT = 12;
const COL_MID = 72;
const COL_RIGHT = 16;


export class Product {
  ID: number;
  Name: string;
  Price: number;
}
export class SimpleProduct {
  Name: string;
  ID: number;
}
@Component({
  selector: 'app-frame-builder',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './frame-builder.component.html'
})

export class FrameBuilderComponent implements AfterViewInit, OnInit {

  @ViewChild(FrameEditorComponent) frameEditorComp;
  @ViewChild(FrameClassListComponent) frameScrollerComp;
  @ViewChild(FrameVarsComponent) frameVarsComp;
  @ViewChild(FramePropsComponent) framePropsComp;
  @ViewChild(FrameScriptComponent) frameScriptComp;
  @ViewChild(FrameCoderComponent) frameCoderComp;

  @ViewChild('splitCol', { static: false }) splitCol: SplitComponent;
  @ViewChild('splitRow', { static: false }) splitRow: SplitComponent;
  @ViewChild('frameBrowser', { static: false }) frameBrowserBox: SplitAreaDirective;
  @ViewChild('frameEditor', { static: false }) frameEditorBox: SplitAreaDirective;
  @ViewChild('varEditor', { static: false }) varEditorBox: SplitAreaDirective;
  @ViewChild('linkEditor', { static: false }) linkEditorBox: SplitAreaDirective;




  colLeft = COL_LEFT;
  colMid = COL_MID;
  colRight = COL_RIGHT;

  areaSizes: string;
  initDone = false;

  products: Product[];
  selectedFrame: FrameClass;

  constructor(public frameLib: FrameLibService) {
    ace.config.set('basePath', './scripts/ace/');
    ace.config.set('modePath', '');
    ace.config.set('themePath', '');
  }

  async ngAfterViewInit() {
    this.frameLib.Init().then(() => {
      if (this.frameLib.FSCatalogs.length === 0) {
        this.frameLib.AddCatalog().then(() => {
          this.frameLib.AddFrameSet().then(frSet => {
            frSet.AddFrame().then(() => {
              this.frameScrollerComp.Load(frSet).then(() => {
                this.selectedFrame = frSet.FrameClasses[0];
                this.initDone = true;
                });
            });
          });
        });
      } else {
        const frameSet = this.frameLib.FSCatalogs[0].FrameSets[0];
        this.frameScrollerComp.Load(frameSet).then(() => {
          this.selectedFrame = frameSet.FrameClasses[0];
          this.initDone = true;
        });
      }
    });
  }


  onGutterDblClick(e: any) {
    if (e.gutterNum === 1) {
      if (this.colLeft > 0) {
        // collapse left column
        this.colMid += this.colLeft;
        this.colLeft = 0;
      } else if (this.colMid > COL_LEFT) {
        // expand left column
        this.colMid -= COL_LEFT;
        this.colLeft = COL_LEFT;
      } else {
        this.colLeft = COL_LEFT;
        this.colMid = COL_MID;
        this.colRight = COL_RIGHT;
      }
    }

    if (e.gutterNum === 2) {
      if (this.colRight > 0) {
        // collapse right column
        this.colMid += this.colRight;
        this.colRight = 0;
      } else if (this.colMid > COL_RIGHT) {
        // expand left column
        this.colMid -= COL_RIGHT;
        this.colRight = COL_RIGHT;
      } else {
        this.colLeft = COL_LEFT;
        this.colMid = COL_MID;
        this.colRight = COL_RIGHT;
      }
    }
  }

  onResize(e: any) {
    this.areaSizes = this.frameEditorBox.elRef.nativeElement.clientWidth + ':' + this.frameEditorBox.elRef.nativeElement.clientHeight;
    if (this.initDone) {
    this.frameEditorComp.resize();
    this.frameScrollerComp.resize();
    this.frameVarsComp.resize();
    }
//    console.log(this.areaSizes);
  }

  selectTab(evt: any){
    if (evt.itemData.title === 'Script') {
      this.frameScriptComp.BuildScript();
    }
  }

  ngOnInit() {
  }







}

