<div class="container">

  <app-bg-banner></app-bg-banner>

  <div class="col-fixed"
       id="signupPanel" >

    <fieldset>

      <legend>Email verification</legend>

      <div class="displayTable">
        <div class="displayTableCell">

            <span>Thanks for Registering</span>


            <div class="formGroup" *ngIf="authService.userData as user">
              <p class="text-center">We have sent a confirmation email to <strong>{{user.email}}</strong>.</p>
              <p class="text-center">Please check your email and click on the link to verfiy your email address.</p>
            </div>

            <div class="formGroup">
              <input type="button"
                     class="btn btn-info"
                     value="Resend verification email"
                     (click)="authService.SendVerificationMail()">
            </div>

            <div class="formGroup">
              <input type="button"
                     class="btn btn-warning"
                     value="Back to Sign-in..."
                     (click)="authService.GotoSignIn()">
            </div>

        </div>
      </div>
    </fieldset>
  </div>
</div>

