import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import { SysEventsService, CanvasRect } from '../../shared/services/sysevents.service';
import { DrawCanvasComponent } from 'src/app/tools/draw-view/draw-canvas.component';
import { Subscription } from 'rxjs';
import { WBREM_CTRL, WebRtcService, MASTER_CTRL } from 'src/app/shared/services/webrtc.service';
import { Router } from '@angular/router';
import { SlideViewComponent } from 'src/app/tools/slide-view/slide-view.component';




@Component({
  selector: 'app-master-console',
  templateUrl: './master-console.component.html'
})

export class MasterConsoleComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DrawCanvasComponent) drawCanvasComp;
  @ViewChild(SlideViewComponent) slideViewComp;

  public title = 'Master Console';
  public width: number;
  public height: number;
  public drawCanvasID = 'draw_canvas';
  public slideCanvasID = 'slide_canvas';
  public wbEditPenMode = false;
  public wbEditMoveMode = false;
  public RemOnline = false;
  public wbToolsVisible = true;
  public wbCanvasVisible = false;

  public viewPortAltMode = false;


  private onWBVisibleChange: Subscription;
  private onResizeWindow: Subscription;
  private onMasterExit: Subscription;
  private onVideoScaleIndex: Subscription;
  private onLocViewVisible: Subscription;
  private onClassInSession: Subscription;



  private locViewVisibleText = 'x';
  private locViewHiddenText = '+';
  public locViewBnText = this.locViewVisibleText;
  private locViewVisible = true;

  public ctrlsDisplayTag = 'none';
  public wbToolsDisplayTag = 'none';
  public wbCanvasHiddenTag = 'hidden';
  public mainVideoHiddenTag = 'hidden';
  public slideViewHiddenTag = '';
  public slideToolsDisplayTag = 'block';

  private videoHeightTable: number[] = [135, 180, 240, 360, 480];
  private videoHeightIndex = 1;

  public gridRows = this.videoHeight + 'px ' + this.videoHeight + 'px min-content min-content auto';
  public gridColumns = 'auto ' + this.videoWidth + 'px';

  //  grid-template-columns:
  public slideViewSize: CanvasRect;

  constructor(public ses: SysEventsService,
    public webrtc: WebRtcService,
    public cdr: ChangeDetectorRef,
    public router: Router,
    public ngZone: NgZone) {
    console.log('Master-Console-START...');
  }

  public gridSize(): any {
    const map = new Map<string, string>();
    map.set('grid-template-rows', this.gridRows);
    map.set('grid-template-columns', this.gridColumns);
    return map;
  }

  get locViewHeight(): number {
    return this.locViewVisible ? this.videoHeight : 0;
  }

  get videoHeight(): number {
    return this.videoHeightTable[this.videoHeightIndex];
  }

  get videoWidth(): number {
    return (this.videoHeightTable[this.videoHeightIndex] * 4) / 3;
  }

  async setClassInSession(state: boolean) {
    if (state) {
      this.ses.emitVideoScaleIndex(this.videoHeightIndex);
      this.ses.doDing();
    } else {
      await this.ses.doBeep();
      alert('We have lost the connection....');
      if (this.ses.Master) {
        // the student has disconnected from the session unexpectadly
      } else {
        // the master has exited so logout automatically
        this.ses.emitMasterExit();
      }

    }
  }

  public ScaleVideoElements(index: number) {

    this.videoHeightIndex = index;
    this.gridRows = this.locViewHeight + 'px ' + this.videoHeight + 'px min-content min-content auto';
    this.gridColumns = 'auto ' + this.videoWidth + 'px';
    this.webrtc.MediaChannels[1].ScaleVideoSender(this.videoHeight);
    if (this.ses.Master) {
      this.webrtc.SendMasterCtrlAction(MASTER_CTRL.VIDSCALE, this.videoHeightIndex);
    }
    this.cdr.detectChanges();
  }

  private setLocViewVisible(state: boolean) {
    if (state) {
      this.locViewBnText = this.locViewVisibleText;
      this.locViewVisible = true;
    } else {
      this.locViewBnText = this.locViewHiddenText;
      this.locViewVisible = false;
    }
    this.gridRows = this.locViewHeight + 'px ' + this.videoHeight + 'px min-content min-content auto';
    this.gridColumns = 'auto ' + this.videoWidth + 'px';
    this.cdr.detectChanges();
    if (this.ses.Master) {
      this.webrtc.SendMasterCtrlAction(MASTER_CTRL.LOCVIS, this.locViewVisible ? 1 : 0);
    }
    console.log('Local view visisble = ' + this.locViewVisible);
  }

  public onLocViewToggle() {
    this.ses.emitLocViewVisible(!this.locViewVisible);
  }


  public onRemViewZoom(evt: WheelEvent) {
    if (this.ses.Master) {
      let newIndex = this.videoHeightIndex;
      if (evt.deltaY < 0) {
        // zoom in
        if (newIndex < this.videoHeightTable.length - 1) {
          newIndex++;
        }
      } else {
        // zoom out
        if (newIndex > 0) {
          newIndex--;
        }
      }
      this.ses.emitVideoScaleIndex(newIndex);
    }
  }

  public onRemViewDblClick() {
    if (this.ses.Master) {
      console.log('Switch video window');
      this.viewPortAltMode = !this.viewPortAltMode;
      if (this.viewPortAltMode) {
        // maximize resolution of remote camera
        this.webrtc.SendMasterCtrlAction(MASTER_CTRL.VIDRES, 1, this.videoHeightTable[this.videoHeightTable.length - 1]);
      } else {
        // return to the local setting on master
        this.webrtc.SendMasterCtrlAction(MASTER_CTRL.VIDRES, 1, this.videoHeight);
      }
      this.cdr.detectChanges();
    }
  }

  doResize() {
    const drawContainer = document.getElementById('draw_view_container') as HTMLDivElement;
    const videoWindow = document.getElementById('video_main') as HTMLVideoElement;
    if (drawContainer) {
      const W = drawContainer.clientWidth;
      const H = drawContainer.clientHeight;
      let state: CanvasRect = { width: W, height: H, zoom: 1.0 };
      state = this.drawCanvasComp.Resize(state);
      this.slideViewComp.Resize(state);
      videoWindow.width = state.width;
      videoWindow.height = state.height;
    }
  }

  onResized(event: ResizedEvent) {
    this.doResize();
  }

  ngAfterViewInit() {
    this.onWBVisibleChange = this.ses.onWBVisibleChange.subscribe((visible: boolean) => { this.setWBVisibleState(visible); });
    this.webrtc.RxWBoardCtrlCmd.subscribe((cmd) => { this.onRxWBRemoteCmd(cmd); });
    this.onResizeWindow = this.ses.ResizeWindow.subscribe(() => { this.doResize(); });
    this.onMasterExit = this.ses.onMasterExit.subscribe(() => { this.doExit(); });
    this.onVideoScaleIndex = this.ses.onVideoScaleIndex.subscribe((index) => { this.ScaleVideoElements(index); });
    this.onLocViewVisible = this.ses.onLocViewVisible.subscribe((state) => { this.setLocViewVisible(state); });
    this.onClassInSession = this.ses.onClassInSession.subscribe((state) => { this.setClassInSession(state); });
  }



  doExit() {
    //    console.log('Master-Console-EXIT...');
    this.webrtc.CloseWebRTC(false).then(() => {
      this.ngZone.run(() => this.router.navigate(['dashboard']));
    });
  }


  async ngOnInit(): Promise<void> {
    //  console.log('MC-WebRTC Start...');
    const succ = await this.webrtc.InitWebRTC();
    //    if (this.ses.Master) {
    this.ctrlsDisplayTag = 'block';
    //    }
    //  console.log('MC-WebRTC Continue...');
  }

  private onRxWBRemoteCmd(action: string) {
    if (action.startsWith('[')) {
      const endcmd = action.indexOf(']');
      const head = action.slice(1, endcmd);
      const sections = head.split(':', 2);
      const cmd = sections[0];
      const state = (sections[1] === 'true') ? true : false;
      //      console.log('onRxWBRemoteCmd:' + cmd + '=' + state);
      switch (cmd) {

        case WBREM_CTRL.REMON:
          if (this.ses.Master) {
            this.RemOnline = state;
            this.ses.ClassOpen = false;
            this.cdr.detectChanges();
          }
          break;
        case WBREM_CTRL.VISIBLE:
          this.setWBVisibleState(state);
          this.doResize();
          this.cdr.detectChanges();
          break;

        case WBREM_CTRL.TOOLS:
          this.wbToolsVisible = state;
          this.cdr.detectChanges();
          break;

        case WBREM_CTRL.DRAW:
          this.wbEditPenMode = state;
          this.cdr.detectChanges();
          break;
        case WBREM_CTRL.MOVE:
          this.wbEditMoveMode = state;
          this.cdr.detectChanges();
          break;

      }

    }
  }


  private setWBVisibleState(visible: boolean) {
    if (visible) {
      this.wbCanvasHiddenTag = '';
      this.wbToolsDisplayTag = 'block';
    } else {
      this.wbToolsDisplayTag = 'none';
      this.wbEditMoveMode = false;
      this.wbEditPenMode = false;
      this.wbCanvasHiddenTag = 'hidden';
      if (this.ses.Master) {
      }
      else {
      }
    }
    this.wbCanvasVisible = visible;
    this.cdr.detectChanges();
  }


  ngOnDestroy(): void {
    //    console.log('Master-Console-Destroy');
    this.onLocViewVisible.unsubscribe();
    this.onClassInSession.unsubscribe();
    this.onVideoScaleIndex.unsubscribe();
    this.onMasterExit.unsubscribe();
    this.onWBVisibleChange.unsubscribe();
    this.onResizeWindow.unsubscribe();
    this.ses.doMainWinDestroy();
  }

}
