import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { FrameVarDef, FrameClass, VARTYPE } from '../frame-store.service';
import { FrameEditorComponent } from '../editor/frame-editor.component';
import { DxDataGridComponent } from 'devextreme-angular';

interface ColumnInfo {
  caption: string;
  dataType: string;
  dataField: string;
  name: string;
  varID: string;
}


class ElementPropSelection {
  text: string;
  onItemClick: any;
}

@Component({
  selector: 'app-frame-vars',
  templateUrl: './frame-vars.component.html'
})


export class FrameVarsComponent implements OnInit {

  @Input() frameEditor: FrameEditorComponent;
  @ViewChild('VarDataGrid', { static: false }) dataGrid: DxDataGridComponent;

  gridStorageID: string;
  FrameVarStore: ArrayStore;
  GridColumns: ColumnInfo[];
  frame: FrameClass;

  colSelectIndex = -1;
  focusColumnIndex = -1;

  showColAdd = false;
  showColRename = false;
  showLinkAdd = false;

  newColumnName = 'NewCol';
  newColumnValue = 'Hello World';
  //  dataTypes = ['boolean', 'number', 'string', 'object'];

  selectedColumnName: string;

  selectedCanvasObj: fabric.Object;

  canvasObjSetter = 'none';
  canvasObjID = 'null';




  constructor(public cdr: ChangeDetectorRef) {
  }

  public resize(){
    console.log('Resize - Vars');
  }

  ngOnInit(): void {
  }

  async onReorder(e) {
    await this.frame.MoveRow(e.fromIndex, e.toIndex);
    this.UpdateView();
  }


  onGridOpionChanged(e: any) {
    if (e.fullName.indexOf('visibleIndex') !== -1) {
      const prevIndx = e.previousValue;
      const newIndx = e.value;
      this.MoveColumn(newIndx, prevIndx);
    }
  }

  onCellPrepared(e: any) {
    if (e.rowType === 'header') {
      e.cellElement.onclick = this.onColumnHeaderClick.bind(this);
      e.cellElement.colIndex = e.column.index;
    }
  }

  focusColumnHeader() {
    const dg = this.dataGrid as any;
    const elm = dg.element.nativeElement as HTMLElement;
    const tblParent = elm.children[0].children[4].children[0];
    const tbl = tblParent.children[0];
    const tbod = tbl.children[1];
    const header = tbod.children[0] as any;
    header.childNodes.forEach(td => {
      if (td.colIndex === this.focusColumnIndex) {
        td.bgColor = 'blue';
      } else {
        td.bgColor = 'black';
      }
    });
  }

  onFocusCellChange(e: any) {
    this.focusColumnIndex = e.newColumnIndex;
    this.focusColumnHeader();
  }

  onColumnHeaderClick(e: any) {
    const parent = e.srcElement.parentNode;
    this.focusColumnIndex = parent.colIndex;
    this.focusColumnHeader();
  }

  onContentReadyAction(e: any) {
  }


  onContextMenuPreparing(e: any) {
    if (e.row.rowType === 'header') {

      //      const cannotAddLink = (this.canvasSelectedCount() === 0);
      console.dir(e);
      //      this.buildLinkItems(e.columnIndex);
      this.focusColumnIndex = e.columnIndex;
      this.focusColumnHeader();
      e.items =
        [
          {
            text: 'Column',
            items: [
              {
                text: 'Add',
                onItemClick: () => { this.AddColumn(e.columnIndex); }
              },
              {
                text: 'Rename',
                onItemClick: () => { this.RenameColumn(e.columnIndex); }
              },
              {
                text: 'Delete',
                onItemClick: () => { this.DeleteColumn(e.columnIndex); }
              }
            ]
          },
          {
            text: 'Link-Add',
            //            disabled: cannotAddLink,
            //          onItemClick: this.AddVarLink.bind(this)
          }
        ];
    }
    //    this.cdr.detectChanges();
  }



  columnModalRename(ev: any) {
    this.showColRename = false;
    if (this.colSelectIndex > -1) {
      this.frame.RenameColumn(this.colSelectIndex, this.newColumnName);
      this.UpdateView();
    }
    this.colSelectIndex = -1;
  }

  columnModalAdd(ev: any) {
    this.showColAdd = false;
    if (this.colSelectIndex > -1) {
      this.frame.AddColumn(this.colSelectIndex, this.newColumnName, VARTYPE.STRING, this.newColumnValue);
      this.UpdateView();
    }
    this.colSelectIndex = -1;
  }

  modalCancel(ev: any) {
    this.showLinkAdd = false;
    this.showColAdd = false;
    this.showColRename = false;
    this.colSelectIndex = -1;
  }



  AddColumn(index: number) {
    this.colSelectIndex = index;
    this.showColAdd = true;
  }

  RenameColumn(index: number) {
    this.colSelectIndex = index;
    this.newColumnName = this.GridColumns[index].caption;
    this.showColRename = true;
  }

  async DeleteColumn(index: number) {
    await this.frame.DeleteColumn(index);
    this.UpdateView();
  }

  async MoveColumn(fromindx: number, toindx: number) {
    await this.frame.MoveColumn(fromindx, toindx);
    this.UpdateView();
  }

  ToolBarAddColumn(e) {
    this.colSelectIndex = this.GridColumns.length;
    this.showColAdd = true;
  }

  async ToolBarAddRow(e) {
    await this.frame.AddRow();
    this.UpdateView();
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach((item) => {
      if (item.name === 'addRowButton') {
        item.options.onClick = this.ToolBarAddRow.bind(this);
      }
    });
    // Adds a new item
    toolbarItems.push({
      widget: 'dxButton',
      options: { hint: 'Add a column', icon: 'overflow', onClick: this.ToolBarAddColumn.bind(this) },
      location: 'after'
    });
  }

  public UpdateView() {
    this.gridStorageID = 'varGrid-' + this.frame.id;
    this.GetColums();
    this.FrameVarStore = this.frame.GetVarStore(false, -1);
  }

  public GetColums() {
    const vardefs: FrameVarDef[] = this.frame.GetVarDefs();
    const columns: any[] = [];
    let indx = 0;
    vardefs.forEach(def => {
      const colItem: ColumnInfo = {
        caption: def.Name,
        dataType: 'string',
        dataField: def.Name,
        name: 'column' + indx,
        varID: def.ID
      };
      indx++;
      columns.push(colItem);
    });
    this.GridColumns = columns;
  }

  public Load(frm: FrameClass) {
    this.frame = frm;
    this.UpdateView();
    this.onReorder = this.onReorder.bind(this);
  }


}
