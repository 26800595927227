import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewChecked } from '@angular/core';
import { FrameEditorComponent } from '../editor/frame-editor.component';
import { FrameClass } from '../frame-store.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { zComponent } from '../../../../scripts/zimext/zbuilder';



@Component({
  selector: 'app-frame-props',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './frame-props.component.html'
})
export class FramePropsComponent implements AfterViewChecked {
  @Input() frameEditor: FrameEditorComponent;

  startTime: number;
  updating = false;
  frame: FrameClass;
  selectedComp: zComponent;

  form = new FormGroup({});
  formProps: any;
  formFields: FormlyFieldConfig[] = [];

  constructor(public cdr: ChangeDetectorRef) {
  }

  ngAfterViewChecked() {
    //    console.log('VC=' + Date.now());
  }

  onSubmit(ev: any) {
    console.dir(this.formProps);
  }


  startWatch() {
    this.startTime = performance.now();
  }

  endWatch() {
    const endTime = Math.round((performance.now() - this.startTime) * 1000);
    console.log(endTime + 'us');
  }


  buildField(pname: string, pdesc: any) {

    const ztype =  pdesc.vtype ? pdesc.vtype : pdesc.ztype;
    const range = '';
    let itype: string;

    // view could be '#' or  '.fname#plabel' or '#plabel.fname'
    const indx = pdesc.view.indexOf('#');
    let stop = pdesc.view.indexOf('.');
    stop = (stop > indx) ? stop : pdesc.view.length;
    const lbl = pdesc.view.slice(indx + 1, stop);
    const plabel = lbl ? lbl : pname;

    const ftLookup = {
      zbool: 'checkbox',
      zint: 'number',
      zfloat: 'number',
      zfrac: 'number',
      zcoord: 'number',
      zstr: 'text',
      zlist: 'list',
      zrange: 'range',
      zcolrgb: 'colorPicker'
    };


    itype = ftLookup[ztype];

    switch (itype) {
      case 'text':
      case 'range':
      case 'checkbox':
      case 'number':
        this.formFields.push(
          {
            key: pname,
            type: 'input',
            wrappers: ['propPanel'],
            defaultValue: pdesc.default,
            templateOptions: {
              label: plabel,
              type: itype,
              min: pdesc.min,
              max: pdesc.max,
              step: pdesc.step,
              placeholder: '',
              required: false,
            }
          }
        );
        break;

     case 'list':
          // implement a custom template for a combo listbox
          break;

      case 'colorPicker':
        this.formFields.push(
          {
            key: pname,
            wrappers: ['propPanel'],
            type: itype,
            templateOptions: {
              label: plabel,
              type: itype,
              required: false,
            }
          }
        );
        break;
    }
  }



  private UpdateView(zcomp: zComponent, rewire: boolean) {

    this.updating = true;
    if (rewire) {
      zcomp.wireup();
    }

    this.formFields = [];
    if (zcomp) {
      for (const [pn, pdesc] of Object.entries(zcomp.props)) {
        if ((pn[0] === '$') && (pdesc.view)) {
          const indx = pdesc.view.indexOf('#');
          if (indx > -1) {
            const pname = pn.slice(1);
            if (rewire) {
              zcomp.wireup(pname, this.onPropChanged.bind(this));
            }
            this.buildField(pname, pdesc);
          }
        }
      }
    }
    this.formProps = zcomp.props;
    this.cdr.markForCheck();
    this.updating = false;
  }


  onPropChanged(key: string, value: any, dir: string) {
    if (value instanceof Function) {
      return;
    }
    if (dir === 'from') {
      if (!this.updating) {
        this.UpdateView(this.selectedComp, false);
      }
    }
  }


  public Select(zcomp?: zComponent) {
    if (zcomp) {
      this.selectedComp = zcomp;
    } else {
      this.selectedComp = this.frameEditor.zimFrame.component;
    }
    this.UpdateView(this.selectedComp, true);
  }


  // public Load(frm: FrameClass) {
  //   this.frame = frm;
  //   this.UpdateView();
  // }


}

