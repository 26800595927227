import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService, AVconfig } from 'src/app/shared/services/config.service';
import { AuthService } from 'src/app/shared/services/auth.service';



export class MediaSrc {
  constructor(public id: string, public label: string) {
  }
}



@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['..//styles/entry-dialogs.css']

})

export class ConfigComponent implements OnInit, AfterViewInit, OnDestroy {

  public avConfig: AVconfig = null;
  public audioSinks: MediaSrc[];
  public audioSources: MediaSrc[];
  public videoSources: MediaSrc[];
  public userDisplayName;

  constructor(public router: Router, public config: ConfigService, public auth: AuthService) {
    this.userDisplayName = auth.userDisplayName;
  }


  private async initSources() {
    this.audioSinks = await this.getAVSources('audiooutput');
    this.audioSources = await this.getAVSources('audioinput');
    this.videoSources = await this.getAVSources('videoinput');

    // video does not have a default id, like does audio, so we code the default choice to point to the first video device instead
    if (this.videoSources.length > 0) {
      if (this.avConfig.lmVideo[0] === 'default') {
        this.avConfig.lmVideo[0] = this.videoSources[0].id;
      }
      if (this.avConfig.lmVideo[1] === 'default') {
        this.avConfig.lmVideo[1] = this.videoSources[0].id;
      }
    } else {
      this.avConfig.lmVideo[0] = null;
      this.avConfig.lmVideo[1] = null;
    }

  }

  private async getAVSources(kind: MediaDeviceKind): Promise<MediaSrc[]> {
    const sources: MediaSrc[] = []; // = [new AVSource('0', '..none..')];

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      alert('Use of Media-Devices not supported in this browser.');
      return Promise.resolve(null);
    }

    const cons: MediaStreamConstraints = { audio: true, video: true };

    try {
      const stream = await navigator.mediaDevices.getUserMedia(cons);
      this.stopStreamTracks(stream);
    } catch (err) {
      /* handle the no-stream error */
      alert('No media of kind:' + kind + ' available to stream in this browser.');
      return Promise.resolve(null);
    }

    const kindDevices: MediaDeviceInfo[] = [];
    const devices = await navigator.mediaDevices.enumerateDevices();
    devices.forEach((device) => {
      if (device.kind === kind) {
        kindDevices.push(device);
      }
    });

    let defLabel: string = null;
    kindDevices.forEach((device) => {
      console.log(device);
      if (device.deviceId === 'default') {
        defLabel = '(auto)=> ' + device.label;
      }
      sources.push(new MediaSrc(device.deviceId, device.label));
    });
    if (kindDevices.length > 0) {
      if (!defLabel) { // as would be the case for video...without default
        defLabel = '(auto)=> ' + kindDevices[0].label;
      }
      sources.unshift(new MediaSrc('default', defLabel));
    }
    return Promise.resolve(sources);
  }

  private stopStreamTracks(stream: MediaStream) {
    if (stream) {
      stream.getTracks().forEach(track => (track.stop()));
    }
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.avConfig = this.config.AVConfig;
    this.initSources();
  }

  ngAfterViewInit(): void {
  }

  public async defaultConfig() {
    this.avConfig = this.config.defaultConfig();
    this.initSources();
  }

  public saveConfig() {
    this.config.AVConfig = this.avConfig;
    this.auth.userDisplayName = this.userDisplayName;
    this.router.navigate(['dashboard']);
  }

  enterLogin() {
    this.router.navigate(['dashboard']);
  }

}
