<div style="width: 100%; margin: 0 auto;">
    <ace-editor 
        #scriptEditor  
        [(text)] = 'scriptText'
        theme="monokai" 
        [options]="aceOptions"   
        mode="javascript" 
        style="min-height:100px; overflow: auto;" > 
    </ace-editor>
</div>
