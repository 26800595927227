<style>
  /* .custom-btns :not(:disabled):not(.disabled).active,
.custom-btns :not(:disabled):not(.disabled):active,
.custom-btns .show>.btn-primary.dropdown-toggle {
    color: #000;
    background-color: lightblue;
    border-color:lightblue;
} */


  .exit-btns.active {
    color: black;
    background-color: red;
    border-color: black;
    width: 40px;
  }

  .custom-btns {
    margin-left: 3px;
    width: 40px;
    height: fit-content;
  }

  .custom-btns.active {
    color: black;
    background-color:#17a2b8;
    border-color: black;
  }


  .custom-btns:not(.active) {
    color: white;
    background-color: #303030;
    border-color:black;
  }

  /* .custom-btns:hover.custom-btns:not(.active) {
    color: black;
  }

  .custom-btns :hover {
    color: white;
  } */

  .custom-btns.focus {
    box-shadow: 0 0 0 0 white;
  }


  .controls-toolbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  #tb-Row1 {
    margin: 0 auto;
  }

  #tb-Row2 {
    margin: 0 auto;
  }
</style>


<div class="controls-toolbar">

  <div id="tb-Row1">

    <!-- <button type="button" class="btn  btn-danger" style= "width:38px; height:38px; padding: 2px;"
            (click)="bnExitClassClick(false)"> Exit
    </button> -->

    <div class="btn-group btn-group-toggle">

      <button type="button"
              style="color:black; background-color: red;"
              class="btn btn-outline-dark"
              (click)="bnExitClassClick()">
        Exit
      </button>

      <!-- <label class="exit-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               (click) = "bnExitClassClick(false)"
               [(ngModel)]="ExitState">
        <fa-icon [icon]="bnExitIcon"></fa-icon>
      </label> -->

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [(ngModel)]="MicState">
        <fa-icon [icon]="bnMicIcon"
                 placement="bottom"
                 ngbTooltip="Mic On/Off"></fa-icon>
      </label>

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [(ngModel)]="CamState">
        <fa-icon [icon]="bnCamIcon"
                 placement="bottom"
                 ngbTooltip="Cam On/Off"></fa-icon>
      </label>

    </div>

  </div>


  <div id="tb-Row2">
    <div class="btn-group btn-group-toggle">

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [(ngModel)]="WBState">
        <fa-icon [icon]="bnWBIcon"
                 placement="bottom"
                 ngbTooltip="White-Board"></fa-icon>
      </label>

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [disabled]="!bnSCRenabled ? 'disabled': null"
               [(ngModel)]="SCRState">
        <fa-icon [icon]="bnSSIcon"
                 placement="bottom"
                 ngbTooltip="Screen-Share"></fa-icon>
      </label>

      <label class="custom-btns"
             ngbButtonLabel>
        <input type="checkbox"
               ngbButton
               [disabled]="!bnOBSenabled ? 'disabled': null"
               [(ngModel)]="OBState">
        <fa-icon [icon]="bnOBIcon"
                 placement="bottom"
                 ngbTooltip="Observe"></fa-icon>
      </label>

    </div>

  </div>


</div>
