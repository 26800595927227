<div style="background-color: aqua;">
<dx-tile-view  id="frameScroller" (onItemClick)="OnFrameSelect($event)" (onItemContextMenu)="OnItemContext($event)"  class="dx-tile-content" *ngIf ="frameSet != null"  direction="horizontal"  height="140px" [itemMargin]="6" [baseItemHeight]="128" [baseItemWidth]="128" width="100%">
  <dxi-item id="frameScrollerItem"
      *ngFor="let frameClass of frameSet.FrameClasses">
      <img src={{frameClass.ThumbNail}} width="128" height="128">
      <!-- <div class="image" [style.background-image]="frame.ThumbNail"></div> -->
  </dxi-item>
</dx-tile-view>
<dx-context-menu
    [dataSource]="cmItems"
    [width]="100"
    target="#frameScrollerItem"
    (onItemClick)="OnItemCM($event)">
</dx-context-menu>
</div>
