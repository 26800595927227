import { zim } from "../zimjs/zimjs.js";

class zMirror {
    constructor(ismaster) {
       this.isMaster = ismaster;
       this.propList = [];
       zim.addWires(this);
       window.ZMirror = this;
    }
 
    UpdateRemote(key, val) {
       console.log('def.zimMirror.Update.Remote: ' + key + '=' + val);
    }
 
    UpdateLocal(key, val, update) {
       this.updateLocal(key, val, update);
    }
 
    propIndx(pname) {
       var num = pname.split('_')[0];
       return parseInt(num);
    }
 
    propName(indx, prop) {
       return indx + '_' + prop;
    }
 
    getVal(pname) {
       return this.propList[this.propIndx(pname)].zval;
    }
 
    setVal(pname, value) {
       this.propList[this.propIndx(pname)].zval = value;
    }
 
    updateLocal(key, val, update) {
       var ple = this.propList[this.propIndx(key)];
       if (ple) {
          var prop = ple.zprop;
          ple.zobj[prop] = val;
          if (ple.zobj.stage) {
             if (update) ple.zobj.stage.update();
          }
       }
 
    }
 
    updateRemote(key) {
       var val = this.getVal(key);
       this.UpdateRemote(key, val);
    }
 
 
    addProp(obj, prop, flow) {
       var pname = this.propName(this.propList.length, prop);
       var mentry = { zobj: obj, zprop: prop, mprop: pname, zflow: flow, zval: obj[prop] };
       this.propList.push(mentry);
       Object.defineProperty(this, pname, {
          get: function () {
             return this.getVal(pname);
          },
          set: function (value) {
             this.setVal(pname, value);
          },
       });
       return pname;
    }
 
    reflect(zobj, prop, flow) {
       var pname = this.addProp(zobj, prop, flow);
       flow = flow.toUpperCase();
       if (this.isMaster) {
          if (flow == "BID") {
             zobj.wire(this, pname, true, false, null, () => { this.updateRemote(pname); }, prop);
          }
          if (flow == "M2S") {
             zobj.wire(this, pname, false, false, null, () => { this.updateRemote(pname); }, prop);
          }
          if (flow == "S2M") {
             zobj.wired(this, prop, false, false, null, null, pname);
          }
       }
       else {
          if (flow == "BID") {
             zobj.wire(this, pname, true, false, null, () => { this.updateRemote(pname); }, prop);
          }
          if (flow == "M2S") {
             zobj.wired(this, prop, false, false, null, null, pname);
          }
          if (flow == "S2M") {
             zobj.wire(this, pname, false, false, null, () => { this.updateRemote(pname); }, prop);
          }
       }
    }
 
    reflects(zobj, props, flow) {
       if (Array.isArray(props)) {
          props.forEach(prop => {
             this.reflect(zobj, prop, flow);
          });
       } else {
          this.reflect(zobj, props, flow);
       }
    }
 }
 
let ZMirror = window.ZMirror;
export { ZMirror, zMirror };
