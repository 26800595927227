<style>
  .btn {
    width:120px;
    margin:6px;
    margin-left:10px;
  }

  .btn[disabled] {
    background-color: transparent;
  }

  .sidebar{
    width:auto;
    background-color: white;
  }

  .sidebar-sticky{
    width:auto;
    background-color: transparent;
  }
</style>

<app-bg-banner></app-bg-banner>


<div class="container-fluid"
     style="margin-top:65px;">
  <div class="row">

    <nav class="sidebar">

      <div class="sidebar-sticky">

        <ul class="nav flex-column">

          <div class="formGroup">
            <input type="button"
                   class="btn btn-info"
                   value="Configure"
                   (click)="this.navConfig()">
          </div>

          <div class="formGroup">
            <input type="button"
                   id="bnEnterClass"
                   class ="btn btn-warning"
                   [attr.disabled] = "canEnterClassAttr"
                   value= {{this.bnEnterClassText}}
                   (click)="this.enterClass()">
          </div>

          <div class="formGroup">
            <input type="button"
                   class="btn btn-primary"
                   value="Frame Builder"
                   (click)="this.navFrameBuilder()">
          </div>

          <!-- <div class="formGroup">
            <input type="button"
                   class="btn btn-primary"
                   value="ZimJS Viewer"
                   (click)="this.zimJSView()">
          </div> -->

          <div class="formGroup">
            <input type="button"
                   class="btn btn-danger"
                   value="Log Out"
                   (click)="this.navSignOut()">
          </div>

        </ul>
      </div>
    </nav>


    <!-- Main content -->
    <main role="main"
          class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <div class="inner-adjust">

        <div class="pt-3 pb-2 mb-3 border-bottom">
          <h1 class="h2">User Profile</h1>
        </div>
        <!-- Show user data when logged in -->
        <div class="row"
             *ngIf="auth.userData as user">
          <div class="col-md-12">
            <div class="media">
              <img class="align-self-start mr-5 img-thumbnail rounded-circle"
                   src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}"
                   alt="{{user.displayName}}">
              <div class="media-body">
                <h1>Welcome back <strong>{{(user.displayName) ? user.displayName : '...'}}</strong></h1>
                <p>User ID: <strong>{{user.uid}}</strong></p>
                <p>Email: <strong>{{user.email}}</strong></p>
                <!-- <p>Email Verified: <strong>{{user.emailVerified}}</strong></p> -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>

  </div>
</div>
