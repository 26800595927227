import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FrameEditorComponent } from './frame-editor.component';
// import {
//   drawMode, HLineDrawTool, IDrawTool, LabelDrawTool, LineDrawTool, OvalDrawTool, PolylineDrawTool,
//   RectDrawTool, TextDrawTool, TriangleDrawTool, VLineDrawTool, ITextTool
// } from './draw-tools';
import { ToolbarPanel } from './toolbar/toolbar-panel.component';
import { ToolbarItem } from './toolbar/toolbar-item.component';
import { zFrame, zMirror, zComponent, zBuilder } from '../../../../scripts/zimext/zbuilder';
// import { zFrame, zRectangle, zCircle, zTriangle, zLine, zBlob, zSquiggle, zLabel, zText, zPolygon } from '../../../../scripts/zimext/zbuilder';

export enum drawMode {
  SELECT,
  LINE,
  HLINE,
  VLINE,
  POLYLINE,
  TABLE,
  zRectangle, zCircle, zTriangle, zLine, zBlob, zSquiggle, zLabel, zText, zPolygon,
  SVGPAGE,
  IMAGE,
  AGIF,
  VIDEO,
  SOUND,
}


enum textAlign {
  LEFT,
  RIGHT,
  CENTER,
  JUSTIFIED,
}

class MenuItem {
  id: string;
  name: string;
  onChange?: any;
  value?: any;
  icon?: string;
  disabled?: boolean;
  items?: MenuItem[];
}

export interface FrameProps {
  pageWidth?: number;
  pageHeight?: number;
  pageCols?: number;
  pageRows?: number;
  pageShow?: boolean;
  gridOn?: boolean;
  gridSnapX?: boolean;
  gridSnapY?: boolean;
  gridSize?: number;
}


@Component({
  selector: 'app-frame-editor-toolbar',
  templateUrl: './frame-editor-toolbar.component.html'
})



export class FEToolbarComponent implements AfterViewInit {

  @Input() frameEditor: FrameEditorComponent;

  json: string;
  params: any;
  document: HTMLDocument;

  private zimBuilder: zBuilder;
  private loading = true;

  parentDivWidth = 800;
  parentDivHeight = 600;

  private menuValues = null;
  private panelStates = {};

  frameProps: FrameProps;

  toolMode: drawMode = drawMode.SELECT;
  selectedComp: zComponent = null;

  FillColor: string;
  StrokeColor: string;
  BGColor: string;
  ddButtonStroke: any;
  ddButtonBG: any;
  ddButtonFill: any;
  public colors = [null, '#980000', '#ff0000', '#ff9900', '#ffff00', '#00ff00', '#00ffff', '#4a86e8', '#0000ff', '#9900ff', '#ff00ff', '#ff3466'];

  alignment: string;
  compEditMode = false;

  zoom = 1.0;
  zoomStep = 0.1;
  zoomMin = 0.1;
  zoomMax = 5;
  panning = false;
  shiftDown = false;

  showCtxMenu = false;

  public editCmds: ToolbarItem =
    {
      type: 'CMDBUTTONS', name: '*',
      items: [
        { disabled: true, name: 'Cut', id: 'cut', icon: 'cut', callBack: this.CutClick.bind(this) },
        { disabled: true, name: 'Copy', id: 'copy', icon: 'copy', callBack: this.CopyClick.bind(this) },
        { disabled: true, name: 'Paste', id: 'pase', icon: 'paste', callBack: this.PasteClick.bind(this) },
        { disabled: true, name: 'Dupl', id: 'dup', icon: 'paste', callBack: this.CutClick.bind(this) },
        { disabled: true, name: 'Repeat', id: 'rep', icon: 'paste', callBack: this.CutClick.bind(this) },
        { disabled: true, name: 'Undo', id: 'undo', icon: 'undo', callBack: this.UndoClick.bind(this) },
        { disabled: true, name: 'Redo', id: 'redo', icon: 'redo', callBack: this.RedoClick.bind(this) },
      ]
    };
  public editPanel: ToolbarPanel = { host: this, name: 'Edit', items: [this.editCmds] };

  public frameCmds: ToolbarItem = {
    type: 'CMDBUTTONS', name: '*',
    items: [
      { name: 'Save', id: 'save', icon: 'save', callBack: this.SaveClick.bind(this) },
      { disabled: true, name: 'Print', id: 'print', icon: 'print', callBack: this.PrintClick.bind(this) },
      { name: 'Exp', id: 'export', icon: 'export', callBack: this.ExportClick.bind(this) },
      { name: 'Imp', id: 'import', icon: 'upload', callBack: this.ImportClick.bind(this) },
      { name: 'Clr', id: 'clear', icon: 'clear', callBack: this.ClearClick.bind(this) },
    ]
  };
  public framePanel: ToolbarPanel = { host: this, name: 'Frame', items: [this.frameCmds] };

  public lineTools: ToolbarItem = {
    type: 'SELECT', name: 'Line', id: 'placeLine', width: '100px',
    items: [
      { name: 'P2P', icon: 'edit', value: drawMode.zLine },
      { name: 'Horz', icon: 'plus', value: drawMode.HLINE },
      { name: 'Vert', icon: 'plus', value: drawMode.VLINE },
      { name: 'Poly', icon: 'greater', value: drawMode.zSquiggle },
    ]
  };

  public textTools: ToolbarItem = {
    type: 'SELECT', name: 'Text', id: 'placeText', width: '100px',
    items: [
      { name: 'Label', icon: 'bold', value: drawMode.zLabel },
      { name: 'Para', icon: 'startswith', value: drawMode.zText },
      { disabled: true, name: 'Table', icon: 'smalliconslayout', value: drawMode.TABLE },
    ]
  };

  public shapeTools: ToolbarItem = {
    type: 'SELECT', name: 'Shape', id: 'placeShape', width: '100px',
    items: [
      { name: 'Rect', icon: 'repeat', value: drawMode.zRectangle },
      { name: 'Oval', icon: 'isblank', value: drawMode.zCircle },
      { name: 'Tri', icon: 'less', value: drawMode.zTriangle },
      { name: 'Polygon', icon: 'favorites', value: drawMode.zPolygon },
      { name: 'Blob', icon: 'favorites', value: drawMode.zBlob },
    ]
  };
  public toolsPanel: ToolbarPanel = {
    host: this, name: 'Tools',
    items: [this.lineTools, this.textTools, this.shapeTools],
    callBack: this.setPlaceMode.bind(this)
  };


  // $textAlign: zpa(zlist, { view: showTBR, mid:'textAlign', items: ['left', 'center', 'right', 'justified'] }),
  // $color: zpa(zcolrgb, { view: showTBR, mid:'fillColor', label: 'fil-col' }),
  // $borderColor: zpa(zcolrgb, { view: showTBR, mid:'penColor', label: 'brd-col' }),
  // $borderWidth: zpa(zint, { view: showPED, mid:'penWidth', label: 'brd-width' }),

  // **** Comp & Tool menu items  */

  public penProps: ToolbarItem[] = [
    {
      type: 'COLOR', name: 'Pen', id: 'penColor', width: '24px',
      value: '#808080', position: 'bottom', offsetX: '-10px'
    },
    {
      type: 'NUMBER', name: 'Width', width: '60px', id: 'penWidth', value: 0, step: 1, max: 200, min: 0
    },
    {
      type: 'SELECT', name: 'Pattern', width: '100px', id: 'penPattern',
      items: [
        { name: 'Solid', value: [0] },
        { name: 'Dots', value: [3, 3] },
        { name: 'Dashed', value: [10, 3] },
        { name: 'DotDash', value: [3, 3, 10, 3] },
      ]
    },
  ];
  public penPropPanel: ToolbarPanel = { host: this, name: 'Pen', items: this.penProps, callBack: this.menuChoice.bind(this) };


  public fillProps: ToolbarItem[] = [
    {
      type: 'COLOR', name: 'Fill', id: 'fillFGColor', width: '24px',
      value: '#808080', position: 'bottom', offsetX: '-10px'
    },
    {
      type: 'COLOR', name: 'BGnd', id: 'fillBGColor', width: '24px',
      value: '#808080', position: 'bottom', offsetX: '-150px'
    },
    {
      type: 'SELECT', name: 'Pattern', width: '100px', id: 'fillPattern',
      items: [
        { name: 'Solid', value: [0] },
        { name: 'Dots', value: [3, 3] },
        { name: 'Dashed', value: [10, 3] },
        { name: 'DotDash', value: [3, 3, 10, 3] },
      ]
    },
  ];
  public fillPropPanel: ToolbarPanel = { host: this, name: 'Fill', items: this.fillProps, callBack: this.menuChoice.bind(this) };



  public textProps: ToolbarItem[] = [{
    type: 'SELECT', name: 'Align', width: '110px', id: 'textAlign',
    items: [
      { name: 'Left', icon: 'alignleft', value: 'left' },
      { name: 'Right', icon: 'alignright', value: 'right' },
      { name: 'Center', icon: 'aligncenter', value: 'center' },
      { name: 'Justify', icon: 'alignjustify', value: 'justify' },
    ]
  },
  {
    type: 'NUMBER', name: 'Weight', width: '80px', id: 'fontWeight',
    value: 400, step: 100, max: 900, min: 100
  },
  {
    type: 'NUMBER', name: 'Size(px)', width: '70px', id: 'fontSize',
    value: 14, step: 2, max: 100, min: 8
  },
  {
    type: 'SELECT', name: 'Family', width: '100px', id: 'fontFamily',
    items: [
      { name: 'Times Roman', value: 'Times Roman' },
      { name: 'Arial', value: 'Arial' },
    ]
  },
  {
    type: 'SELECT', name: 'Style', width: '110px', id: 'fontStyle',
    items: [
      { name: 'Default', value: 'default' },
      { name: 'Normal', value: 'normal' },
      { name: 'Italic', value: 'italic' },
      { name: 'Oblique', value: 'oblique' }
    ]
  },
  {
    type: 'NUMBER', name: 'SPC-em/1000', width: '80px', id: 'charSpacing',
    value: 0, step: 50, max: 2000, min: 0
  },
  {
    type: 'NUMBER', name: 'Line Height', width: '60px', id: 'lineHeight',
    value: 0, step: 0.1, max: 2.0, min: 0.0
  },
  ];
  public textPropPanel: ToolbarPanel = { host: this, name: 'Text', items: this.textProps, callBack: this.SetTextProp.bind(this) };


  public zoomProps: ToolbarItem[] = [{
    type: 'BUTTON', name: 'All', id: 'zoomAll', width: '24px',
    icon: 'fullscreen', callBack: this.ZoomAll.bind(this)
  },
  {
    type: 'NUMBER', name: 'Level %', width: '70px', id: 'zoomLevel',
    value: this.zoom * 100, step: this.zoomStep * 100, max: this.zoomMax * 100, min: this.zoomMin * 100, callBack: this.SetZoom.bind(this)
  },
  ];
  public zoomPropPanel: ToolbarPanel = { host: this, name: 'Zoom', items: this.zoomProps };

  public gridProps: ToolbarItem[] = [
    {
      type: 'BTNGROUP', name: 'Grid', width: '120px', id: 'gridSelect', callBack: this.SetPagePropToggle.bind(this),
      items: [
        { name: 'ON', id: 'gridOn', icon: 'smalliconslayout' },
        { name: 'SnapX', id: 'gridSnapX', icon: 'more' },
        { name: 'SnapY', id: 'gridSnapY', icon: 'overflow' },
      ]
    },
    {
      type: 'NUMBER', name: 'Size', width: '60px', id: 'gridSize',
      value: 20, step: 10, max: 50.0, min: 10,
    },
  ];
  public gridPropPanel: ToolbarPanel = { host: this, name: 'Grid', items: this.gridProps, callBack: this.SetGridSize.bind(this) };


  public pageProps: ToolbarItem[] = [
    {
      type: 'COLOR', name: 'B-GND', id: 'pageColor', width: '24px',
      value: '#808080', position: 'bottom', offsetX: '-10px'
    },
    {
      type: 'NUMBER', name: 'Cols', width: '50px', id: 'pageCols',
      value: 1, step: 1, max: 9, min: 1
    },
    {
      type: 'NUMBER', name: 'Rows', width: '50px', id: 'pageRows',
      value: 1, step: 1, max: 9, min: 1
    },
    {
      type: 'BTNGROUP', name: 'Page', id: 'pageShowSelect', width: '40px', callBack: this.SetPagePropToggle.bind(this),
      items: [{ name: 'Show', id: 'pageShow', icon: 'smalliconslayout', value: 'true' }]
    },
    {
      type: 'NUMBER', name: 'SizeX', width: '75px', id: 'pageWidth',
      value: 1000, step: 100, max: 3000, min: 100,
    },
    {
      type: 'NUMBER', name: 'SizeY', width: '75px', id: 'pageHeight',
      value: 750, step: 100, max: 3000, min: 100,
    },
    {
      type: 'CMDBUTTONS', name: '*', items: [
        { name: 'Image', id: 'pageImage', icon: 'image' }
      ]
    },

  ];
  public pagePanel: ToolbarPanel = { host: this, name: 'Page', items: this.pageProps, callBack: this.SetPagePropNumbers.bind(this) };

  public tbPanels = [this.framePanel, this.editPanel, this.toolsPanel, this.penPropPanel, this.fillPropPanel,
  this.textPropPanel, this.gridPropPanel, this.zoomPropPanel, this.pagePanel];

  // canvas.setZoom(val);
  // canvas.setWidth(originalWidth * canvas.getZoom());
  // canvas.setHeight(originalHeight * canvas.getZoom());
  // public menuModes: MenuItem[] = [
  //   {
  //     id: '6', name: 'Import', icon: 'upload', onChange: this.ImportClick.bind(this),
  //     items: [
  //       { id: '6_1', name: 'Page', icon: 'file', value: drawMode.SVGPAGE, onChange: this.setPlaceMode },
  //       { id: '6_2', name: 'Image', icon: 'card', value: drawMode.IMAGE, onChange: this.setPlaceMode },
  //       { id: '6_3', name: 'GIF', icon: 'columnfield', value: drawMode.AGIF, onChange: this.setPlaceMode },
  //       { id: '6_4', name: 'Video', icon: 'video', value: drawMode.VIDEO, onChange: this.setPlaceMode },
  //       { id: '6_5', name: 'Audio', icon: 'tips', value: drawMode.SOUND, onChange: this.setPlaceMode },
  //     ]
  //   }
  // ];

  // static charSpacing: number;




  ctxMenuItems: MenuItem[];
  canvCtxMenuItems: MenuItem[] = [
    {
      id: '1', name: 'Page',
      items: [
        { id: '1_1', name: 'Save', icon: 'save', onChange: this.SaveClick.bind(this) },
        { id: '1_2', name: 'Clear', icon: 'clear', onChange: this.ClearClick.bind(this) },
        { id: '1_3', name: 'Export', icon: 'export', onChange: this.ExportClick.bind(this) },

      ]
    },
    // {
    //   id: '2', name: 'Grid',
    //   items: [
    //     {
    //       id: '2_1', name: 'On',
    //       items: [
    //         { id: '2_1_1', name: '5px', value: 5, onChange: this.gridChange },
    //         { id: '2_1_2', name: '10px', value: 10, onChange: this.gridChange },
    //         { id: '2_1_3', name: '25px', value: 25, onChange: this.gridChange },
    //         { id: '2_1_4', name: '50px', value: 50, onChange: this.gridChange },
    //         { id: '2_1_5', name: '100px', value: 100, onChange: this.gridChange }
    //       ]
    //     },
    //     { id: '2_2', name: 'Off', value: 0, onChange: this.gridChange },
    //     {
    //       id: '2_3', name: 'Snap-X',
    //       items: [
    //         { id: '2_3_1', name: 'Left', value: 'left', onChange: this.gridSnapX },
    //         { id: '2_3_2', name: 'Center', value: 'center', onChange: this.gridSnapX },
    //         { id: '2_3_3', name: 'Right', value: 'right', onChange: this.gridSnapX }
    //       ]
    //     },
    //     {
    //       id: '2_4', name: 'Snap-Y',
    //       items: [
    //         { id: '2_4_1', name: 'Top', value: 'top', onChange: this.gridSnapY },
    //         { id: '2_4_2', name: 'Middle', value: 'middle', onChange: this.gridSnapY },
    //         { id: '2_4_3', name: 'Bottom', value: 'bottom', onChange: this.gridSnapY }
    //       ]
    //     },
    //   ]
    // },

  ];

  compCtxMenuItems: MenuItem[] = [
    { id: '0', name: 'Duplicate', value: 'Insert', onChange: this.duplicateComp },
    { id: '1', name: 'Delete', value: 'Delete', onChange: this.deleteComp },
    {
      id: '2', name: 'Order',
      items: [
        { id: '2_1', name: 'to Front', value: 'Home', onChange: this.orderComp },
        { id: '2_2', name: 'to Back', value: 'End', onChange: this.orderComp },
        { id: '2_3', name: 'Forward', value: 'PageUp', onChange: this.orderComp },
        { id: '2_4', name: 'Backward', value: 'PageDown', onChange: this.orderComp }
      ]
    },
    {
      id: '3', name: 'Align-X', disabled: true,
      items: [
        { id: '2_1', name: 'Left', value: 'left', onChange: this.alignCompX },
        { id: '2_2', name: 'Center', value: 'center', onChange: this.alignCompX },
        { id: '2_3', name: 'Right', value: 'right', onChange: this.alignCompX }
      ]
    },
    {
      id: '4', name: 'Align-Y', disabled: true,
      items: [
        { id: '4_1', name: 'Top', value: 'top', onChange: this.alignCompY },
        { id: '4_2', name: 'Middle', value: 'middle', onChange: this.alignCompY },
        { id: '4_3', name: 'Bottom', value: 'bottom', onChange: this.alignCompY }
      ]
    },
    {
      id: '5', name: 'Row', icon: 'more', disabled: true
    },
    {
      id: '6', name: 'Column', icon: 'overflow', disabled: true,
    }
  ];



  constructor(public cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.loadMenuValues();
    this.loadPanelState();
  }

  private fitFrame() {

    // const W = this.parentDivWidth;
    // const H = this.parentDivHeight;

    // let w = W;
    // let h = H;
    // let zoom = 1.0;

    // const refAR = this.frameProps.pageWidth / this.frameProps.pageHeight;
    // const AR = W / H;
    // if (AR > refAR) {
    //   h = H;
    //   w = Math.round(h * refAR);
    // } else {
    //   w = W;
    //   h = Math.round(W / refAR);
    // }
    // zoom = w / this.frameProps.pageWidth;
    // this.canvas.setHeight(h);
    // this.canvas.setWidth(w);
    // this.canvas.setZoom(zoom);
    // this.drawGrid();
  }

  public onResized(ev: any) {
    this.parentDivWidth = ev.newWidth;
    this.parentDivHeight = ev.newHeight;
    //    this.fitCanvasFrame();
  }


  setMenuProp(id: string, value: any) {
    for (const panel of this.tbPanels) {
      const itm = panel.items.find(item => item.id === id);
      if (itm) {
        itm.update(value);
        return;
      }
    }
  }

  getMenuProp(id: string): any {
    for (const panel of this.tbPanels) {
      const itm = panel.items.find(item => item.id === id);
      if (itm) {
        return itm.value;
      }
    }
    return null;
  }

  UpdateZoom(value: number) {
    this.setMenuProp('zoomLevel', value * 100);
  }

  SetZoom(id: any, value: any, index: number) {
    this.zoomTo(value / 100);
  }

  SetGridSize(id: any, value: any, index: number) {

  }


  UpdatePageProps() {
    // this.setMenuProp('pageCols', this.frameProps.pageCols);
    // this.setMenuProp('pageRows', this.frameProps.pageRows);
    // this.setMenuProp('pageShow', this.frameProps.pageShow);
    // this.setMenuProp('pageWidth', this.frameProps.pageWidth);
    // this.setMenuProp('pageHeight', this.frameProps.pageHeight);
  }

  SetPagePropNumbers(id: any, value: any, index: number) {
    console.log(id + '=' + value);
    switch (id) {

      case 'pageShow':
        this.frameProps.pageShow = value;
        break;

      case 'pageRows':
        this.frameProps.pageRows = value;
        this.drawGrid();
        break;

      case 'pageCols':
        this.frameProps.pageCols = value;
        this.drawGrid();
        break;

      case 'pageWidth':
        this.frameProps.pageWidth = value;
        this.fitFrame();
        this.drawGrid();
        break;

      case 'pageHeight':
        this.frameProps.pageHeight = value;
        this.fitFrame();
        this.drawGrid();
        break;

      case 'pageColor':
        this.saveMenuChoice(id, value);
        //        this.frame.backgroundColor = value;
        //        this.framecanvas.renderAll();
        break;

      case 'pageImage':
        break;
    }


  }

  ZoomAll(id: any, value: any) {
  }


  GridUpdate() {
    this.setMenuProp('gridOn', this.frameProps.gridOn);
    this.setMenuProp('SnapX', this.frameProps.gridSnapX);
    this.setMenuProp('SnapY', this.frameProps.gridSnapY);
  }

  SetPagePropToggle(id: any, select: any, index: number) {

    this.frameProps.pageShow = select.addedItems.some(item => item.id === 'pageShow') ? true : this.frameProps.pageShow;
    this.frameProps.pageShow = select.removedItems.some(item => item.id === 'pageShow') ? false : this.frameProps.pageShow;

    this.frameProps.gridOn = select.addedItems.some(item => item.id === 'gridOn') ? true : this.frameProps.gridOn;
    this.frameProps.gridOn = select.removedItems.some(item => item.id === 'gridOn') ? false : this.frameProps.gridOn;
    this.SetGrid(this.frameProps.gridOn);

    this.frameProps.gridSnapX = select.addedItems.some(item => item.id === 'gridSnapX') ? true : this.frameProps.gridSnapX;
    this.frameProps.gridSnapX = select.removedItems.some(item => item.id === 'gridSnapX') ? false : this.frameProps.gridSnapX;

    this.frameProps.gridSnapY = select.addedItems.some(item => item.id === 'gridSnapY') ? true : this.frameProps.gridSnapY;
    this.frameProps.gridSnapY = select.removedItems.some(item => item.id === 'gridSnapY') ? false : this.frameProps.gridSnapY;
    console.dir(this.frameProps);
  }


  // gridSnapY(val: any) {
  //   this.frameProps.gridSnapY = val;
  // }

  // gridSnapX(val: any) {
  //   this.frameProps.gridSnapX = val;
  // }

  duplicateComp(val: any) {
    this.changeComponents(val);
  }

  deleteComp(val: any) {
    this.changeComponents(val);
  }

  orderComp(val: any) {
    this.changeComponents(val);
  }

  alignCompY(val: any) {
    // const countSelected = this.canvas.getActiveObjects().length;
    // const refObj = this.firstSelected;
    // if ((countSelected > 1) && (refObj)) {
    //   this.canvas.getActiveObjects().forEach((obj) => {
    //     if (obj !== refObj) {
    //       let refY: number;
    //       switch (val) {
    //         case 'top':
    //           refY = refObj.top;
    //           break;
    //         case 'bottom':
    //           refY = refObj.top + (refObj.getScaledHeight() - obj.getScaledHeight());
    //           break;
    //         case 'middle':
    //           refY = refObj.top + (refObj.getScaledHeight() - obj.getScaledHeight()) / 2;
    //           break;
    //       }
    //       obj.top = refY;
    //     }
    //   });
    // }
    // this.canvas.renderAll();
  }

  alignCompX(val: any) {
    // const countSelected = this.canvas.getActiveObjects().length;
    // const refObj = this.firstSelected;
    // if ((countSelected > 1) && (refObj)) {
    //   this.canvas.getActiveObjects().forEach((obj) => {
    //     if (obj !== refObj) {
    //       let refX: number;
    //       switch (val) {
    //         case 'left':
    //           refX = refObj.left;
    //           break;
    //         case 'right':
    //           refX = refObj.left + (refObj.getScaledWidth() - obj.getScaledWidth());
    //           break;
    //         case 'center':
    //           refX = refObj.left + (refObj.getScaledWidth() - obj.getScaledWidth()) / 2;
    //           break;
    //       }
    //       obj.left = refX;
    //     }
    //   });
    // }
    // this.canvas.renderAll();
  }


  gridChange(val: any) {
    // todo
  }

  ctxMenuClick(ev: any) {
    this.showCtxMenu = false;
    this.menuClick(ev);
  }


  makeCtxMenu() {

    // const someSelected = this.canvas.getActiveObjects().length > 0;
    // const multiSelected = this.canvas.getActiveObjects().length > 1;
    // this.compCtxMenuItems[3].disabled = !multiSelected;
    // this.compCtxMenuItems[4].disabled = !multiSelected;
    // this.ctxMenuItems = someSelected ? this.compCtxMenuItems : this.canvCtxMenuItems;
    // this.showCtxMenu = true;
  }


  selectTool(toolMode: drawMode) {
    // const indx = this.tools.findIndex((tool) => tool.toolMode === toolMode);
    // if (indx > -1) {
    //   this.tool = this.tools[indx];
    // } else {
    //   this.tool = null;
    // }



  }

  // public GetSelectedObject(kind: any[]): fabric.Object[] {
  //   // const res: any[] = [];
  //   // kind.forEach((fot) => {
  //   //   this.canvas.getActiveObjects().forEach((obj) => {
  //   //     if (obj instanceof fot) {
  //   //       res.push(obj);
  //   //     }
  //   //   });
  //   // });
  //   // return res;
  // }


  public SetTextProp(prop: string, value: any, index: any, isSelect?: boolean) {

    // save menu selection
    // todo -- check for compEditMode here

    if (isSelect !== undefined) {
      this.saveMenuChoice(prop, index);
    } else {
      this.saveMenuChoice(prop, value);
    }

    return;

    // if (types === undefined) {
    //   types = [fabric.Text, fabric.IText];
    // }
    // const textObjects = this.GetSelectedObject(types);
    // if (textObjects.length > 0) {
    //   textObjects.forEach(to => {
    //     to[prop] = value;
    //     to.dirty = true;
    //   });
    //   this.canvas.renderAll();
    // } else {
    //   ITextTool[prop] = value;
    // }
  }



  public menuClick(ev: any) {
    if (ev.itemData.onChange) {
      if (ev.itemData.value) {
        ev.itemData.onChange.call(this, ev.itemData.value);
      } else {
        ev.itemData.onChange.call(this, ev.itemData.name);
      }
    }
  }




  public onRefreshClick(ev: any) {

  }

  public Disable() {
    this.zimBuilder = null;
  }


  public Enable(zb: zBuilder, doc: HTMLDocument, params: any) {
    this.zimBuilder = zb;
    this.document = doc;
    this.params = params;

    // this.canvas = this.frameEditor.canvas;
    // this.aCanvas = this.canvas as any;
    // if (this.aCanvas.props) {
    //   this.frameProps = this.aCanvas.props;
    // } else {
    //   this.frameProps = {
    //     pageShow: false,
    //     pageCols: 1,
    //     pageRows: 1,
    //     pageWidth: 1000,
    //     pageHeight: 750,
    //     gridOn: true,
    //     gridSize: 50
    //   };
    //   this.aCanvas.props = this.frameProps;
    // }
    // const uc = (document.getElementsByClassName('upper-canvas frame_canvas_class')[0]) as HTMLElement;
    // fabric.util.addListener(uc, 'contextmenu', (e) => {
    //   this.makeCtxMenu();
    //   e.preventDefault();
    // });
    // this.parentDivWidth = W;
    // this.parentDivHeight = H;
    // this.fitCanvasFrame();
  }


  // Method which allows any drawer to Promise their make() function
  // private async make(x: number, y: number): Promise<fabric.Object> {
  // if (this.tool) {
  //   return await this.tool.make(x, y, this.toolOptions);
  // }
  // return null;
  // }

  public onMouseUp(evt: any) {
    this.panning = false;
    // if (this.isDown) {
    //   this.canvas.discardActiveObject();
    //   this.firstSelected = null;
    // }
    // this.isDown = false;
    // this.canvas.renderAll();
  }

  public onKeyUp(evt: any) {
    if (this.shiftDown) {
      // this.canvas.defaultCursor = 'default';
      // this.canvas.selection = true;
    }
    this.shiftDown = false;
  }

  public onKeyDown(evt: any) {
    this.shiftDown = evt.shiftKey;
    // if (this.shiftDown) {
    //   this.canvas.defaultCursor = 'move';
    //   this.canvas.selection = false;
    // } else {
    //   if (evt.key === 'Escape') {
    //     this.PlaceMode = drawMode.SELECT;
    //     this.canvas.discardActiveObject();
    //     this.firstSelected = null;
    //     this.canvas.renderAll();
    //     return;
    //   } else {
    //     this.changeComponents(evt.key);
    //   }
    // }
  }

  onWheel(evt: any) {
    if (this.shiftDown) {
      const delta = evt.wheelDelta;
      if (delta !== 0) {
        //    const point =  new fabric.Point(evt.offsetX, evt.offsetY);
        //   if (delta > 0) {
        //     this.zoomIn(point);
        //   } else if (delta < 0) {
        //     this.zoomOut(point);
        //   }
      }
    }
  }


  private mouseMove(x: number, y: number): any {
    // if (!this.isDown) {
    //   return; // If the user isn't holding the mouse button, do nothing
    // }
    // if (this.tool) {
    //   this.tool.resize(this.fabObject, x, y);
    //   this.canvas.renderAll();
    // }
  }

  onMouseMove(evt: any) {
    if (this.panning && evt && evt.e) {
      // const delta = new fabric.Point(evt.e.movementX, evt.e.movementY);
      //   this.canvas.relativePan(delta);
      // } else {
      //   const pointer = this.canvas.getPointer(evt.e);
      //   this.mouseMove(pointer.x, pointer.y);
    }
  }

  private async mouseDown(x: number, y: number): Promise<any> {
    // this.isDown = true; // The mouse is being clicked

    // this.fabObject = await this.make(x, y);
    // if (this.fabObject) {
    //   this.canvas.add(this.fabObject);
    //   this.canvas.renderAll();
    // }
  }


  zoomIn(point) {
    // if (this.zoom < this.zoomMax) {
    //   this.zoom += this.zoomStep;
    //   this.canvas.zoomToPoint(point, this.zoom);
    //   this.UpdateZoom(this.zoom);
    // }
  }

  zoomOut(point) {
    // if (this.zoom > this.zoomMin) {
    //   this.zoom -= this.zoomStep;
    //   this.canvas.zoomToPoint(point, this.zoom);
    //   this.UpdateZoom(this.zoom);
    // }
  }

  zoomTo(zoomLevel: number) {
    if ((zoomLevel >= this.zoomMin) && (zoomLevel <= this.zoomMax)) {
      this.zoom = zoomLevel;
      // const point = {}; //new fabric.Point(this.canvas.getWidth() / 2, this.canvas.getHeight() / 2);
      // this.canvas.zoomToPoint(point, this.zoom);
    }
  }


  private s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
  private GuidV4() {
    return (this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4());
  }

  private makeIdentity(fobj: fabric.Object, otype: string) {
    // const objs = this.canvas.getObjects() as fabric.Object[];
    // let count = 0;
    // let name;
    // let indx = 0;
    // do {
    //   name = otype + '#' + count;
    //   indx = objs.findIndex(obj => obj.name === name);
    //   count++;
    // } while (indx !== -1);
    // fobj.name = name;
    // fobj.data = this.GuidV4();
  }

  objectAdded(fobj: fabric.Object) {
    // const count = this.canvas.getObjects().length;
    // if (fobj.data === undefined) {
    //   let otype = fobj.type;
    //   if (otype === undefined) {
    //     otype = 'path';
    //   }
    //   this.makeIdentity(fobj, otype);
    // }
  }

  cloned(obj: fabric.Object) {
    // this.canvas.add(obj);
    // this.canvas.renderAll();
  }

  changeComponents(cmd: any) {
    // this.canvas.getActiveObjects().forEach((obj) => {
    //   switch (cmd) {
    //     case 'Delete':
    //       this.canvas.remove(obj);
    //       break;

    //     case 'Insert':
    //       obj.clone(this.cloned.bind(this));
    //       break;

    //     case 'PageUp':
    //       this.canvas.bringForward(obj, true);
    //       break;

    //     case 'PageDown':
    //       this.canvas.sendBackwards(obj, true);
    //       break;

    //     case 'End':
    //       this.canvas.sendToBack(obj);
    //       break;

    //     case 'Home':
    //       this.canvas.bringToFront(obj);
    //       break;

    //   }
    // });
    // this.canvas.renderAll();
  }

  onMouseDown(evt: any) {

    this.panning = this.shiftDown;
    // if (!this.panning) {


    //   if (evt.target) {
    //     this.PlaceMode = drawMode.SELECT;
    //     if (this.canvas.getActiveObjects().length === 1) {
    //       this.firstSelected = evt.target;
    //       console.dir(this.firstSelected);
    //     }
    //   }

    //   switch (this.PlaceMode) {

    //     case drawMode.SELECT:
    //       break;


    //     default:
    //       const e = evt.e as MouseEvent;
    //       const pointer = this.canvas.getPointer(evt.e);
    //       this.mouseDown(pointer.x, pointer.y);
    //   }
    // }
  }

  onItemClick(e) {
    console.log(e.itemData.name || e.itemData, 'success', 600);
  }

  cpInitStroke = (e) => {
    this.ddButtonStroke = e.component;
  }

  cpInitBG = (e) => {
    this.ddButtonBG = e.component;
  }

  cpInitFill = (e) => {
    this.ddButtonFill = e.component;
  }


  get PlaceMode(): drawMode {
    return this.toolMode;
  }

  set PlaceMode(val: drawMode) {
    this.toolMode = val;
    this.selectTool(val);
  }

  setToolbarProps(comp: any) {
    const zcomp = comp.component as zComponent;
    for (const [pn, pdesc] of Object.entries(zcomp.props)) {
      if ((pn[0] === '$') && (pdesc.view)) {
        const indx = pdesc.view.indexOf('.');
        if (indx > -1) {
          let stop = pdesc.view.indexOf('#');
          stop = (stop > indx) ? stop : pdesc.view.length;
          const fname = pdesc.view.slice(indx + 1, stop);
          const pname = pn.slice(1);
          if (fname.length > 0) {
            zcomp.props[pname] = this.getMenuProp(fname);
            zcomp.host[pname] = zcomp.props[pname];
          }
        }
      }
    }
    zcomp.update();
  }

  setPlaceMode(id: any, val: drawMode, index: number, doplace: boolean) {
    this.saveMenuChoice(id, index);
    if (doplace) {
      const ctype = drawMode[val];
      const cname = ctype.slice(1, 5).toLowerCase() + '#';
      const comp = this.zimBuilder.CreateComp(cname, ctype);
      this.setToolbarProps(comp);
    }
    this.PlaceMode = val;
  }


  CopyClick(id: string) {
    console.log(id + ' clicked');
  }


  CutClick(id: string) {
    console.log(id + ' clicked');
  }

  PasteClick(id: string) {
    console.log(id + ' clicked');
  }

  UndoClick(id: string) {
    console.log(id + ' clicked');
  }


  RedoClick(id: string) {
    console.log(id + ' clicked');
  }

  ClearClick(id: string) {
    console.log(id + ' clicked');
    this.PlaceMode = drawMode.SELECT;
    // if (this.canvas) {
    //   this.canvas.clear();
    //   if (this.frameProps.gridOn) {
    //     this.drawGrid();
    //   }
    //   this.canvas.renderAll();
    // }
  }

  PrintClick(id: string) {
    console.log(id + ' clicked');
  }

  SaveClick(id: string) {
    this.PlaceMode = drawMode.SELECT;
    this.clearGrid(false);
    //    this.frameEditor.frame.SaveCanvas();
    if (this.frameProps.gridOn) {
      this.drawGrid();
    }
  }

  ExportClick(id: string) {
    this.saveFrame();
  }

  ImportClick(id: string) {
    this.loadFrame();
    //    this.PlaceMode = drawMode.SELECT;
  }


  SetGrid(state: boolean) {
    if (state) {
      this.drawGrid();
    } else {
      this.clearGrid(true);
    }
  }

  clearGrid(render: boolean) {
    // const gridLines: fabric.Object[] = this.canvas.getObjects().filter(obj => obj.data === '#');
    // for (const line of gridLines) {
    //   this.canvas.remove(line);
    // }
    // if (render) {
    //   this.canvas.renderAll();
    // }
  }

  drawGrid() {
  }

  refreshFrame() {
    const json = this.zimBuilder.SaveFrame(true);
    this.zimBuilder.LoadFrame(json, this.params);
    this.zimBuilder.ShowFrame(this.document);

  }

  loadFrame() {
    this.zimBuilder.LoadFrame(this.json, this.params);
    this.zimBuilder.ShowFrame(this.document);
  }

  saveFrame() {
    this.json = this.zimBuilder.SaveFrame(true);
  }


  menuChoice(prop: string, val: any, index: number) {
    if (this.compEditMode) {
      this.setSelCompProp(prop, val);
    } else {
      this.saveMenuChoice(prop, val);
      // if (index) {
      //   this.saveMenuChoice(prop, index);
      // } else {
      //   this.saveMenuChoice(prop, val);
      // }
    }
  }


  public PanelToggle(panel: ToolbarPanel) {
    if (!this.loading) {
      this.panelStates[panel.name] = panel.collapsed;
      localStorage.setItem('FEP', JSON.stringify(this.panelStates));
    }
  }

  private loadPanelState() {
    this.loading = true;
    this.panelStates = JSON.parse(localStorage.getItem('FEP')) || {};
    for (const [name, collapsed] of Object.entries(this.panelStates)) {
      const indx = this.tbPanels.findIndex(panel => panel.name === name);
      if (indx > -1) {
        this.tbPanels[indx].collapsed = collapsed as boolean;
      }
    }
    this.loading = false;
  }

  private makeMenuValues() {
    this.menuValues = {};
    this.tbPanels.forEach(panel => {
      panel.items.forEach(tbi => {
        if (tbi.id) {
          this.menuValues[tbi.id] = undefined;
        }
      });
    });
  }

  private loadMenuValues() {

    if (!this.menuValues) {
      this.makeMenuValues();
    }
    this.loading = true;
    const mv = JSON.parse(localStorage.getItem('FEM')) || {};
    for (const [key, val] of Object.entries(mv)) {
      this.menuValues[key] = val;
      this.setMenuProp(key, val);
    }
    this.loading = false;
  }

  private saveMenuValues() {
    if (!this.loading) {
      localStorage.setItem('FEM', JSON.stringify(this.menuValues));
    }
  }


  private saveMenuChoice(id: string, val: any) {
    //    console.log(id + ':' + val);
    if (!this.loading) {
      this.menuValues[id] = val;
      this.saveMenuValues();
    }
  }

  // view could be '.fname' or '.fname#plabel' or '#plabel.fname'

  private setSelCompProp(prop: string, value: any) {
    const zcomp = this.selectedComp;

    if (zcomp) {
      for (const [pn, pdesc] of Object.entries(zcomp.props)) {
        if ((pn[0] === '$') && (pdesc.view)) {
          const indx = pdesc.view.indexOf('.');
          if (indx > -1) {
            let stop = pdesc.view.indexOf('#');
            stop = (stop > indx) ? stop : pdesc.view.length;
            const fname = pdesc.view.slice(indx + 1, stop);
            const pname = pn.slice(1);
            if (fname === prop) {
              zcomp.updateProp(pname, value);
              break;
            }
          }
        }
      }
    }
  }

  private getSelCompProp() {
    const zcomp = this.selectedComp;
    if (zcomp) {
      for (const [pn, pdesc] of Object.entries(zcomp.props)) {
        if ((pn[0] === '$') && (pdesc.view)) {
          const indx = pdesc.view.indexOf('.');
          if (indx > -1) {
            let stop = pdesc.view.indexOf('#');
            stop = (stop > indx) ? stop : pdesc.view.length;
            const fname = pdesc.view.slice(indx + 1, stop);
            const pname = pn.slice(1);
            const val = zcomp.props[pname];
            //            console.log(pname + ':' + fname + ' = ' + val);
            if (fname.length > 0) {
              this.setMenuProp(fname, zcomp.props[pname]);
            }
          }
        }
      }
    }
  }


  private updateToolbar() {
    if (this.compEditMode) {
      // switch to comp mode - save menu values
      this.saveMenuValues();
      this.getSelCompProp();
      console.log('comp-edit-mode');
    } else {
      // switch to tool mode - restore menu values
      this.loadMenuValues();
      console.log('tool-mode');
    }


  }


  public Select(zcomp?: zComponent) {
    if (this.selectedComp && !zcomp) {
      this.compEditMode = false;
    }
    if (!this.selectedComp && zcomp) {
      this.compEditMode = true;
    }
    this.selectedComp = zcomp;
    this.updateToolbar();
  }


}


