<style>
  .banner {
    height: auto;
    text-align: center;
  }

  .banner-text {
    font-size: 32px;
    display: inline-block;
    margin: 0 auto;
  }

  #bgVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }
</style>

<div class="banner">
  <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-sm-3">
      <span class="banner-text">MCS • Online • Teaching</span>
    </a>
  </nav>
</div>

<video id="bgVideo"
       autoplay
       [muted]="true"
       loop>
  <source src="/assets/inkflow.webm"
          type="video/webm">
</video>
