import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SysEventsService } from 'src/app/shared/services/sysevents.service';
import { WebRtcService } from 'src/app/shared/services/webrtc.service';
import { Subscription } from 'rxjs';


interface Message {
  type: string;
  text: string;
  align?: string;
}


@Component({
  selector: 'app-message-board',
  templateUrl: './message-board.component.html'
})
export class MessageBoardComponent implements OnInit, OnDestroy {

  inputTxt: HTMLInputElement;
  messages: Message[] = new Array(0);
  private myMBTextSubs: Subscription;

  constructor(public ses: SysEventsService, public webrtc: WebRtcService, public cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.myMBTextSubs = this.webrtc.RxMsgBoardText.subscribe((msg) => { this.receiveTxt(msg); });
    this.inputTxt = document.getElementById('sendInput') as HTMLInputElement;
  }

  ngOnDestroy(): void {
    this.myMBTextSubs.unsubscribe();
  }

  keyPress(evt: KeyboardEvent){
  }


  receiveTxt(msg: string) {
    const newMsg: Message = {
      type: 'success',
      text: msg,
      align: 'ngb_right'
    };
    this.messages.push(newMsg);
    this.cdr.detectChanges();
  }

  sendTxt(msg: string) {
    this.inputTxt.value = '';
    if (msg.length > 0) {
      const newMsg: Message = {
        type: 'info',
        text: msg,
        align: 'ngb_left'
      };
      this.messages.push(newMsg);
      this.webrtc.SendMBoardTxt(msg);
    }

  }

  clearLog() {
    this.messages.length = 0;
  }


}
